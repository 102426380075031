import * as documentsApi from '../api/documentsApi'
import { actionOpenSnackbar } from './snackbarActions'

export const FETCHING = '@@redux-documents/FETCHING'
export const FETCH_DOCUMENTS = '@@redux-documents/FETCH_DOCUMENTS'
export const LOGOUT = '@@redux-documents/LOGOUT'

export const actionFetchDocuments = () => {
  return dispatch => {
    documentsApi.getDocuments()
      .then(res => {
        console.log(res)
        dispatch(fetchDocuments(res.data.data))
      })
      .catch(err => {
        // dispatch(actionOpenSnackbar('Something went wrong.'))
      })
  }
}

export const fetching = (val = true) => ({
  type: FETCHING,
  payload: val
})

export const fetchDocuments = documents => ({
  type: FETCH_DOCUMENTS,
  payload: documents
})

export const clearDocuments = () => ({
  type: LOGOUT
})