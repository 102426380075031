import React from 'react'
import { Button } from '@material-ui/core';
import { useTheme, createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import theme from '../../Theme/theme'

const WarningButton = props => {
  const mainTheme = useTheme(theme)

  const outlined = createMuiTheme({ palette: { primary: mainTheme.palette.warning } })

  return (
    <MuiThemeProvider theme={outlined}>
      <Button {...props} color="primary" />
    </MuiThemeProvider>
  )
}

export default WarningButton
