import React from 'react'
import LoadingPage from '../Shared/LoadingPage'
import DashboardComponent from './DashboardComponent'
import { Context } from '../../context/context'

const Dashboard = () => {

  // const [loading, setLoading] = React.useState(true)
  const context = React.useContext(Context)

  // React.useEffect(() => {
  //   context.setLoading(true)
  //   setTimeout(() => {
  //     context.setLoading(false)
  //   }, 1500)
  // }, [])

  return (
    context.isLoading ? <LoadingPage /> : <DashboardComponent />
  )
}

export default Dashboard
