import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaperTitle from '../../Theme/customComponents/PaperTitle';
import { Toolbar, Typography, Box } from '@material-ui/core';
import QuickAction from '../Shared/QuickAction';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchBar from '../Shared/SearchBar';
import Clickable, { ClickableSkeleton } from '../Shared/Clickable';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import QuickActionsSnippet from '../QuickActions/QuickActionsSnippet';

const QuotesComponent = () => {
  const orders = useSelector(state => state.orders?.data?.items)
  const [selected, setSelected] = React.useState(orders)
  const history = useHistory()

  React.useEffect(() => {
    setSelected(orders)
  }, [orders])

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={5}>
          <Paper fill>
            <PaperTitle>
              <Typography variant="h6">
                Search by business
              </Typography>
            </PaperTitle>
            <Box p={2} display="flex" flexDirection="column">
              <SearchBar target="orders" updateFunction={setSelected} matchOn='name' fullSet={orders}/>
              { selected ? selected.map(order => {
                console.log(order, 'order')
                return <Clickable onClick={() => history.push(`/orders/${order.OrderId}`)}> {order.OrderId} </Clickable>
              }) : <ClickableSkeleton amount="3" /> }
              <QuickAction onClick={() => history.push(`/add_business`)}>Add new business</QuickAction>
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={12} md={7}>
          <Paper fill>
            <PaperTitle>
              <Typography variant="h6">
                Quotes
              </Typography>
            </PaperTitle>
            <Box p={2} display="flex" flexDirection="column">
              { orders ? orders.map(order => {
                console.log(order, 'order')
                return <Clickable onClick={() => history.push(`/orders/${order.OrderId}`)}> {order.OrderId} </Clickable>
              }) : <ClickableSkeleton amount="3" /> }
              <Typography variant="body">
                Active
              </Typography>
              <Clickable leftIcon={<DescriptionIcon/>}> Welcome to BrandHub </Clickable>
              <Clickable leftIcon={<DescriptionIcon/>}> Register your first business </Clickable>
            </Box>
            {/* <Box p={2} display="flex" flexDirection="column">
              <Typography variant="body">
                Complete
              </Typography>
              <Clickable leftIcon={<DescriptionIcon/>}> Welcome to BrandHub </Clickable>
              <Clickable leftIcon={<DescriptionIcon/>}> Register your first business </Clickable>
            </Box> */}
          </Paper>
        </Grid>
        <Grid item sm={12}>
        <Paper fill>
          <PaperTitle>
            <Typography variant="h6">
              Quick Tools
            </Typography>
          </PaperTitle>
          <Box p={2} display="flex" flexDirection="row">
            <QuickAction margin> Export as PDF </QuickAction>
            <QuickAction margin> Email </QuickAction>
            <QuickAction margin> Publish </QuickAction>
            <QuickAction margin> Share </QuickAction>
          </Box> 
        </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default QuotesComponent
