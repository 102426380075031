import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import TextField from '@material-ui/core/TextField'
import { Box, Button, Typography, CircularProgress } from '@material-ui/core'

const App = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting
}) => {
  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography>Starting a new business? First things first, we can run a free check against <strong>Instagram, Facebook, Australian Business Registry and ASIC</strong> databases to see if anything similar comes up</Typography>
        <TextField
          id="business_name"
          error={touched.business_name && errors.business_name}
          value={values.business_name}
          name="business_name"
          label="Business Name"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.business_name && errors.business_name}
        /> 
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
        >
          {isSubmitting ? 'Checking availability...' : 'Check now'}
        </Button>
      </Box>
    </Form>
  )
}

const AddNameForm = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName
  }) => ({
      updateBankDetails,
      refreshUserData,
      business_name: businessName,
      handler
    }),
  validationSchema: Yup.object().shape({
    business_name: Yup.string()
      .nullable()
      .required('Required')
  }),
  handleSubmit(
    { business_name, handler },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    console.log('submitted', business_name)
    handler({ business_name })
  }
})(App)


export default AddNameForm
