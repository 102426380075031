import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik, FieldArray, Field, getIn, FastField } from 'formik'
import Form from '../../../Shared/Form'

import { Box, Button, Typography, CircularProgress, FormHelperText, Grid, OutlinedInput, InputAdornment } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'
import { newShareholder } from '../AsicRegisterBusiness'

const Input = ({ label, startAdornment, field, form: { errors, touched, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  return(
    <FormControl component="fieldset" error={isTouched && errorMessage}>
      <FormLabel component="legend">{label}</FormLabel>
      <OutlinedInput {...field}
        margin="normal"
        variant="outlined"
        margin="dense"
        fixedLabel="true"
        startAdornment={startAdornment}
      /> 
      { (submitCount > 0 || isTouched) && errorMessage && <FormHelperText>{errorMessage}</FormHelperText> }
    </FormControl>
  )
}

const Role = ({ label, field, form: { errors, touched, values, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  const fieldValue = getIn(values, field.name)
  return(
    <FormControl component="fieldset" error={errorMessage} margin="dense">
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            color="primary"
            checked={fieldValue}
          />
        }
        label={label}
      />
      { errorMessage && <FormHelperText>{errorMessage}</FormHelperText> }
    </FormControl>
  )
}

const YesNo = ({ label, field, form: { errors, touched, values, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  const fieldValue = getIn(values, field.name)
  return(
    <FormControl component="fieldset" error={isTouched && errorMessage} margin="dense">
      <FormLabel component="legend">{label}</FormLabel>
      <RadioGroup row {...field}>
        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
        <FormControlLabel value="no" control={<Radio />} label="No" />
      </RadioGroup>
    </FormControl> 
  )
}

const Options = ({ children, label, field, form: { errors, touched, values, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  return(
    <FormControl component="fieldset" variant="outlined" size="small" error={isTouched && errorMessage}>
      <FormLabel component="legend">{label}</FormLabel>
      <Select {...field}>
        {children}
      </Select>
      { isTouched && errorMessage && <FormHelperText>{errorMessage}</FormHelperText> }
    </FormControl>
  )
}

const ShareholdersFormTemplate = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  setFieldValue,
  submitCount,
  scrollFunction
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])

  return (
    <Form>
      { values.review && <Box position="absolute" width="100%" height="100%" zIndex="999" bgcolor="rgba(0,0,0,0.1)"></Box> }
      <Box width="80%" maxWidth="800px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h6">Shareholders of the company</Typography>
        <Typography variant="body1">Please enter the details of the people or entities that will be holding shares in the Company</Typography>
        <Divider />
        <Divider />
        <FieldArray name="shareholders">
          {
            ({ push, remove }) => (
              <>
                {
                  values.shareholders.map((elem, i) => {
                    return(
                      <>
                        <Grid container>
                          <Grid item xs="9">
                            <Typography variant="h6">{`Shareholder ${i + 1}`}</Typography>
                          </Grid>
                          <Grid item xs="3">
                            <Box display="flex" justifyContent="flex-end">
                              <Button color="primary" onClick={() => remove(i)}>Remove</Button>
                            </Box>
                          </Grid>
                        </Grid>
                        <FastField label="Is this Shareholder an Individual, Company or a Joint Holder?" name={`shareholders[${i}].type`} component={Options}>
                          <MenuItem value="individual">Individual</MenuItem>
                          <MenuItem value="company">Company</MenuItem>
                          <MenuItem value="joint">Joint Holder</MenuItem>
                        </FastField>
                        {
                          values.shareholders[i].type == 'joint' && (
                            <>
                              <FastField label="Is Joint Holder 1 a Company or Individual?" name={`shareholders[${i}].joint_holder_1_type`} component={Options}>
                                <MenuItem value="individual">Individual</MenuItem>
                                <MenuItem value="company">Company</MenuItem>
                              </FastField>
                              <FastField label="Is Joint Holder 2 a Company or Individual?" name={`shareholders[${i}].joint_holder_2_type`} component={Options}>
                                <MenuItem value="individual">Individual</MenuItem>
                                <MenuItem value="company">Company</MenuItem>
                              </FastField>
                              <Divider />
                            </>
                          )
                        }
                        {
                          (values.shareholders[i].type == 'individual' ||
                          values.shareholders[i].type == 'company' ||
                          (values.shareholders[i].type == 'joint' && values.shareholders[i].joint_holder_1_type != 'company' && values.shareholders[i].joint_holder_2_type != 'company')) &&
                          <>
                            <FastField label="Are the shares in this shareholding going to be held on trust for another entity, such as a Super Fund or Trust?" name={`shareholders[${i}].shares_held_on_trust`} component={YesNo} />
                            {
                              values.shareholders[i].shares_held_on_trust == 'yes' && 
                              <FastField label="Name of the entity the shares are being held in trust for" name={`shareholders[${i}].shares_held_on_trust_for`} component={Input} />
                            }
                            <Divider />
                          </>
                        }
                        { /* INDIVIDUAL */
                          values.shareholders[i].type == 'individual' && (
                            <>
                              <Typography variant="h6">Shareholder Name</Typography>
                              <FastField label="Shareholder Name" name={`shareholders[${i}].name`} component={Options}>
                                {
                                  values.directors.map(director => (
                                    <MenuItem 
                                      value={`${director.given_names} ${director.family_name}`} 
                                      onClick={() => {
                                        setFieldValue(`shareholders[${i}].given_names`, director.given_names)
                                        setFieldValue(`shareholders[${i}].family_name`, director.family_name)
                                      }}
                                    >
                                      {`${director.given_names} ${director.family_name}`}
                                    </MenuItem>
                                  ))
                                }
                                <MenuItem value="new">A new associate</MenuItem>
                              </FastField>
                              {
                                values.shareholders[i].name == 'new' && (
                                  <>
                                    <FastField label="Shareholder Given Names" name={`shareholders[${i}].given_names`} component={Input} />
                                    <FastField label="Shareholder Family Name" name={`shareholders[${i}].family_name`} component={Input} />
                                  </>
                                )
                              }
                              <Divider />
                            </>
                          )
                        }
                        { /* COMPANY */
                          values.shareholders[i].type == 'company' && (
                            <>
                              <Typography variant="h6">Shareholder Name</Typography>
                              <FastField label="Shareholder Company Name" name={`shareholders[${i}].company_name`} component={Input} />
                              <FastField label="Does this Company have an ACN or ARBN?" name={`shareholders[${i}].company_has_acn_or_arbn`} component={YesNo} />
                              {
                                values.shareholders[i].company_has_acn_or_arbn == 'yes' && 
                                <FastField label="ACN of the Company" name={`shareholders[${i}].company_acn_or_arbn`} component={Input} />
                              }
                              <br/>
                              <Typography variant="caption">Please nominate 2 Directors that will sign the Share Application Form on behalf of the Company
                              (if the Company is a sole Director Company, you may enter only 1 Director)</Typography>
                              <br/>
                              <FastField label="Shareholder Company Representative - Director 1" name={`shareholders[${i}].company_representative_1`} component={Input} />
                              <FastField label="Shareholder Company Representative - Director 2" name={`shareholders[${i}].company_representative_2`} component={Input} />
                              <Divider />
                            </>
                          )
                        }
                        {
                          values.shareholders[i].type == 'joint' && (
                            <>
                              {
                                values.shareholders[i].joint_holder_1_type == 'individual' && (
                                  <>
                                    <Typography variant="h6">Joint Shareholder 1 Name</Typography>
                                    <FastField label="Shareholder Name" name={`shareholders[${i}].joint_holder_1_name`} component={Options}>
                                      {
                                        values.directors.map(director => (
                                          <MenuItem 
                                            value={`${director.given_names} ${director.family_name}`}
                                            onClick={() => {
                                              setFieldValue(`shareholders[${i}].joint_holder_1_given_names`, director.given_names)
                                              setFieldValue(`shareholders[${i}].joint_holder_1_family_name`, director.family_name)
                                            }}
                                          >
                                            {`${director.given_names} ${director.family_name}`}
                                          </MenuItem>
                                        ))
                                      }
                                      <MenuItem value="new">A new associate</MenuItem>
                                    </FastField>
                                    {
                                      values.shareholders[i].joint_holder_1_name == 'new' && (
                                        <>
                                          <FastField label="Shareholder Given Names" name={`shareholders[${i}].joint_holder_1_given_names`} component={Input} />
                                          <FastField label="Shareholder Family Name" name={`shareholders[${i}].joint_holder_1_family_name`} component={Input} />
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }
                              { /* COMPANY */
                                values.shareholders[i].joint_holder_1_type == 'company' && (
                                  <>
                                    <Typography variant="h6">Shareholder Name</Typography>
                                    <FastField label="Shareholder Company Name" name={`shareholders[${i}].joint_holder_1_company_name`} component={Input} />
                                    <FastField label="Does this Company have an ACN or ARBN?" name={`shareholders[${i}].joint_holder_1_company_has_acn_or_arbn`} component={YesNo} />
                                    {
                                      values.shareholders[i].joint_holder_1_company_has_acn_or_arbn == 'yes' && 
                                      <FastField label="ACN of the Company" name={`shareholders[${i}].joint_holder_1_company_acn_or_arbn`} component={Input} />
                                    }
                                    <br/>
                                    <Typography variant="caption">Please nominate 2 Directors that will sign the Share Application Form on behalf of the Company
                                    (if the Company is a sole Director Company, you may enter only 1 Director)</Typography>
                                    <br/>
                                    <FastField label="Shareholder Company Representative - Director 1" name={`shareholders[${i}].joint_holder_1_company_representative_1`} component={Input} />
                                    <FastField label="Shareholder Company Representative - Director 2" name={`shareholders[${i}].company_representative_2`} component={Input} />
                                  </>
                                )
                              }
                              <Typography variant="h6">Joint Shareholder 1's Address</Typography>
                              <Typography variant="caption">Please enter the address of the Shareholder. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
                              <br />
                              <FastField label="What is the shareholder's address?" name={`shareholders[${i}].joint_holder_1_address`} component={Options}>
                                {
                                  values.addresses.map(address => <MenuItem value={address?.full_address}>{address?.full_address}</MenuItem>)
                                }
                                <MenuItem value="new">A different address</MenuItem>
                              </FastField>
                              {
                                values.shareholders[i].joint_holder_1_address == 'new' &&
                                  <>
                                    <FastField label="Level, Floor, Unit, Office, Suite" name={`shareholders[${i}].joint_holder_1_level`} component={Input} />
                                    <FastField label="Street Number and Name" name={`shareholders[${i}].joint_holder_1_street_number_and_name`} component={Input} />
                                    <FastField label="Suburb" name={`shareholders[${i}].joint_holder_1_suburb`} component={Input} />
                                    <FastField label="State" name={`shareholders[${i}].joint_holder_1_state`} component={Options}>
                                      <MenuItem value="">
                                        <em>Please select a state</em>
                                      </MenuItem>
                                      <MenuItem value="ACT">ACT</MenuItem>
                                      <MenuItem value="NSW">NSW</MenuItem>
                                      <MenuItem value="NT">NT</MenuItem>
                                      <MenuItem value="QLD">QLD</MenuItem>
                                      <MenuItem value="SA">SA</MenuItem>
                                      <MenuItem value="TAS">TAS</MenuItem>
                                      <MenuItem value="VIC">VIC</MenuItem>
                                      <MenuItem value="WA">WA</MenuItem>
                                    </FastField>
                                    <FastField label="Postcode" name={`shareholders[${i}].joint_holder_1_postcode`} component={Input} />
                                  </>
                              }
                              <Divider />
                            </>
                          )
                        }
                        {
                          values.shareholders[i].type == 'joint' && (
                            <>
                              {
                                values.shareholders[i].joint_holder_2_type == 'individual' && (
                                  <>
                                    <Typography variant="h6">Joint Shareholder 2 Name</Typography>
                                    <FastField label="Shareholder Name" name={`shareholders[${i}].joint_holder_2_name`} component={Options}>
                                      {
                                        values.directors.map(director => (
                                          <MenuItem 
                                            value={`${director.given_names} ${director.family_name}`}
                                            onClick={() => {
                                              setFieldValue(`shareholders[${i}].joint_holder_2_given_names`, director.given_names)
                                              setFieldValue(`shareholders[${i}].joint_holder_2_family_name`, director.family_name)
                                            }}
                                          >
                                            {`${director.given_names} ${director.family_name}`}
                                          </MenuItem>
                                        ))
                                      }
                                      <MenuItem value="new">A new associate</MenuItem>
                                    </FastField>
                                    {
                                      values.shareholders[i].joint_holder_2_name == 'new' && (
                                        <>
                                          <FastField label="Shareholder Given Names" name={`shareholders[${i}].joint_holder_2_given_names`} component={Input} />
                                          <FastField label="Shareholder Family Name" name={`shareholders[${i}].joint_holder_2_family_name`} component={Input} />
                                        </>
                                      )
                                    }
                                  </>
                                )
                              }
                              { /* COMPANY */
                                values.shareholders[i].joint_holder_2_type == 'company' && (
                                  <>
                                    <Typography variant="h6">Shareholder Name</Typography>
                                    <FastField label="Shareholder Company Name" name={`shareholders[${i}].joint_holder_2_company_name`} component={Input} />
                                    <FastField label="Does this Company have an ACN or ARBN?" name={`shareholders[${i}].joint_holder_2_company_has_acn_or_arbn`} component={YesNo} />
                                    {
                                      values.shareholders[i].joint_holder_2_company_has_acn_or_arbn == 'yes' && 
                                      <FastField label="ACN of the Company" name={`shareholders[${i}].joint_holder_2_company_acn_or_arbn`} component={Input} />
                                    }
                                    <br/>
                                    <Typography variant="caption">Please nominate 2 Directors that will sign the Share Application Form on behalf of the Company
                                    (if the Company is a sole Director Company, you may enter only 1 Director)</Typography>
                                    <br/>
                                    <FastField label="Shareholder Company Representative - Director 1" name={`shareholders[${i}].joint_holder_2_company_representative_1`} component={Input} />
                                    <FastField label="Shareholder Company Representative - Director 2" name={`shareholders[${i}].company_representative_2`} component={Input} />
                                  </>
                                )
                              }
                              <Typography variant="h6">Joint Shareholder 2's Address</Typography>
                              <Typography variant="caption">Please enter the address of the Shareholder. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
                              <br />
                              <FastField label="What is the shareholder's address?" name={`shareholders[${i}].joint_holder_2_address`} component={Options}>
                                {
                                  values.addresses.map(address => <MenuItem value={address?.full_address}>{address?.full_address}</MenuItem>)
                                }
                                <MenuItem value="new">A different address</MenuItem>
                              </FastField>
                              {
                                values.shareholders[i].joint_holder_2_address == 'new' &&
                                  <>
                                    <FastField label="Level, Floor, Unit, Office, Suite" name={`shareholders[${i}].joint_holder_2_level`} component={Input} />
                                    <FastField label="Street Number and Name" name={`shareholders[${i}].joint_holder_2_street_number_and_name`} component={Input} />
                                    <FastField label="Suburb" name={`shareholders[${i}].joint_holder_2_suburb`} component={Input} />
                                    <FastField label="State" name={`shareholders[${i}].joint_holder_2_state`} component={Options}>
                                      <MenuItem value="">
                                        <em>Please select a state</em>
                                      </MenuItem>
                                      <MenuItem value="ACT">ACT</MenuItem>
                                      <MenuItem value="NSW">NSW</MenuItem>
                                      <MenuItem value="NT">NT</MenuItem>
                                      <MenuItem value="QLD">QLD</MenuItem>
                                      <MenuItem value="SA">SA</MenuItem>
                                      <MenuItem value="TAS">TAS</MenuItem>
                                      <MenuItem value="VIC">VIC</MenuItem>
                                      <MenuItem value="WA">WA</MenuItem>
                                    </FastField>
                                    <FastField label="Postcode" name={`shareholders[${i}].joint_holder_2_postcode`} component={Input} />
                                  </>
                              }
                            </>
                          )
                        }
                        {
                          (values.shareholders[i].type == 'individual' || values.shareholders[i].type == 'company') && (
                            <>
                              <Typography variant="h6">Shareholder's Address</Typography>
                              <Typography variant="caption">Please enter the address of the Shareholder. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
                              <br />
                              <FastField label="What is the shareholder's address?" name={`shareholders[${i}].address`} component={Options}>
                                {
                                  values.addresses.map(address => <MenuItem value={address?.full_address}>{address?.full_address}</MenuItem>)
                                }
                                <MenuItem value="new">A different address</MenuItem>
                              </FastField>
                              {
                                values.shareholders[i].address == 'new' &&
                                  <>
                                    <FastField label="Level, Floor, Unit, Office, Suite" name={`shareholders[${i}].level`} component={Input} />
                                    <FastField label="Street Number and Name" name={`shareholders[${i}].street_number_and_name`} component={Input} />
                                    <FastField label="Suburb" name={`shareholders[${i}].suburb`} component={Input} />
                                    <FastField label="State" name={`shareholders[${i}].state`} component={Options}>
                                      <MenuItem value="">
                                        <em>Please select a state</em>
                                      </MenuItem>
                                      <MenuItem value="ACT">ACT</MenuItem>
                                      <MenuItem value="NSW">NSW</MenuItem>
                                      <MenuItem value="NT">NT</MenuItem>
                                      <MenuItem value="QLD">QLD</MenuItem>
                                      <MenuItem value="SA">SA</MenuItem>
                                      <MenuItem value="TAS">TAS</MenuItem>
                                      <MenuItem value="VIC">VIC</MenuItem>
                                      <MenuItem value="WA">WA</MenuItem>
                                    </FastField>
                                    <FastField label="Postcode" name={`shareholders[${i}].postcode`} component={Input} />
                                  </>
                              }
                            </>
                          )
                        }
                        { /* JOINT */

                        }
                        <Divider />
                        <Typography variant="h6">Details of this Shareholding</Typography>
                        <br/>
                        <FastField label="Class of shares held by this Shareholder" name={`shareholders[${i}].class_of_shares_held`} component={Options}>
                          <MenuItem value="ORD">ORD</MenuItem>
                          <MenuItem value="A">A</MenuItem>
                          <MenuItem value="B">B</MenuItem>
                          <MenuItem value="C">C</MenuItem>
                          <MenuItem value="D">D</MenuItem>
                          <MenuItem value="E">E</MenuItem>
                          <MenuItem value="F">F</MenuItem>
                          <MenuItem value="G">G</MenuItem>
                          <MenuItem value="H">H</MenuItem>
                          <MenuItem value="I">I</MenuItem>
                          <MenuItem value="J">J</MenuItem>
                          <MenuItem value="K">K</MenuItem>
                          <MenuItem value="L">L</MenuItem>
                          <MenuItem value="M">M</MenuItem>
                          <MenuItem value="N">N</MenuItem>
                          <MenuItem value="O">O</MenuItem>
                          <MenuItem value="P">P</MenuItem>
                        </FastField>
                        <FastField label="Number of shares in this Shareholding" name={`shareholders[${i}].number_of_shares`} component={Input} />
                        <FastField startAdornment={<InputAdornment position="start">$</InputAdornment>} label="Price of each share" name={`shareholders[${i}].price_of_each_share`} component={Input} />
                        <Divider />
                      </>
                    )})
                }
                {
                  !values.review && (
                    <Button
                      variant="contained"
                      color="primary"
                      type="button"
                      onClick={() => push(newShareholder(Date.now()))}
                    >
                      Add Shareholder
                    </Button>
                  )
                }
              </>
            )
          }
        </FieldArray>
        {
          !values.review && (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={status.back}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
                  fullWidth
                >
                  {isSubmitting ? 'Loading' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Form>
  )
}

const Shareholders = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    shareholders,
    directors,
    review,
    addresses
  }) => ({
      refreshUserData,
      shareholders,
      directors,
      addresses,
      review,
      handler
    }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    shareholders: Yup.array().of(
      Yup.object().shape({
        type: Yup.string().required('Required'),
        shares_held_on_trust: Yup.string().when('type', (type, schema) => type != 'joint' ? schema.required('Required') : schema.when(['joint_holder_1_type', 'joint_holder_1_type'], (type1, type2, schema) => (type1 == 'company' || type2 == 'company') ? schema : schema.required('Required'))),
        shares_held_on_trust_for: Yup.string().when('shares_held_on_trust', { is: true, then: Yup.string().required('Required')}),
        
        //if type is individual
        name: Yup.string().when('type', { is: 'individual', then: Yup.string().required('Required')}), 
        given_names: Yup.string().when('type', { is: 'individual', then: Yup.string().when('name', { is: 'new', then: Yup.string().required('Required')})}),
        family_name: Yup.string().when('type', { is: 'individual', then: Yup.string().when('name', { is: 'new', then: Yup.string().required('Required')})}),

        //if type is company
        company_name: Yup.string().when('type', { is: 'company', then: Yup.string().required('Required')}),
        company_has_acn_or_arbn: Yup.string().when('type', { is: 'company', then: Yup.string().required('Required')}),
        company_acn_or_arbn: Yup.string().when('type', { is: 'company', then: Yup.string().when('company_has_acn_or_arbn', { is: true, then: Yup.string().required('Required')})}),
        company_representative_1: Yup.string().when('type', { is: 'company', then: Yup.string().required('Required')}),
        company_representative_2: Yup.string().when('type', { is: 'company', then: Yup.string().required('Required')}),

        //if type is joint
        joint_holder_1_type: Yup.string().when('type', { is: 'joint', then: Yup.string().required('Required')}),
        joint_holder_2_type: Yup.string().when('type', { is: 'joint', then: Yup.string().required('Required')}),
          //JOINT HOLDER 1
          //if joint_holder_1_type is individual
          joint_holder_1_name: Yup.string().when('joint_holder_1_type', { is: 'individual', then: Yup.string().required('Required')}), 
          joint_holder_1_given_names: Yup.string().when('joint_holder_1_type', { is: 'individual', then: Yup.string().when('joint_holder_1_name', { is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_1_family_name: Yup.string().when('joint_holder_1_type', { is: 'individual', then: Yup.string().when('joint_holder_1_name', { is: 'new', then: Yup.string().required('Required')})}),
          //if joint_holder_1_type is company
          joint_holder_1_company_name: Yup.string().when('joint_holder_1_type', { is: 'company', then: Yup.string().required('Required')}),
          joint_holder_1_company_has_acn_or_arbn: Yup.boolean().when('joint_holder_1_type', { is: 'company', then: Yup.boolean().required('Required')}),
          joint_holder_1_company_acn_or_arbn: Yup.string().when('joint_holder_1_company_has_acn_or_arbn', { is: true, then: Yup.string().required('Required')}),
          joint_holder_1_company_representative_1: Yup.string().when('joint_holder_1_type', { is: 'company', then: Yup.string().required('Required')}),
          joint_holder_1_company_representative_2: Yup.string().when('joint_holder_1_type', { is: 'company', then: Yup.string().required('Required')}),
          // for both cases
          joint_holder_1_address: Yup.mixed().when('type', (type, schema) => type != 'joint' ? schema : schema.required('Address required')),
          joint_holder_1_level: Yup.string(),
          joint_holder_1_street_number_and_name: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_1_suburb: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_1_state: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_1_postcode: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),

          // JOINT HOLDER 2
          //if joint_holder_2_type is individual
          joint_holder_2_name: Yup.string().when('joint_holder_2_type', { is: 'individual', then: Yup.string().required('Required')}), 
          joint_holder_2_given_names: Yup.string().when('joint_holder_2_type', { is: 'individual', then: Yup.string().when('joint_holder_2_name', { is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_2_family_name: Yup.string().when('joint_holder_2_type', { is: 'individual', then: Yup.string().when('joint_holder_2_name', { is: 'new', then: Yup.string().required('Required')})}),
          //if joint_holder_2_type is company
          joint_holder_2_company_name: Yup.string().when('joint_holder_2_type', { is: 'company', then: Yup.string().required('Required')}),
          joint_holder_2_company_has_acn_or_arbn: Yup.boolean().when('joint_holder_2_type', { is: 'company', then: Yup.boolean().required('Required')}),
          joint_holder_2_company_acn_or_arbn: Yup.string().when('joint_holder_2_company_has_acn_or_arbn', { is: true, then: Yup.string().required('Required')}),
          joint_holder_2_company_representative_1: Yup.string().when('joint_holder_2_type', { is: 'company', then: Yup.string().required('Required')}),
          joint_holder_2_company_representative_2: Yup.string().when('joint_holder_2_type', { is: 'company', then: Yup.string().required('Required')}),
          // for both cases
          joint_holder_2_address: Yup.mixed().when('type', (type, schema) => type != 'joint' ? schema : schema.required('Address required')),
          joint_holder_2_level: Yup.string(),
          joint_holder_2_street_number_and_name: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_2_suburb: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_2_state: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),
          joint_holder_2_postcode: Yup.string().when('type', { is: 'joint', then: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')})}),

        address: Yup.mixed().when('type', (type, schema) => type == 'joint' ? schema : schema.required('Address required')),
        level: Yup.string(),
        street_number_and_name: Yup.string().when('type', (type, schema) => type == 'joint' ? schema : schema.when('address', {is: 'new', then: Yup.string().required('Required')})),
        suburb: Yup.string().when('type', (type, schema) => type == 'joint' ? schema : schema.when('address', {is: 'new', then: Yup.string().required('Required')})),
        state: Yup.string().when('type', (type, schema) => type == 'joint' ? schema : schema.when('address', {is: 'new', then: Yup.string().required('Required')})),
        postcode: Yup.string().when('type', (type, schema) => type == 'joint' ? schema : schema.when('address', {is: 'new', then: Yup.string().required('Required')})),

        class_of_shares_held: Yup.string().required('Required'),
        number_of_shares: Yup.string().required('Required'),
        price_of_each_share: Yup.string().required('Required'),
      })
    ),
  }),
  handleSubmit(
    { handler, ...values },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const newAddresses = values.shareholders.reduce((acc, o, i) => {
      const addr = o.address != 'new' ? null : {
        name: `${o.given_names} ${o.family_name}'s address`,
        id: `shareholder_${i}_address`,
        level: o.level,
        street_number_and_name: o.street_number_and_name,
        suburb: o.suburb,
        state: o.state,
        postcode: o.postcode,
        full_address: `${o.level} ${o.street_number_and_name}, ${o.suburb}, ${o.state} ${o.postcode}`
      }
      const joint_1_addr = o.joint_holder_1_address != 'new' ? null : {
        name: `${o.joint_holder_1_given_names} ${o.joint_holder_1_family_name}'s address`,
        id: `shareholder_${i}_address`,
        level: o.joint_holder_1_level,
        street_number_and_name: o.joint_holder_1_street_number_and_name,
        suburb: o.joint_holder_1_suburb,
        state: o.joint_holder_1_state,
        postcode: o.joint_holder_1_postcode,
        full_address: `${o.joint_holder_1_level} ${o.joint_holder_1_street_number_and_name}, ${o.joint_holder_1_suburb}, ${o.joint_holder_1_state} ${o.joint_holder_1_postcode}`
      }
      const joint_2_addr = o.joint_holder_2_address != 'new' ? null : {
        name: `${o.joint_holder_2_given_names} ${o.joint_holder_2_family_name}'s address`,
        id: `shareholder_${i}_address`,
        level: o.joint_holder_2_level,
        street_number_and_name: o.joint_holder_2_street_number_and_name,
        suburb: o.joint_holder_2_suburb,
        state: o.joint_holder_2_state,
        postcode: o.joint_holder_2_postcode,
        full_address: `${o.joint_holder_2_level} ${o.joint_holder_2_street_number_and_name}, ${o.joint_holder_2_suburb}, ${o.joint_holder_2_state} ${o.joint_holder_2_postcode}`
      }
      return [...acc, addr, joint_1_addr, joint_2_addr].filter(e => e)
    }, [])

    handler(values.shareholders, newAddresses)
  }
})(ShareholdersFormTemplate)


export default Shareholders
