import React from 'react'
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx'

export const useContainer = makeStyles(theme => ({
  container:{
    display: 'flex',
    flexDirection: 'column',
    height: props => props.fill ? '100%' : 'auto',
    background: '#fff',
    borderRadius: '10px',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    cursor: props => props.clickable && 'pointer',
    transition: 'box-shadow .1s linear',
    '&:hover': {
      boxShadow: props => props.clickable ? theme.shadows[2] : 'none'
    }
  }
}))
  
const Container = props => {
  const classes = useContainer(props)
  return (
    <Box {...props} className={clsx(classes.container, props.className)} />
  )
}

export default Container
  