import React from 'react'
import axios from 'axios'
import './App.css';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './Components/Layout/Layout'
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import theme from './Theme/theme'
import Dashboard from './Components/Dashboard/Dashboard'
import Businesses from './Components/Businesses/Businesses'
import AddBusiness from './Components/Businesses/AddBusiness'
import ContextProvider from './context/context'
import { Context } from './context/context'
import CheckNameAvailability from './Components/QuickActions/BrandAvailability/CheckNameAvailability';
import AsicRegisterBusiness from './Components/QuickActions/RegisterBusiness/AsicRegisterBusiness';
import Copyright from './Components/Businesses/Copyright';
import Quotes from './Components/Quotes/Quotes';
import Documents from './Components/Documents/Documents';
import { useSelector } from 'react-redux'
import Login from './Components/Login/Login';
import PublicRoute from './Routers/PublicRoute';
import PrivateRoute from './Routers/PrivateRoute';
import IndividualBusiness from './Components/Businesses/IndividualBusiness';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignUp } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { useDispatch } from 'react-redux'
import { actionLogin } from './actions/userActions';
import { createGenerateClassName } from './Theme/generateClassName';
import Partners from './Components/Partners/Partners';
import Pricing from './Components/Pricing/Pricing';

function App() {
  const context = React.useContext(Context)
  const jwt = useSelector(state => state.user.jwt)
  const dispatch = useDispatch()

  const [auth, setAuth] = React.useState({nextAuthState: '', authData: {}})

  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      console.log('in return statement', nextAuthState, authData)
      setAuth({nextAuthState, authData})
    })
  }, []);

  React.useEffect(() => {
    switch(auth.nextAuthState) {
      case 'signedin':
        console.log('case: signedin', auth.nextAuthState, auth.authData)
        auth.authData && dispatch(actionLogin({ jwt: auth.authData?.signInUserSession.idToken.jwtToken, user: { ...auth.authData?.attributes, username: auth.authData?.username }}));
        break;
      case 'signedout': 
        console.log('case: signedout', auth.nextAuthState, auth.authData);
        break;
      default: 
        dispatch(actionLogin({ jwt: 'badjwt', user: { username: 'gonz' }}));
        console.log('case: default', auth.nextAuthState, auth.authData);
    };
  }, [auth.nextAuthState])

  return (
    <ContextProvider>
      <div className="App">
        <AmplifyAuthenticator signUpConfig={{defaultCountryCode: "61"}}>
          <AmplifySignUp 
            signUpConfig={{defaultCountryCode: "61"}}
            slot="sign-up" 
            defaultCountryCode="61"
            formFields={[
              {
                type: "email",
                label: "Custom Email Label",
                placeholder: "Custom email placeholder",
                inputProps: { required: true, autocomplete: "username" },
              },
              {
                type: "password",
                label: "Custom Password Label",
                placeholder: "Custom password placeholder",
                inputProps: { required: true, autocomplete: "new-password" },
              },
              {
                type: "phone_number",
                label: "Custom Phone Label",
                placeholder: "Custom phone placeholder",
                defaultCountryCode: "61"
              },
            ]} 
            />
          <ThemeProvider theme={theme}>
            <StylesProvider generateClassName={createGenerateClassName()}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <Layout>
                  <Switch>
                    <Route path="/" exact component={Dashboard} />
                    <Route path="/dashboard" component={Dashboard} />
                    <Route path="/quotes" component={Quotes} />
                    <Route path="/pricing" component={Pricing} />
                    <Route path="/partners" component={Partners} />
                    <Route path="/documents" component={Documents} />
                    <Route path="/businesses" exact component={Businesses} />
                    <Route path="/businesses/copyright/:id" component={Copyright} />
                    <Route path="/businesses/copyright" component={Copyright} />
                    <Route path="/businesses/:id" exact component={IndividualBusiness} />
                    <Route path="/add_business" exact component={AddBusiness} />
                    <Route path="/check_availability" component={CheckNameAvailability} />
                    <Route path="/register_business" component={AsicRegisterBusiness} />
                  </Switch>
                </Layout>
              </MuiPickersUtilsProvider>
            </StylesProvider>
          </ThemeProvider>
        </AmplifyAuthenticator>
      </div>
    </ContextProvider>
  );
}

export default App;