import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import PaperTitle from '../../Theme/customComponents/PaperTitle';
import { Typography, Box, Divider, Button, Grid } from '@material-ui/core';
import QuickAction from '../Shared/QuickAction';
import Container from '../Shared/Container';
import LoadingPage from '../Shared/LoadingPage';
import { resolveAction } from '../../helpers/helpers'

const QuickActionsSnippet = ({ fill }) => {
  const history = useHistory()
  const businesses = useSelector(state => state.businesses?.data?.items)
  return (
    <Container fill> 
      <Typography variant="h3">
        Quick services
      </Typography>
      <Divider />
      <Grid container spacing={3}>
        {
          [{name: 'add_business', restriction: false}, {name: 'name_check', restriction: false}, {name: 'name_register', restriction: true}, {name: 'brand_copyright', restriction: true}]
            // .filter(el => {
            //   return businesses.length ? true : !el.restriction
            // })
            .map((check, i) => {
              let resolved = resolveAction(check.name, null)
              console.log('resolved', i, resolved)
              return(
                <Grid item sm={6}>
                  <QuickAction fill>
                    <Typography variant="h3">
                      {resolved?.name}
                    </Typography>
                    <br />
                    <Typography variant="body1">
                      {resolved?.text}
                    </Typography>
                    <Box width="100%" display="flex" marginTop="auto" justifyContent="flex-end">
                      {
                        check.restriction ?
                          businesses?.length ? 
                            <Button variant="contained" color="primary" onClick={resolved?.action}>Start</Button> :
                            <Button variant="contained" color="primary" onClick={() => history.push(`/add_business`)}>Add a Business First</Button> :
                          <Button variant="contained" color="primary" onClick={resolved?.action}>Start</Button> 
                      }
                    </Box>
                  </QuickAction>
                </Grid>
              )
            })
        }
      </Grid>
    </Container>
  )
}

export default QuickActionsSnippet
