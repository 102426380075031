import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import { Box, Button, Typography, CircularProgress, FormHelperText, Grid, OutlinedInput } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'

const App = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  submitCount,
  scrollFunction
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  return (
    <Form>
      { values.review && <Box position="absolute" width="100%" height="100%" zIndex="999" bgcolor="rgba(0,0,0,0.1)"></Box> }
      <Box width="80%" maxWidth="800px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h6">Company Registered Address</Typography>
        <Typography variant="body1">Where will the Registered Address of the Company be? The Registered Address is where all ASIC correspondance relating to the Company will be sent and cannot be a PO Box. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
        <br/>
        <FormControl component="fieldset" error={touched.care_of && errors.care_of}>
          <FormLabel component="legend">Care of (C/-)</FormLabel>
          <OutlinedInput
            id="care_of"
            value={values.care_of}
            name="care_of"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.care_of) && errors.care_of && <FormHelperText>{errors.care_of}</FormHelperText> }
        </FormControl>
        <FormControl component="fieldset" error={touched.level && errors.level}>
          <FormLabel component="legend">Level, Floor, Unit, Office, Suite</FormLabel>
          <OutlinedInput
            id="level"
            value={values.level}
            name="level"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.level) && errors.level && <FormHelperText>{errors.level}</FormHelperText> }
        </FormControl>
        <FormControl component="fieldset" error={touched.street_number_and_name && errors.street_number_and_name}>
          <FormLabel component="legend">Street Number and Name</FormLabel>
          <OutlinedInput
            id="street_number_and_name"
            value={values.street_number_and_name}
            name="street_number_and_name"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.street_number_and_name) && errors.street_number_and_name && <FormHelperText>{errors.street_number_and_name}</FormHelperText> }
        </FormControl>
        <FormControl component="fieldset" error={touched.suburb && errors.suburb}>
          <FormLabel component="legend">Suburb</FormLabel>
          <OutlinedInput
            id="suburb"
            value={values.suburb}
            name="suburb"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.suburb) && errors.suburb && <FormHelperText>{errors.suburb}</FormHelperText> }
        </FormControl>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.state && errors.state}>
          <FormLabel component="legend">State</FormLabel>
          <Select
            id="state"
            name="state"
            value={values.state}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Please select a state</em>
            </MenuItem>
            <MenuItem value="ACT">ACT</MenuItem>
            <MenuItem value="NSW">NSW</MenuItem>
            <MenuItem value="NT">NT</MenuItem>
            <MenuItem value="QLD">QLD</MenuItem>
            <MenuItem value="SA">SA</MenuItem>
            <MenuItem value="TAS">TAS</MenuItem>
            <MenuItem value="VIC">VIC</MenuItem>
            <MenuItem value="WA">WA</MenuItem>
          </Select>
        </FormControl>
        <FormControl component="fieldset" error={touched.postcode && errors.postcode}>
          <FormLabel component="legend">Postcode</FormLabel>
          <OutlinedInput
            id="postcode"
            value={values.postcode}
            name="postcode"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.postcode) && errors.postcode && <FormHelperText>{errors.postcode}</FormHelperText> }
        </FormControl>
        <FormControl component="fieldset" error={touched.occupy_office && errors.occupy_office}>
          <FormLabel component="legend">Will the Company occupy the Registered Office?</FormLabel>
          <RadioGroup row aria-label="occupy_office" name="occupy_office" value={values.occupy_office} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl> 
        {
          values.occupy_office == 'no' && 
            <FormControl component="fieldset" error={touched.who_occupies_office && errors.who_occupies_office}>
              <FormLabel component="legend">What is the name of the person or Company that occupies the office?</FormLabel>
              <OutlinedInput
                id="who_occupies_office"
                value={values.who_occupies_office}
                name="who_occupies_office"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.who_occupies_office) && errors.who_occupies_office && <FormHelperText>{errors.who_occupies_office}</FormHelperText> }
            </FormControl>
        }
        <Divider />
        <Typography variant="h6">Principal Business Address of the Company</Typography>
        <Typography variant="body1">The Company's Principal Place of Business is the address where the Company actually conducts its business on a day to day basis and cannot be a PO Box. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
        <br/>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.PBAC_is && errors.PBAC_is}>
          <FormLabel component="legend">What is the Company's Principal Place of Business?</FormLabel>
          <Select
            id="PBAC_is"
            name="PBAC_is"
            value={values.PBAC_is}
            onChange={handleChange}
          >
            <MenuItem value="same_as_CRA">Same as Company Registered Address</MenuItem>
            <MenuItem value="new">A different address</MenuItem>
          </Select>
        </FormControl>
        {
          values.PBAC_is == 'new' && 
          <>
            <FormControl component="fieldset" error={touched.PBAC_level && errors.PBAC_level}>
              <FormLabel component="legend">Level, Floor, Unit, Office, Suite</FormLabel>
              <OutlinedInput
                id="PBAC_level"
                value={values.PBAC_level}
                name="PBAC_level"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.PBAC_level) && errors.PBAC_level && <FormHelperText>{errors.PBAC_level}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" error={touched.PBAC_street_number_and_name && errors.PBAC_street_number_and_name}>
              <FormLabel component="legend">Street Number and Name</FormLabel>
              <OutlinedInput
                id="PBAC_street_number_and_name"
                value={values.PBAC_street_number_and_name}
                name="PBAC_street_number_and_name"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.PBAC_street_number_and_name) && errors.PBAC_street_number_and_name && <FormHelperText>{errors.PBAC_street_number_and_name}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" error={touched.PBAC_suburb && errors.PBAC_suburb}>
              <FormLabel component="legend">Suburb</FormLabel>
              <OutlinedInput
                id="PBAC_suburb"
                value={values.PBAC_suburb}
                name="PBAC_suburb"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.PBAC_suburb) && errors.PBAC_suburb && <FormHelperText>{errors.PBAC_suburb}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" variant="outlined" size="small" error={touched.PBAC_state && errors.PBAC_state}>
              <FormLabel component="legend">State</FormLabel>
              <Select
                id="PBAC_state"
                name="PBAC_state"
                value={values.PBAC_state}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Please select a state</em>
                </MenuItem>
                <MenuItem value="ACT">ACT</MenuItem>
                <MenuItem value="NSW">NSW</MenuItem>
                <MenuItem value="NT">NT</MenuItem>
                <MenuItem value="QLD">QLD</MenuItem>
                <MenuItem value="SA">SA</MenuItem>
                <MenuItem value="TAS">TAS</MenuItem>
                <MenuItem value="VIC">VIC</MenuItem>
                <MenuItem value="WA">WA</MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset" error={touched.PBAC_postcode && errors.PBAC_postcode}>
              <FormLabel component="legend">Postcode</FormLabel>
              <OutlinedInput
                id="PBAC_postcode"
                value={values.PBAC_postcode}
                name="PBAC_postcode"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.PBAC_postcode) && errors.PBAC_postcode && <FormHelperText>{errors.PBAC_postcode}</FormHelperText> }
            </FormControl>
          </>
        }

        <Divider />
        <Typography variant="h6">Company Meeting Address</Typography>
        <Typography variant="body1">The Company's Meeting address is the address where the directors of the Company will sign the documents associated with registering the Company and cannot be a PO Box. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
        <br/>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.CMA_is && errors.CMA_is}>
          <FormLabel component="legend">What is Company's Meeting address?</FormLabel>
          <Select
            id="CMA_is"
            name="CMA_is"
            value={values.CMA_is}
            onChange={handleChange}
          >
            <MenuItem value="same_as_CRA">Same as Company Registered Address</MenuItem>
            {values.PBAC_is == 'new' && <MenuItem value="same_as_PBAC">Same as Principal Business Address of the Company</MenuItem>}
            <MenuItem value="new">A different address</MenuItem>
          </Select>
        </FormControl>
        {
          values.CMA_is == 'new' && 
          <>
            <FormControl component="fieldset" error={touched.CMA_level && errors.CMA_level}>
              <FormLabel component="legend">Level, Floor, Unit, Office, Suite</FormLabel>
              <OutlinedInput
                id="CMA_level"
                value={values.CMA_level}
                name="CMA_level"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.CMA_level) && errors.CMA_level && <FormHelperText>{errors.CMA_level}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" error={touched.CMA_street_number_and_name && errors.CMA_street_number_and_name}>
              <FormLabel component="legend">Street Number and Name</FormLabel>
              <OutlinedInput
                id="CMA_street_number_and_name"
                value={values.CMA_street_number_and_name}
                name="CMA_street_number_and_name"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.CMA_street_number_and_name) && errors.CMA_street_number_and_name && <FormHelperText>{errors.CMA_street_number_and_name}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" error={touched.CMA_suburb && errors.CMA_suburb}>
              <FormLabel component="legend">Suburb</FormLabel>
              <OutlinedInput
                id="CMA_suburb"
                value={values.CMA_suburb}
                name="CMA_suburb"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.CMA_suburb) && errors.CMA_suburb && <FormHelperText>{errors.CMA_suburb}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" variant="outlined" size="small" error={touched.CMA_state && errors.CMA_state}>
              <FormLabel component="legend">State</FormLabel>
              <Select
                id="CMA_state"
                name="CMA_state"
                value={values.CMA_state}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Please select a state</em>
                </MenuItem>
                <MenuItem value="ACT">ACT</MenuItem>
                <MenuItem value="NSW">NSW</MenuItem>
                <MenuItem value="NT">NT</MenuItem>
                <MenuItem value="QLD">QLD</MenuItem>
                <MenuItem value="SA">SA</MenuItem>
                <MenuItem value="TAS">TAS</MenuItem>
                <MenuItem value="VIC">VIC</MenuItem>
                <MenuItem value="WA">WA</MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset" error={touched.CMA_postcode && errors.CMA_postcode}>
              <FormLabel component="legend">Postcode</FormLabel>
              <OutlinedInput
                id="CMA_postcode"
                value={values.CMA_postcode}
                name="CMA_postcode"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.CMA_postcode) && errors.CMA_postcode && <FormHelperText>{errors.CMA_postcode}</FormHelperText> }
            </FormControl>
          </>
        }
        {
          !values.review && (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={status.back}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
                  fullWidth
                >
                  {isSubmitting ? 'Loading' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Form>
  )
}

const CompanyAdresses = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName,
    review,
    data
  }) => ({
      updateBankDetails,
      refreshUserData,
      review,
      order_reference: businessName,
      care_of: data.care_of || '',
      level: data.level || '',
      street_number_and_name: data.street_number_and_name || '',
      suburb: data.suburb || '',
      state: data.state || '',
      postcode: data.postcode || '',
      occupy_office: data.occupy_office || '',
      who_occupies_office: data.who_occupies_office || '',
      PBAC_is: data.PBAC_is || 'same_as_CRA',
      PBAC_level: data.PBAC_level || '',
      PBAC_street_number_and_name: data.PBAC_street_number_and_name || '',
      PBAC_suburb: data.PBAC_suburb || '',
      PBAC_state: data.PBAC_state || '',
      PBAC_postcode: data.PBAC_postcode || '',
      CMA_is: data.CMA_is || 'same_as_CRA',
      CMA_level: data.CMA_level || '',
      CMA_street_number_and_name: data.CMA_street_number_and_name || '',
      CMA_suburb: data.CMA_suburb || '',
      CMA_state: data.CMA_state || '',
      CMA_postcode: data.CMA_postcode || '',
      handler
    }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    care_of: Yup.string(),
    level: Yup.string(),
    street_number_and_name: Yup.string().required('Required'),
    suburb: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    postcode: Yup.string().required('Required'),
    occupy_office: Yup.string().required('Required'),
    who_occupies_office: Yup.string().when('occupy_office', {is: 'no', then: Yup.string().required('Required')}),
    PBAC_is: Yup.string().required('Required'),
    PBAC_level: Yup.string(),
    PBAC_street_number_and_name: Yup.string().when('PBAC_is', {is: 'new', then: Yup.string().required('Required')}),
    PBAC_suburb: Yup.string().when('PBAC_is', {is: 'new', then: Yup.string().required('Required')}),
    PBAC_state: Yup.string().when('PBAC_is', {is: 'new', then: Yup.string().required('Required')}),
    PBAC_postcode: Yup.string().when('PBAC_is', {is: 'new', then: Yup.string().required('Required')}),
    CMA_is: Yup.string(),
    CMA_level: Yup.string().when('CMA_is', {is: 'new', then: Yup.string().required('Required')}),
    CMA_street_number_and_name: Yup.string().when('CMA_is', {is: 'new', then: Yup.string().required('Required')}),
    CMA_suburb: Yup.string().when('CMA_is', {is: 'new', then: Yup.string().required('Required')}),
    CMA_state: Yup.string().when('CMA_is', {is: 'new', then: Yup.string().required('Required')}),
    CMA_postcode: Yup.string().when('CMA_is', {is: 'new', then: Yup.string().required('Required')}),
  }),
  handleSubmit(
    { handler, ...values },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const CRA = {
      name: 'Company Registered Address',
      id: 'company_registered_address',
      care_of: values.care_of,
      level: values.level,
      street_number_and_name: values.street_number_and_name,
      suburb: values.suburb,
      state: values.state,
      postcode: values.postcode,
      occupy_office: values.occupy_office,
      who_occupies_office: values.who_occupies_office,
      full_address: `${values.care_of} ${values.level} ${values.street_number_and_name}, ${values.suburb}, ${values.state} ${values.postcode}`
    }
    
    const PBAC = values.PBAC_is == 'new' ? {
      name: 'Principal Business Address of the Company',
      id: 'principal_business_address_of_the_company',
      PBAC_level: values.PBAC_level,
      PBAC_street_number_and_name: values.PBAC_street_number_and_name,
      PBAC_suburb: values.PBAC_suburb,
      PBAC_state: values.PBAC_state,
      PBAC_postcode: values.PBAC_postcode,
      full_address: `${values.PBAC_level} ${values.PBAC_street_number_and_name}, ${values.PBAC_suburb}, ${values.PBAC_state} ${values.PBAC_postcode}`
    } : null

    const CMA = values.CMA_is == 'new' ? {
      name: 'Company Meeting Address',
      id: 'company_meeting_address',
      CMA_level: values.CMA_level,
      CMA_street_number_and_name: values.CMA_street_number_and_name,
      CMA_suburb: values.CMA_suburb,
      CMA_state: values.CMA_state,
      CMA_postcode: values.CMA_postcode,
      full_address: `${values.CMA_level} ${values.CMA_street_number_and_name}, ${values.CMA_suburb}, ${values.CMA_state} ${values.CMA_postcode}`
    } : null

    const addresses = [CRA, PBAC, CMA].filter(el => el)
    handler(addresses, values)
  }
})(App)


export default CompanyAdresses
