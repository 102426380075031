import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../Shared/Form'

import TextField from '@material-ui/core/TextField'
import { Box, Button, Typography } from '@material-ui/core'

const App = ({
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting
}) => {
  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography>Starting a new business? First things first, let’s get some info, how about a business name?</Typography>
        <TextField
          id="business_name"
          error={touched.business_name && errors.business_name}
          value={values.business_name}
          name="business_name"
          label="Business Name"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.business_name && errors.business_name}
        /> 
        <Button
          variant="contained"
          color="primary"
          type="submit"
        >
          Next
        </Button>
      </Box>
    </Form>
  )
}

const BusinessNameForm = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName
  }) => ({
      updateBankDetails,
      refreshUserData,
      business_name: businessName,
      handler
    }),
  validationSchema: Yup.object().shape({
    business_name: Yup.string()
      .nullable()
      .required('Required')
  }),
  handleSubmit(
    { business_name, handler },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    console.log('submitted', business_name)
    handler({ business_name })
    // submitBankAccountDetails(
    //   { bank_account_name, bank_account_number, bank_bsb },
    //   localStorage.getItem('token')
    // )
    //   .then(res => {
    //     updateBankDetails(localStorage.getItem('token'))
    //     refreshUserData()
    //     ReactGA.event({
    //       category: GA.ACCOUNT_EVENT_CATEGORY,
    //       action: bank_account_set ? GA.CHANGE_BANK_ACCOUNT : GA.LINK_BANK_ACCOUNT
    //     })
    //     logEvent({
    //       event: AC.BANK_ACCOUNT_ADDED,
    //       url: window.location.href
    //     }, localStorage.getItem('token'))
    //   })
    //   .catch(err => {
    //     switch(err.response.data.error) {
    //       case "InvalidBsbNumber":
    //         setErrors({bank_bsb: "Invalid BSB"})
    //         setSubmitting(false)
    //         break;
    //       case "InvalidAccountNumberFormat":
    //         setErrors({bank_account_number: "Invalid account number"})
    //         setSubmitting(false)
    //         break;
    //       case "contact":
    //         updateBankDetails(localStorage.getItem('token'))
    //         break;
    //     }
    //   })
  }
})(App)

const mapStateToProps = state => ({
  // user: state.user.user,
  // fetching: state.account.fetching,
  // bankDetails: state.account.data.bank,
  // fetching: state.account.fetching
})

const mapDispatchToProps = dispatch => ({
  // updateBankDetails: details => dispatch(bankUpdated(details))
  // refreshUserData: () => dispatch(getAccountData(localStorage.getItem('token'))),
  // updateBankDetails: token => dispatch(getBankDetails(token))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessNameForm)
