import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Snackbar from '@material-ui/core/Snackbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { useHistory, useLocation } from 'react-router-dom'
import LinearProgress from '@material-ui/core/LinearProgress'
import { Context } from '../../context/context'
import BusinessesCollapse from './BusinessesCollapse'
import { fetchBusinesses } from '../../actions/businessesActions'
// import { fetchInitialData } from '../../actions/layoutActions'
import { useSelector, useDispatch } from 'react-redux'
import LoadingPage from '../Shared/LoadingPage';
import { AmplifySignOut } from '@aws-amplify/ui-react';
import { actionLogout } from '../../actions/userActions';
import bhlogo from '../../bhlogo.png'
import bhbanner from '../../bhbanner.svg'
import theme from '../../Theme/theme';
import DashboardIcon from '@material-ui/icons/Dashboard';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import BusinessIcon from '@material-ui/icons/Business';
import CloseIcon from '@material-ui/icons/Close';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import { actionCloseSnackbar, actionOpenSnackbar } from '../../actions/snackbarActions'

const drawerWidth = 300;

const routes = [
  {text:'Dashboard', path: '/dashboard', Icon: DashboardIcon},
  // {text:'My Quotes', path: '/quotes'},
  // {text:'My Documents', path: '/documents'},
  {text:'My Businesses', path: '/businesses', Icon: BusinessCenterIcon, Children: BusinessesCollapse},
  {text:'Partners', path: '/partners', Icon: BusinessIcon},
  {text:'Pricing', path: '/pricing', Icon: AttachMoneyIcon},
]

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
    backgroundColor: '#fff'
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    height: 80,
    marginLeft: drawerWidth,
  },
  subHeader: {
    width: '100%',
    height: '2em'
  },
  logo: {
    maxWidth: '50px',
    borderRadius: '10px',
    marginRight: '16px'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    background: '#fff',
    border: 0
    // background: theme.palette.primary.main
  },
  menu: {
    // backgroundColor: theme.palette.background.mid,
    backgroundColor: theme.palette.background.main,
    color: theme.palette.primary.main,
    // borderRadius: '0px 20px 0px 0px',
    overflow: 'hidden',
    overflowY: 'scroll',
    display: 'flex',
    flexDirection: 'column'
  },
  listItem: {
    color: theme.palette.primary.main
  },
  listItemIcon: {
    color: theme.palette.primary.main
  },
  // necessary for content to be below app bar
  toolbar: { height: 80, ...theme.mixins.toolbar, background: theme.palette.primary.main, justifyContent: 'flex-end' },
  banner: {
    padding: theme.spacing(3),
  },
  bannerImage: {
    maxWidth: '1000px',
  },
  subToolbar: { height: 60, background: '#f5f5f5', boxShadow: theme.shadows[4] },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
    padding: theme.spacing(3),
  },
  wrapper: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.main,
    marginTop: '32px',
    borderRadius: '20px 0px 0px 0px',
    overflow: 'hidden'
  },
  noMargin: {
    margin: 0,
    marginTop: theme.spacing(1),
    padding: 0
  },
}));

export default function LayoutComponent({ children }) {
  const classes = useStyles();
  const context = React.useContext(Context)
  let location = useLocation()
  let history = useHistory()
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.layout.isFetching)
  const user = useSelector(state => state.user)
  const snackbar = useSelector(state => state.snackbar)

  const handleLogOut = (nextAuthState, data) => {
    console.log('next', nextAuthState)
    console.log('data', data)
    nextAuthState == 'signedout' && dispatch(actionLogout())
  }

  // React.useEffect( () => { dispatch(fetchInitialData()) }, [] )

  return (
    isFetching ? 
      <LoadingPage /> :
      <div className={classes.root} >
        <CssBaseline />
        {/* <AppBar position="fixed" className={classes.appBar} elevation={0}>
          {
            user?.jwt && 
            <Toolbar className={classes.toolbar}>
              <IconButton aria-label="show 17 new notifications" color="inherit">
                <Badge badgeContent={17} color="secondary">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Button color="inherit">{user.data.username}</Button>
            </Toolbar>
          }
        </AppBar> */}
        <Drawer
          className={classes.drawer}
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          anchor="left"
        >
          <Box width="100%" height="150px" display="flex" alignItems="center" justifyContent="center">
            <img src={bhlogo} alt="Brandhub logo" className={classes.logo}/>
            <Typography variant="h1" color="primary">Brandhub</Typography>
          </Box>
          <Box width="calc(100% - 16px)" height="100%" borderRadius="0px 20px 0px 0px" className={classes.menu}>
            <List className={classes.noMargin}>
              {routes.map(({text, path, Icon, Children}, index) => (
                <React.Fragment key={`__${index}${text}`} >
                  <ListItem 
                    button 
                    selected={location.pathname == path} 
                    onClick={() => history.push(path)}
                    className={classes.listItem}>
                    <ListItemIcon className={classes.listItemIcon}>{Icon && <Icon />}</ListItemIcon>
                    <ListItemText primary={text} />
                  </ListItem>
                  { 
                    Children && location.pathname.includes(path) &&
                    <Box paddingLeft="32px" paddingRight="16px" marginY="16px">
                      <Children open={location.pathname.includes(path)} />
                    </Box>
                  }
                </React.Fragment>
              ))}
            </List>
            { user?.jwt && <Box marginTop="auto"><AmplifySignOut key="__amplifySignOut" handleAuthStateChange={handleLogOut}/></Box> }
          </Box>
        </Drawer>
        <main className={classes.wrapper}>
          {/* <Box className={classes.banner}>
            <object data={bhbanner} alt="Brandhub banner" className={classes.bannerImage}/>
          </Box> */}
          {/* <div className={classes.toolbar} />
          <Toolbar className={classes.subToolbar}>
          </Toolbar> */}
          { context.isLoading ? <LinearProgress /> : null }
          <div className={classes.content}>
            { children }
            <Snackbar
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              open={snackbar.isOpen}
              autoHideDuration={3000}
              onClose={() => dispatch(actionCloseSnackbar())}
              message={snackbar.message}
              action={
                <IconButton size="small" aria-label="close" color="inherit" onClick={() => dispatch(actionCloseSnackbar())}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              }
            />
          </div>
        </main>
      </div>
  );
}