import React from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Toolbar, Typography, Box, Button, CardActions, CardContent } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import DescriptionIcon from '@material-ui/icons/Description';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PaperTitle from '../../../Theme/customComponents/PaperTitle';
import BlockIcon from '@material-ui/icons/Block';
import CardTitle from '../../../Theme/customComponents/CardTitle';
import QuickAction from '../../Shared/QuickAction';
import { capitalizeString } from '../../../helpers/helpers';
import { useStatusClasses } from '../../../Theme/statusClasses'
import QuickActionsTextSnippet from '../QuickActionsTextSnippet';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ResultCard from '../../Shared/ResultCard';

const ResultsPage = ({ id, name, back, results, domains = null, scrollFunction }) => {
  const classes = useStatusClasses()
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  const history = useHistory()
  return (
    <>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Card>
          <CardTitle title={`Availability for ${name}`} />
          <CardContent>
            <Box display="flex" flexDirection="column" height="fit-content">
              {/* <Typography>
                Usually there are common names amongst businesses, but as  long as you are not in the same industry
              </Typography> */}
              <ResultCard results={results} />
              <br/>
              <br/>
              {
                domains ? 
                <Card>
                  <CardHeader title={`Domain name availability`} />
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Domain</TableCell>
                        <TableCell>Availability</TableCell>
                        <TableCell>Price</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                        domains.map(domain => (
                          <TableRow>
                            <TableCell>{domain.domain}</TableCell>
                            <TableCell>{domain.available ? 'Yes' : 'No'}</TableCell>
                            <TableCell>{domain.price ? `${domain.currency} ${domain.price / 1000000}` : '---'}</TableCell>
                          </TableRow>
                        ))
                      }
                    </TableBody>
                  </Table>
                </Card>
                : null
              }
            </Box>
          </CardContent>
          <CardActions>
            <Button variant="contained" color="primary" onClick={() => back()}>
              Check another name
            </Button>
            <Button variant="contained" color="primary" onClick={id ? () => history.push(`/businesses/${id}`) : () => history.push(`/dashboard`)}>
              {id ? 'Return to business page' : 'Return to Dashboard'}
            </Button>
          </CardActions>
        </Card>
      </Box>
      <QuickActionsTextSnippet />
    </>
  )
}

export default ResultsPage
