import { blue, green, orange, red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  status: {
    warning: orange,
    success: green,
    error: red,
    inProgress: blue
  },
  palette: {
    primary: {
      light: '#6666A7',
      main: '#242582',
      dark: '#1D1E68'
    },
    secondary: {
      light: '#6666A7',
      main: '#242582',
      dark: '#1D1E68'
    },
    // secondary: {
    //   light: '#BBDDF5',
    //   main: '#9ECFF0',
    //   dark: '#7EA6C0'
    // },
    background: {
      light: '#EEEEF6',
      main: '#E7E6F2',
      dark: '#B9B8C2'
    },
    text: {
      primary: '#0F0B33'
    },
    success: {
      light: '#B4EA89',
      main: '#94E157',
      dark: '#76B446'
    },
    warning: {
      light: '#FBB46D',
      main: '#F9942F',
      dark: '#C77626'
    },
    error: {
      light: '#F8849E',
      main: '#F54F74',
      dark: '#C43F5D'
    },
  },
  typography: {
    h1: {
      fontWeight: 'bold',
      fontSize: '28px',
      fontFamily: '"Montserrat"'
    },
    h3: {
      fontWeight: 500,
      fontSize: '26px',
      fontFamily: '"Montserrat"'
    },
    h4: {
      fontWeight: 500,
      fontSize: '22px',
      fontFamily: '"Montserrat"'
    },
  },
  overrides: {
    MuiButton: {
      root: {
        margin: '4px 6px',
      }
    },
    // MuiListItem: {
    //   root: {
    //     color: props => props.nav && '#f4f4f4'
    //   }
    // },
    MuiListItemIcon: {
      root: {
        color: '#f4f4f4'
      }
    },
    MuiPaper: {
      root: {
        height: props => props.fill ? '100%' : 'auto'
      }
    },
    MuiFormControl: {
      root: {
        marginTop: 8,
        marginBottom: 12
      }
    },
    MuiFormLabel: {
      root: {
        marginBottom: 8
      }
    },
    MuiTextField: {
      root: {
        margin: props => props.fixedLabel && '0'
      }
    },
    MuiDivider: {
      root: {
        marginTop: 16,
        marginBottom: 16
      }
    },
    MuiStepper: {
      root: {
        backgroundColor: '#E7E6F2'
      }
    },
    MuiStepIcon: {
      completed: {
        color: '#76B446 !important'
      }
    }
  },
});

export default theme