import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BusinessNameForm from './Forms/BusinessNameForm';
// import LogoForm from './Forms/LogoForm';
import CheckLogoForm from './Forms/CheckLogoForm';
import AdditionalBusinessInfoForm from './Forms/AdditionalBusinessInfoForm';
import { actionCheckLogo, requestQuote } from '../../actions/businessesActions';
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Quote from './Forms/Quote';
import EmailSent from './EmailSent';
import { actionAddOrder } from '../../actions/ordersActions';
import AsicAddNameForm from '../QuickActions/RegisterBusiness/Forms/AsicAddNameForm'
import LoadingPage from '../Shared/LoadingPage';
import LogoForm from './Forms/LogoForm';
import { actionFetchDocuments } from '../../actions/documentsActions';
import PageTitle from '../Shared/PageTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContent: {
    alignItems: 'center'
  }
}));


const Copyright = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = React.useState(0);
  const [formState, setFormState] = React.useState({
    order_type: 'trademark',
    business: null,
    business_name: null,
    logo: null,
    logo_checked: null,
    main_purpose: null,
    date_commenced: null,
    instagram: null,
    website: null
  })

  const location = useLocation()
  const path = location.pathname.split('/').reverse()[0]
  const businesses = useSelector(state => state.businesses?.data?.items)
  const currentBusiness = businesses?.filter(b => b.BusinessId == path)[0]
  const history = useHistory()

  const [steps, setSteps] = React.useState([
    // {label: `Select the logo for ${currentBusiness?.name}`, ref: React.useRef()}, 
    {label: 'Select business name', ref: React.useRef()}, 
    {label: 'Check your logo', optional: true, ref: React.useRef()}, 
    {label: 'Review your quote', ref: React.useRef()},
    {label: 'Application result', ref: React.useRef()}
  ])

  const handleNext = (amount = 1) => {
    setActiveStep((prevActiveStep) => prevActiveStep + amount);
  };

  const handleSkip = (stepIndex, skipAmount) => {
    setSteps(prev => prev.map((step, i) => (((i == stepIndex) || (i == skipAmount - 1)) ? {...step, skipped: true} : step )))
    handleNext(skipAmount)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const quoteHandler = () => {
    // dispatch(requestQuote({ id: currentBusiness.id, checklist: {
    //   logo_copyright: {status: formState.logo_check == 'error' ? formState.logo_check : 'pending'},
    //   name_copyright: {status: 'pending'}
    // } }))
    dispatch(actionAddOrder({ ...formState }))
    handleNext()
  }

  const checkLogoHandler = runSideEffects => {
    setFormState(prev => ({ ...prev, logo_checked: true }));
    dispatch(actionCheckLogo(currentBusiness.BusinessId, runSideEffects))
  }
  const checkLogoResultHandler = result => {
    setFormState(prev => ({ ...prev, logo_check: result })) // result from emulateApiCall
    handleNext()
  }
  const uploadLogoHandler = values => {
    console.log(values, 'values from handler upload logo')
    setFormState(prev => ({ ...prev, ...values }))
    dispatch(actionFetchDocuments()) 
    handleNext()
  }

  const handleBusiness = ({ business_name }) => {
    const b = businesses?.filter(b => b.Properties.business_name == business_name)[0]
    setFormState(prev => ({ ...prev, business: b, logo: b.Properties.logo })); //todo: continue here
    handleNext()
  }

  function getStepContent(step) {
    let scrollFunction = () => {
      let y = steps[step]?.ref?.current?.getBoundingClientRect().top + window.pageYOffset - 80 - 16 //todo: make these numbers globals
      window.scrollTo({top: y, behavior: 'smooth'})
    } 
    switch (step) {
      case 0:
        return <AsicAddNameForm 
                preSelected={currentBusiness?.Properties.business_name} 
                businesses={businesses} 
                handler={handleBusiness}
                title="What name would you like to copyright?"
                label="Select business name"
                buttonText="Continue"
                />;
      case 1: 
        return <LogoForm businessName={formState.business?.Properties.business_name} logo={formState.business?.Properties.logo} handler={uploadLogoHandler} skip={() => handleSkip(step)} back={handleBack} scrollFunction={scrollFunction}/>        
        // return <CheckLogoForm 
        //           business={currentBusiness?.Properties.business_name} 
        //           logo={currentBusiness?.Properties.logo} 
        //           checkLogo={checkLogoHandler} 
        //           handler={checkLogoResultHandler}
        //           skip={() => handleSkip(step, 1)} 
        //           back={handleBack} 
        //           scrollFunction={scrollFunction}
        //           title={name => {
        //             return currentBusiness?.Properties.checklist.logo_copyright.completed ?
        //               <Typography> You already copyrighted the name <strong>{name}</strong>, good job! We recomend you check and trademark a logo now </Typography>:
        //               <Typography> Before we proceed to trademark the name <strong>{name}</strong>, we recomend you upload a logo and trademark these assets together</Typography>
        //           }}
        //         />;
      case 2:
        return <Quote logo={formState.logo} formState={formState} business={formState.business} handler={quoteHandler} back={handleBack} scrollFunction={scrollFunction}/>; 
      case 3:
        return <EmailSent scrollFunction={scrollFunction}/>; 
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      {
        businesses ?
          <>
            <PageTitle>Trademark a brand</PageTitle>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map(({label, optional, skipped, ref}, index) => {
                let labelProps = {}
                if(optional){
                  labelProps.optional = <Typography variant="caption">Optional</Typography>
                }
                let stepProps = {}
                if(skipped){
                  stepProps.completed = false
                }
                return(
                <Step {...stepProps} key={label} ref={ref}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                  <StepContent className={classes.stepContent}>
                    {getStepContent(index)}
                  </StepContent>
                </Step>
              )})}
            </Stepper>
            {activeStep === steps.length && (
              <Paper square elevation={0} className={classes.resetContainer}>
                <Typography>All steps completed - you&apos;re finished! We will now take you to your Business view</Typography>
                <Button onClick={handleReset} className={classes.button}>
                  Reset
                </Button>
              </Paper>
            )}
          </> : 
          <LoadingPage />
      }
    </div>
  );
}

export default Copyright