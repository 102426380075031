
const emulateFetchingBusinessesName = new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve({data: [
      { name: 'LensBender'},
      { name: 'Candy Co.' },
      { name: 'Leisure Sweet Studios' },
      { name: 'The Anchor BMX' },
    ]})
  }, 2000)
})

const emulateFetchingSomethingElse = new Promise((resolve, reject) => {
  setTimeout(() => {
    resolve({error: 'no data'})
  }, 2200)
})

export const fetchMenuItemsApi = async token => {
  const Headers = {
    Authorization: `Bearer ${token}`
  }
  try {
    const businesses = (await emulateFetchingBusinessesName()).data
    const somethingElse = (await emulateFetchingSomethingElse()).data
    
    return new Promise(resolve =>
      resolve({
        businesses: businesses,
        somethingElse: somethingElse
      })
    )
  } catch (e) {
    return new Promise((resolve, reject) => reject(e))
  } // I dont want this because if anything fails, the promise gets rejected.
}