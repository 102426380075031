import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaperTitle from '../../Theme/customComponents/PaperTitle';
import { Toolbar, Typography, Box } from '@material-ui/core';
import QuickAction from '../Shared/QuickAction';
import DescriptionIcon from '@material-ui/icons/Description';
import SearchBar from '../Shared/SearchBar';
import Clickable, { ClickableSkeleton } from '../Shared/Clickable';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import QuickActionsSnippet from '../QuickActions/QuickActionsSnippet';

import { makeStyles } from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionContext from '@material-ui/core/Accordion/AccordionContext';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(theme => {
  const transition = {
    duration: theme.transitions.duration.shortest,
  };

  return({
    MuiAccordionroot: {
      display: 'flex',
      flexDirection: 'column',
      "&.MuiAccordion-root:before": {
        backgroundColor: "white"
      }
    },
    expandIcon: {
      transform: 'rotate(0deg)',
      transition: theme.transitions.create('transform', transition),
      '&:hover': {
        // Disable the hover effect for the IconButton,
        // because a hover effect should apply to the entire Expand button and
        // not only to the IconButton.
        backgroundColor: 'transparent',
      },
      '&$expanded': {
        transform: 'rotate(180deg)',
      },
    },
    details: {
      paddingLeft: 4
    }
})})

const DocumentsComponent = () => {
  const businesses = useSelector(state => state.businesses?.data?.items)
  const [selected, setSelected] = React.useState(businesses)
  const [expanded, setExpanded] = React.useState(false);
  const history = useHistory()
  const classes = useStyles()

  React.useEffect(() => {
    setSelected(businesses)
  }, [businesses])

  const handleChange = (panel) => {
    setExpanded(expanded == panel ? false : panel);
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item sm={12} md={5}>
          <Paper fill>
            <PaperTitle>
              <Typography variant="h6">
                Search by business
              </Typography>
            </PaperTitle>
            <Box p={2} display="flex" flexDirection="column">
              <SearchBar target="businesses" updateFunction={setSelected} matchOn='name' fullSet={businesses}/>
              { selected ? selected.map(b => (
                <Accordion key={b.id} expanded={expanded === b.name} elevation={0} classes={{
                  root: classes.MuiAccordionroot
                }}>
                  <Clickable rightIcon={<ExpandMoreIcon className={classes.expandIcon} />} onClick={() => handleChange(b.name)}>{b.name}</Clickable>
                  <AccordionDetails className={classes.details}>
                    <Box display="flex" flexDirection="column" paddingLeft="24px" width="100%">
                      <Clickable> Document 1 </Clickable>
                      <Clickable> Document 2 </Clickable>
                    </Box>
                  </AccordionDetails>
                </Accordion>
                // <Clickable onClick={() => history.push(`/businesses/${b.name}`)}> {b.name} </Clickable>
              )) : <ClickableSkeleton amount="3" /> }
              <QuickAction onClick={() => history.push(`/add_business`)}>Add new business</QuickAction>
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={12} md={7}>
          <Paper fill>
            <PaperTitle>
              <Typography variant="h6">
                Document
              </Typography>
            </PaperTitle>
            <Box p={2} display="flex" flexDirection="column">
              <p>Tap on a document to display</p>
            </Box>
          </Paper>
        </Grid>
        <Grid item sm={12}>
          <QuickActionsSnippet />
        </Grid>
      </Grid>
    </div>
  )
}

export default DocumentsComponent
