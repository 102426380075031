import * as ordersApi from '../api/ordersApi'

export const FETCHING = '@@redux-orders/FETCHING'
export const FETCH_ORDERS = '@@redux-orders/FETCH_ORDERS'
export const FETCH_ORDER = '@@redux-orders/FETCH_ORDER'
export const ADD_ORDERS = '@@redux-orders/ADD_ORDERS'
export const LOGOUT = '@@redux-orders/LOGOUT'

export const fetchInitialData = () => {
  return dispatch => {
    dispatch(fetching())
    setTimeout(() => {
      dispatch(fetching(false))
    }, 2000)
  }
}

export const actionGetOrder = orderId => {
  return dispatch => {
    ordersApi.getOrder(orderId)
    .then(res => console.log(res))
  }
}

export const actionGetOrders = () => {
  return dispatch => {
    ordersApi.getOrders()
      .then(res => {
        console.log(res.data, 'res.data')
        dispatch(fetchOrders(res.data.data))
      })
  }
}

export const actionAddOrder = (order, sideEffects = () => {}) => {
  return dispatch => {
    ordersApi.addOrder(order)
    .then(res => {
      sideEffects()
    })
  }
}

export const fetching = (val = true) => ({
  type: FETCHING,
  payload: val
})

export const fetchOrders = orders => ({
  type: FETCH_ORDERS,
  payload: orders
})

export const cleatOrders = () => ({
  type: LOGOUT
})