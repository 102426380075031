import {
  TRIGGER,
  CLOSE
} from '../actions/snackbarActions'

const initialState = {
  isOpen: false,
  message: null
}

export default (state = initialState, action) => {
  switch (action.type) {

    case TRIGGER: {
      return {
        isOpen: true,
        message: action.payload
      }
    }

    case CLOSE: {
      return initialState
    }

    default: {
      return state
    }
  }
}
