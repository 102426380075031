import React from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Toolbar, Typography, Box, Button, makeStyles, Divider } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import SearchBar from '../Shared/SearchBar';
import Clickable, { ClickableSkeleton } from '../Shared/Clickable';
import Skeleton from '@material-ui/lab/Skeleton';
import BlockIcon from '@material-ui/icons/Block';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';
import { useSelector } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom'
import PaperTitle from '../../Theme/customComponents/PaperTitle';
import { useStatusClasses } from '../../Theme/statusClasses';
import moment from 'moment'
import LoadingPage from '../Shared/LoadingPage';
import ResultCard from '../Shared/ResultCard';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { requestRead } from '../../api/documentsApi';
import BusinessPreview from './BusinessPreview';
import Container, { useContainer } from '../Shared/Container'
import QuickAction from '../Shared/QuickAction';
import { resolveAction } from '../../helpers/helpers'

const useStyles = makeStyles(theme => ({
  logo: {
    maxWidth: '300px',
    maxHeight: '300px',
    width: 'auto',
    height: 'auto',
  }
}))

const IndividualBusinessComponent = ({ business }) => {
  const location = useLocation()
  const checklist = business?.Properties.checklist
  const [dialog, setDialog] = React.useState({ open: false, content: null })
  const userId = useSelector(state => state.user.data.sub)

  const handleClose = () => setDialog(prev => ({ ...prev, open: false}))

  const [logo, setLogo] = React.useState(null)

  React.useEffect(() => {
    business?.Properties.logo && getLogo(business?.Properties.logo)
  }, [business])


  const getLogo = filename => {
    requestRead(filename)
      .then(res => {
        console.log(res)
        setLogo(res.data.documentUrl)
      })
      .catch(err => {
        console.dir(err)
      })
  }

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog.open}>
        <DialogTitle id="simple-dialog-title">Similarities found</DialogTitle>
      </Dialog>
      <BusinessPreview business={business} />
      <Grid container spacing={3}>
        <Grid item sm={12} md={5}>
          <Container fill>
            <Typography variant="h3">
              Brand Checklist
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="column" height="fit-content">
              {
                checklist ?
                  <ResultCard dense results={checklist} business={business}/> :
                  <LoadingPage />
              }
            </Box>
          </Container>
        </Grid>
        <Grid item sm={12} md={7}>
          <Container fill>
            <Typography variant="h3">
              To do next
            </Typography>
            <Divider />
              {
                checklist ?
                  checklist
                    .filter(check => !['completed', 'warning', 'pending'].includes(check.status))
                    .map((check, i) => {
                      let resolved = resolveAction(check.name, business)
                      return(
                        <QuickAction>
                          <Typography variant="h3">
                            {resolved?.name}
                          </Typography>
                          <br />
                          <Box display="flex" alignItems="center" justifyContent="space-between">
                            <Typography variant="body1">
                              {resolved?.text}
                            </Typography>
                            <Button variant="contained" color="primary" onClick={resolved?.action}>Start</Button>
                          </Box>
                        </QuickAction>
                      )
                    })[0] :
                  <LoadingPage />
              }
          </Container>
        </Grid>
      </Grid>
    </div>
  )
}

export default IndividualBusinessComponent
