import React from 'react'
import IndividualBusinessComponent from './IndividualBusinessComponent'
import { Context } from '../../context/context'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import LoadingPage from '../Shared/LoadingPage'

const IndividualBusinesses = () => {
  const isFetching = useSelector(state => state.businesses.isFetching)
  const businesses = useSelector(state => state.businesses?.data?.items)
  const context = React.useContext(Context)
  const location = useLocation()
  const [currentBusiness, setCurrentBusiness] = React.useState(null)
  const path = location.pathname.split('/').reverse()[0]

  React.useEffect(() => { 
    if(businesses) {
      const desiredBusiness = businesses.filter(b => b.BusinessId == path)[0]
      //? maybe: Fetch updated record for specific business instead
      if (desiredBusiness) {
        setCurrentBusiness(desiredBusiness)
      }
    }
  }, [businesses, location])
  React.useEffect(() => { context.setLoading(isFetching) }, [isFetching])

  return (
    businesses ? 
      currentBusiness ? 
        <IndividualBusinessComponent business={currentBusiness}/> : 
        <LoadingPage /> :
        <LoadingPage /> 
  )
}

export default IndividualBusinesses
