import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import TextField from '@material-ui/core/TextField'
import { Box, Button, Typography, CircularProgress } from '@material-ui/core'
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const App = ({
  businesses,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  scrollFunction,
  isSubmitting
}) => {
  const filter = createFilterOptions();
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Box width="100%" height="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center" bgcolor="#aaa">
          Top Docs Embed UI
        </Box>
        <Box display="flex">
          <Button
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isSubmitting}
            endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
          >
            {isSubmitting ? 'Checking availability...' : 'Begin registration'}
          </Button>
        </Box>
      </Box>
    </Form>
  )
}

const AddNameForm = withFormik({
  mapPropsToValues: ({
    handler
  }) => ({
      handler
    }),
  validationSchema: Yup.object().shape({

  }),
  handleSubmit(
    { handler },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    setTimeout(() => {
      handler(true)
    }, 2000)
  }
})(App)

export default AddNameForm
