// import React from 'react'
// import { Link } from 'react-router-dom'
// import { default as MUIButton } from '@material-ui/core/Button'
// import { withStyles, ThemeProvider } from "@material-ui/styles";
// import { makeStyles } from "@material-ui/core/styles";
// import { Box, CircularProgress } from '@material-ui/core'
// import { useTheme } from '@material-ui/core/styles';

// const useStyles = makeStyles(theme => {
//   const defineMargin = props => {
//     return props.margin ? { margin: theme.spacing(.5) } : { 
//       margin: 0,
//       marginTop: theme.spacing(.5),
//       marginBottom: theme.spacing(.5) 
//     } 
//   }  
//   return({
//     quickAction: props => ({
//       ...defineMargin(props),
//       minHeight: '3em',
//       flexGrow: 1,
//       flexBasis: 1,
//       fontSize: theme.typography.fontSize + 6,
//       textTransform: 'none',
//       borderRadius: 0,
//       lineHeight: 1.2
//     })
//   })
// })

// const QuickAction = ({ children, color, leftIcon = null, rightIcon = null, onClick, loading, rotation = null, margin, ...rest }) => {
//   const clickHandler = () => (
//     onClick && 
//     setTimeout(() => {
//       onClick()
//     }, 100)
//   )
//   const theme = useTheme()
//   const styles = useStyles({ margin })

//   return(
//     <MUIButton
//       color={color || "primary"}
//       variant="contained"
//       onClick={clickHandler}
//       disabled={loading || rest.disabled}
//       disableElevation
//       {...rest}
//       className={styles.quickAction}
//     >
//       <Box pr={1} alignItems="center" justifyContent="center" display="flex">
//         {leftIcon && loading ? <CircularProgress size={theme.typography.button.fontSize} /> : leftIcon}
//       </Box>
//       {children}
//       {rightIcon && loading ? <CircularProgress size={theme.typography.button.fontSize} /> : rightIcon}
//     </MUIButton>
// )}

// export default QuickAction

import React from 'react'
import { Link } from 'react-router-dom'
import { default as MUIButton } from '@material-ui/core/Button'
import { withStyles, ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import Container from './Container';

const useStyles = makeStyles(theme => {
  return({
    quickAction: {
      backgroundColor: theme.palette.background.main
    }
  })
})



const QuickAction = ({ children, color, leftIcon = null, rightIcon = null, onClick, loading, rotation = null, margin, fill, ...rest }) => {
  const clickHandler = () => (
    onClick && 
    setTimeout(() => {
      onClick()
    }, 100)
  )
  const theme = useTheme()
  const classes = useStyles({ margin })

  return(
    // <MUIButton
    //   color={color || "primary"}
    //   variant="contained"
    //   onClick={clickHandler}
    //   disabled={loading || rest.disabled}
    //   disableElevation
    //   {...rest}
    //   className={styles.quickAction}
    // >
    //   <Box pr={1} alignItems="center" justifyContent="center" display="flex">
    //     {leftIcon && loading ? <CircularProgress size={theme.typography.button.fontSize} /> : leftIcon}
    //   </Box>
    //   {children}
    //   {rightIcon && loading ? <CircularProgress size={theme.typography.button.fontSize} /> : rightIcon}
    // </MUIButton>
    <Container className={classes.quickAction} fill={fill}>
      {children}
    </Container>
)}

export default QuickAction