import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import TextField from '@material-ui/core/TextField'
import { Box, Button, Typography, CircularProgress, InputLabel, FormHelperText, InputAdornment, OutlinedInput, Grid } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'

const App = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting,
  submitCount,
  scrollFunction
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  return (
    <Form>
      { values.review && <Box position="absolute" width="100%" height="100%" zIndex="999" bgcolor="rgba(0,0,0,0.1)"></Box> }
      <Box width="80%" maxWidth="800px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h6">Company Details</Typography>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.do_you_have_a_proposed_name && errors.do_you_have_a_proposed_name}>
          <FormLabel component="legend">Do you have a proposed name for the Company, or will the Company Name be the ACN given to the Company upon registration?</FormLabel>
          <Select
            id="do_you_have_a_proposed_name"
            name="do_you_have_a_proposed_name"
            value={values.do_you_have_a_proposed_name}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Please select an option</em>
            </MenuItem>
            <MenuItem value="register_company_name">I have a Company Name I would like to register</MenuItem>
            <MenuItem value="acn_as_company_name">I wish for the ACN to be the Company Name</MenuItem>
          </Select>
          { (submitCount > 0 || touched.do_you_have_a_proposed_name) && errors.do_you_have_a_proposed_name && <FormHelperText>{errors.do_you_have_a_proposed_name}</FormHelperText> }
        </FormControl>
        {
          values.do_you_have_a_proposed_name == 'register_company_name' ?
            <>
              <Divider />
              <FormControl component="fieldset" error={touched.have_you_previously_reserved_this_company_name && errors.have_you_previously_reserved_this_company_name}>
                <FormLabel component="legend">Have you previously reserved this Company Name with ASIC (by lodging a Form 410 with ASIC?)</FormLabel>
                <RadioGroup row aria-label="have_you_previously_reserved_this_company_name" name="have_you_previously_reserved_this_company_name" value={values.have_you_previously_reserved_this_company_name} onChange={handleChange}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                { (submitCount > 0 || touched.have_you_previously_reserved_this_company_name) && errors.have_you_previously_reserved_this_company_name && <FormHelperText>{errors.have_you_previously_reserved_this_company_name}</FormHelperText> }
              </FormControl> 
              {
                values.have_you_previously_reserved_this_company_name == "yes" ? 
                  <>
                    <FormControl component="fieldset" error={touched.company_name_reservation_number && errors.company_name_reservation_number}>
                      <FormLabel component="legend">Company name reservation number</FormLabel>
                      <OutlinedInput
                        id="company_name_reservation_number"
                        value={values.company_name_reservation_number}
                        name="company_name_reservation_number"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        margin="dense"
                        variant="outlined"
                        fixedLabel="true"
                      /> 
                      { (submitCount > 0 || touched.company_name_reservation_number) && errors.company_name_reservation_number && <FormHelperText>{errors.company_name_reservation_number}</FormHelperText> }
                    </FormControl>
                    <FormControl component="fieldset" variant="outlined" size="small" error={touched.who_lodged_the_company_name_reservation_form && errors.who_lodged_the_company_name_reservation_form}>
                      <FormLabel component="legend">Who lodged the Company Name Reservation Form?</FormLabel>
                      <Select
                        id="who_lodged_the_company_name_reservation_form"
                        name="who_lodged_the_company_name_reservation_form"
                        value={values.who_lodged_the_company_name_reservation_form}
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>Please select an option</em>
                        </MenuItem>
                        <MenuItem value="individual">An individual</MenuItem>
                        <MenuItem value="organisation">An organisation</MenuItem>
                      </Select>
                      <FormHelperText>{(touched.who_lodged_the_company_name_reservation_form && errors.who_lodged_the_company_name_reservation_form) || `You must enter the name of the lodging party exactly as it was stated in the Company Name Reservation Form`}</FormHelperText>
                    </FormControl>
                    {
                      values.who_lodged_the_company_name_reservation_form == 'individual' ?
                        <>
                          <FormControl component="fieldset" error={touched.individual_given_names && errors.individual_given_names}>
                            <FormLabel component="legend">Given Names</FormLabel>
                            <OutlinedInput
                              id="individual_given_names"
                              value={values.individual_given_names}
                              name="individual_given_names"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="dense"
                              variant="outlined"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.individual_given_names) && errors.individual_given_names && <FormHelperText>{errors.individual_given_names}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" error={touched.individual_family_name && errors.individual_family_name}>
                            <FormLabel component="legend">Family Name</FormLabel>
                            <OutlinedInput
                              id="individual_family_name"
                              value={values.individual_family_name}
                              name="individual_family_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="dense"
                              variant="outlined"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.individual_family_name) && errors.individual_family_name && <FormHelperText>{errors.individual_family_name}</FormHelperText> }
                          </FormControl>
                        </> : values.who_lodged_the_company_name_reservation_form == 'organisation' ?
                        <>
                          <FormControl component="fieldset" error={touched.organisation_or_company_name && errors.organisation_or_company_name}>
                            <FormLabel component="legend">Organisation / Company Name</FormLabel>
                            <OutlinedInput
                              id="organisation_or_company_name"
                              value={values.organisation_or_company_name}
                              name="organisation_or_company_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="dense"
                              variant="outlined"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.organisation_or_company_name) && errors.organisation_or_company_name && <FormHelperText>{errors.organisation_or_company_name}</FormHelperText> }
                          </FormControl>
                        </> : null
                    }
                  </> : 
                  null
              }
            </> : null
        }

        <Divider />
        {
          values.do_you_have_a_proposed_name == 'register_company_name' ? 
            <>
              <FormControl component="fieldset" error={touched.company_name && errors.company_name}>
                <FormLabel component="legend">Company Name to register</FormLabel>
                <OutlinedInput
                  id="company_name"
                  value={values.company_name}
                  name="company_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="dense"
                  variant="outlined"
                  fixedLabel="true"
                /> 
                <FormHelperText>{((submitCount > 0 || touched.company_name) && errors.company_name) || "Please only enter the Name of the Company, without the 'Pty Ltd'"}</FormHelperText>
              </FormControl>
            </> : null
        }
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.what_type_of_company_do_you_wish_to_register && errors.what_type_of_company_do_you_wish_to_register}>
          <FormLabel component="legend">What type of company do you wish to register?</FormLabel>
          <Select
            id="what_type_of_company_do_you_wish_to_register"
            name="what_type_of_company_do_you_wish_to_register"
            value={values.what_type_of_company_do_you_wish_to_register}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Please select a company type</em>
            </MenuItem>
            <MenuItem value="Pty Ltd">Pty Ltd</MenuItem>
            <MenuItem value="Pty Limited">Pty Limited</MenuItem>
            <MenuItem value="Proprietary Limited">Proprietary Limited</MenuItem>
            <MenuItem value="Pty Ltd.">Pty Ltd.</MenuItem>
            <MenuItem value="Pty. Ltd">Pty. Ltd</MenuItem>
            <MenuItem value="Pty. Ltd.">Pty. Ltd.</MenuItem>
            <MenuItem value="Pty. Limited">Pty. Limited</MenuItem>
            <MenuItem value="Proprietary Ltd">Proprietary Ltd</MenuItem>
            <MenuItem value="Proprietary Ltd.">Proprietary Ltd.</MenuItem>
          </Select>
          <FormHelperText>{(touched.what_type_of_company_do_you_wish_to_register && errors.what_type_of_company_do_you_wish_to_register) || `All options have the same legal elements`}</FormHelperText>
        </FormControl>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.in_which_state && errors.in_which_state}>
          <FormLabel component="legend">In which state or territory is the Company to be registered in?</FormLabel>
          <Select
            id="in_which_state"
            name="in_which_state"
            value={values.in_which_state}
            onChange={handleChange}
          >
            <MenuItem value="">
              <em>Please select a state</em>
            </MenuItem>
            <MenuItem value="ACT">ACT</MenuItem>
            <MenuItem value="NSW">NSW</MenuItem>
            <MenuItem value="NT">NT</MenuItem>
            <MenuItem value="QLD">QLD</MenuItem>
            <MenuItem value="SA">SA</MenuItem>
            <MenuItem value="TAS">TAS</MenuItem>
            <MenuItem value="VIC">VIC</MenuItem>
            <MenuItem value="WA">WA</MenuItem>
          </Select>
        </FormControl>

        <Divider />


        {
          values.do_you_have_a_proposed_name == 'register_company_name' && 
            <>
              <FormControl component="fieldset" error={touched.is_company_name_same_as_an_already_registered_business && errors.is_company_name_same_as_an_already_registered_business}>
                <FormLabel component="legend">Is the Company Name you are registering the same as an already registered Business Name?</FormLabel>
                <RadioGroup row aria-label="is_company_name_same_as_an_already_registered_business" name="is_company_name_same_as_an_already_registered_business" value={values.is_company_name_same_as_an_already_registered_business} onChange={handleChange}>
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
              </FormControl> 
              {
                values.is_company_name_same_as_an_already_registered_business == 'yes' && 
                  <>
                    <FormControl error={touched.confirm_owners_are_officeholders_or_shareholders && errors.confirm_owners_are_officeholders_or_shareholders}>
                      <FormControlLabel
                        control={<Checkbox checked={values.confirm_owners_are_officeholders_or_shareholders} onChange={() => setFieldValue('confirm_owners_are_officeholders_or_shareholders', !values.confirm_owners_are_officeholders_or_shareholders)} name="confirm_owners_are_officeholders_or_shareholders" />}
                        label="I confirm that all of the owners of the Registered Business Name are either officeholders or shareholders of the Company being registered."
                      />
                      { (submitCount > 0 || touched.confirm_owners_are_officeholders_or_shareholders) && errors.confirm_owners_are_officeholders_or_shareholders && <FormHelperText>{errors.confirm_owners_are_officeholders_or_shareholders}</FormHelperText> }
                    </FormControl>
                    <FormControl component="fieldset" error={touched.does_the_registered_business_name_have_an_abn && errors.does_the_registered_business_name_have_an_abn}>
                      <FormLabel component="legend">Does the Registered Business Name have an Australian Business Number?</FormLabel>
                      <RadioGroup row aria-label="does_the_registered_business_name_have_an_abn" name="does_the_registered_business_name_have_an_abn" value={values.does_the_registered_business_name_have_an_abn} onChange={handleChange} >
                        <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                        <FormControlLabel value="no" control={<Radio />} label="No" />
                      </RadioGroup>
                    </FormControl> 
                    {
                      values.does_the_registered_business_name_have_an_abn == 'no' ?
                        <>
                          <Typography variant="body1">Enter the <strong>Registered Business Number</strong> of the Business Name in each state the Business Name is registered:</Typography>
                          <Typography variant="body1">If the Business Name does not have an ABN you are required by ASIC to provide the Registered Business Number for the Business Name in each state the Business Name is registered.</Typography>
                          <Typography variant="body1">Please ensure you enter the Registered Business Number exactly how it is shown in the business register, including any letters at the start or end of the number. If the number entered does not match the ASIC database, your company will be not able to be registered.</Typography>
                          <br/>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_act && errors.rbn_in_act}>
                            <FormLabel component="legend">Australian Capital Territory</FormLabel>
                            <OutlinedInput
                              id="rbn_in_act"
                              value={values.rbn_in_act}
                              name="rbn_in_act"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_act) && errors.rbn_in_act && <FormHelperText>{errors.rbn_in_act}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_nsw && errors.rbn_in_nsw}>
                            <FormLabel component="legend">New South Wales</FormLabel>
                            <OutlinedInput
                              id="rbn_in_nsw"
                              value={values.rbn_in_nsw}
                              name="rbn_in_nsw"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_nsw) && errors.rbn_in_nsw && <FormHelperText>{errors.rbn_in_nsw}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_nt && errors.rbn_in_nt}>
                            <FormLabel component="legend">Northern Territory</FormLabel>
                            <OutlinedInput
                              id="rbn_in_nt"
                              value={values.rbn_in_nt}
                              name="rbn_in_nt"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_nt) && errors.rbn_in_nt && <FormHelperText>{errors.rbn_in_nt}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_qld && errors.rbn_in_qld}>
                            <FormLabel component="legend">Queensland</FormLabel>
                            <OutlinedInput
                              id="rbn_in_qld"
                              value={values.rbn_in_qld}
                              name="rbn_in_qld"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_qld) && errors.rbn_in_qld && <FormHelperText>{errors.rbn_in_qld}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_sa && errors.rbn_in_sa}>
                            <FormLabel component="legend">South Australia</FormLabel>
                            <OutlinedInput
                              id="rbn_in_sa"
                              value={values.rbn_in_sa}
                              name="rbn_in_sa"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_sa) && errors.rbn_in_sa && <FormHelperText>{errors.rbn_in_sa}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_tas && errors.rbn_in_tas}>
                            <FormLabel component="legend">Tasmania</FormLabel>
                            <OutlinedInput
                              id="rbn_in_tas"
                              value={values.rbn_in_tas}
                              name="rbn_in_tas"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_tas) && errors.rbn_in_tas && <FormHelperText>{errors.rbn_in_tas}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_vic && errors.rbn_in_vic}>
                            <FormLabel component="legend">Victoria</FormLabel>
                            <OutlinedInput
                              id="rbn_in_vic"
                              value={values.rbn_in_vic}
                              name="rbn_in_vic"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_vic) && errors.rbn_in_vic && <FormHelperText>{errors.rbn_in_vic}</FormHelperText> }
                          </FormControl>
                          <FormControl component="fieldset" fullWidth error={touched.rbn_in_wa && errors.rbn_in_wa}>
                            <FormLabel component="legend">Western Australia</FormLabel>
                            <OutlinedInput
                              id="rbn_in_wa"
                              value={values.rbn_in_wa}
                              name="rbn_in_wa"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.rbn_in_wa) && errors.rbn_in_wa && <FormHelperText>{errors.rbn_in_wa}</FormHelperText> }
                          </FormControl>
                        </> : values.does_the_registered_business_name_have_an_abn == 'yes' ? 
                        <>
                          <FormControl component="fieldset" fullWidth error={touched.business_abn && errors.business_abn}>
                            <FormLabel component="legend">What's the Australian Business Number of the Business?</FormLabel>
                            <OutlinedInput
                              id="business_abn"
                              value={values.business_abn}
                              name="business_abn"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              margin="normal"
                              variant="outlined"
                              margin="dense"
                              fixedLabel="true"
                            /> 
                            { (submitCount > 0 || touched.business_abn) && errors.business_abn && <FormHelperText>{errors.business_abn}</FormHelperText> }
                          </FormControl>
                        </> : null
                    }
                  </>

              }
            </>
        }

        <FormControl component="fieldset" error={touched.different_share_prices && errors.different_share_prices}>
          <FormLabel component="legend">Do you want to issue shares at different share prices?</FormLabel>
          <RadioGroup row aria-label="different_share_prices" name="different_share_prices" value={values.different_share_prices} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl> 
        {
          values.different_share_prices == 'no' &&
          <FormControl component="fieldset" fullWidth error={touched.share_price && errors.share_price}>
            <FormLabel component="legend">How much will each share in the Company be worth?</FormLabel>
            <OutlinedInput
              id="share_price"
              value={values.share_price}
              name="share_price"
              onChange={handleChange}
              onBlur={handleBlur}
              margin="normal"
              variant="outlined"
              margin="dense"
              fixedLabel="true"
              startAdornment={
                <InputAdornment position="start">
                  $
                </InputAdornment>
              }
            /> 
            { (submitCount > 0 || touched.share_price) && errors.share_price && <FormHelperText>{errors.share_price}</FormHelperText> }
          </FormControl>
        }
        <Divider />
        <FormControl component="fieldset" error={touched.only_for_super && errors.only_for_super}>
          <FormLabel component="legend">Will this company ONLY act as the trustee of a Self Managed Superannuation Fund?</FormLabel>
          <RadioGroup row aria-label="only_for_super" name="only_for_super" value={values.only_for_super} onChange={handleChange}>
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl> 
        {
          values.only_for_super == 'yes' && 
          <>
            <Typography variant="h6">Sole Purpose SMSF Company Declaration</Typography>
            <Typography variant="body1">ASIC must be notified that this Company is going to act only as a trustee of a Self Managed Superannuation Fund. Please read and confirm the following declaration that confirms this Company will act solely as a SMSF Trustee:</Typography>
            <FormControl component="fieldset">
              <FormControlLabel
                control={<Checkbox checked={values.agree_only_for_super} onChange={() => setFieldValue('agree_only_for_super', !values.agree_only_for_super)} name="agree_only_for_super" />}
                label="I declare that this Company is a special purpose Company as defined under regulation 3 of the Corporations (Fees) Regulations 2003"
              />
            </FormControl> 
          </>
        }
        {
          !values.review && (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={status.back}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
                  fullWidth
                >
                  {isSubmitting ? 'Loading' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Form>
  )
}

const CompanyDetails = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    review,
    data
  }) => ({
    updateBankDetails,
    refreshUserData,
    review,
    do_you_have_a_proposed_name: data.do_you_have_a_proposed_name || '',
    have_you_previously_reserved_this_company_name: data.have_you_previously_reserved_this_company_name || '',
    is_company_name_same_as_an_already_registered_business: data.is_company_name_same_as_an_already_registered_business || '',
    confirm_owners_are_officeholders_or_shareholders: data.confirm_owners_are_officeholders_or_shareholders || false,
    company_name_reservation_number: data.company_name_reservation_number || '',
    who_lodged_the_company_name_reservation_form: data.who_lodged_the_company_name_reservation_form || '',
    individual_given_names: data.individual_given_names || '',
    individual_family_name: data.individual_family_name || '',
    organisation_or_company_name: data.organisation_or_company_name || '',
    in_which_state: data.in_which_state || '',
    company_name: data.company_name || '',
    what_type_of_company_do_you_wish_to_register: data.what_type_of_company_do_you_wish_to_register || '',
    does_the_registered_business_name_have_an_abn: data.does_the_registered_business_name_have_an_abn || '',
    business_abn: data.business_abn || '',
    rbn_in_act: data.rbn_in_act || '',
    rbn_in_nsw: data.rbn_in_nsw || '',
    rbn_in_nt: data.rbn_in_nt || '',
    rbn_in_qld: data.rbn_in_qld || '',
    rbn_in_sa: data.rbn_in_sa || '',
    rbn_in_tas: data.rbn_in_tas || '',
    rbn_in_vic: data.rbn_in_vic || '',
    rbn_in_wa: data.rbn_in_wa || '',
    different_share_prices: data.different_share_prices || '',
    share_price: data.share_price || '',
    only_for_super: data.only_for_super || '',
    agree_only_for_super: data.agree_only_for_super || false,
    handler
  }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    do_you_have_a_proposed_name: Yup.string().required('Required'),
      have_you_previously_reserved_this_company_name: Yup.string().when('do_you_have_a_proposed_name', {is: 'register_company_name', then: Yup.string().required('Required')}),
        company_name_reservation_number: Yup.string().when('have_you_previously_reserved_this_company_name', {is: 'yes', then: Yup.string().required('Required')}),
        who_lodged_the_company_name_reservation_form: Yup.string().when('have_you_previously_reserved_this_company_name', {is: 'yes', then: Yup.string().required('Required')}),
          individual_given_names: Yup.string().when('who_lodged_the_company_name_reservation_form', {is: 'individual', then: Yup.string().required('Required')}),
          individual_family_name: Yup.string().when('who_lodged_the_company_name_reservation_form', {is: 'individual', then: Yup.string().required('Required')}),
          organisation_or_company_name: Yup.string().when('who_lodged_the_company_name_reservation_form', {is: 'organisation', then: Yup.string().required('Required')}),
      company_name: Yup.string().when('do_you_have_a_proposed_name', {is: 'register_company_name', then: Yup.string().required('Required')}),
    what_type_of_company_do_you_wish_to_register: Yup.string().required('Required'),
    in_which_state: Yup.string().required('Required'),
      is_company_name_same_as_an_already_registered_business: Yup.string().when('do_you_have_a_proposed_name', {is: 'register_company_name', then: Yup.string().required('Required')}),
        confirm_owners_are_officeholders_or_shareholders: Yup.boolean().when('is_company_name_same_as_an_already_registered_business', {is: 'yes', then: Yup.boolean().required('Required').oneOf([true], 'You must tick the checkbox')}),
        does_the_registered_business_name_have_an_abn: Yup.string().when('is_company_name_same_as_an_already_registered_business', {is: 'yes', then: Yup.string().required('Required')}),
          business_abn: Yup.string().when('does_the_registered_business_name_have_an_abn', {is: 'yes', then: Yup.string().required('Required')}),
    different_share_prices: Yup.string().required('Required'),
      share_price: Yup.string().when('different_share_prices', {is: 'no', then: Yup.string().required('Required')}),
    only_for_super: Yup.string().required('Required'),
      agree_only_for_super: Yup.boolean().when('only_for_super', {is: 'yes', then: Yup.boolean().required('Required').oneOf([true])}),
  }),
  handleSubmit(
    { handler, ...values },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const second_screen = {
      do_you_have_a_proposed_name: values.do_you_have_a_proposed_name,
      what_type_of_company_do_you_wish_to_register: values.what_type_of_company_do_you_wish_to_register,
      in_which_state: values.in_which_state,
      different_share_prices: values.different_share_prices,
      only_for_super: values.only_for_super,
      rbn_in_act: values.rbn_in_act,
      rbn_in_nsw: values.rbn_in_nsw,
      rbn_in_nt: values.rbn_in_nt,
      rbn_in_qld: values.rbn_in_qld,
      rbn_in_sa: values.rbn_in_sa,
      rbn_in_tas: values.rbn_in_tas,
      rbn_in_vic: values.rbn_in_vic,
      rbn_in_wa: values.rbn_in_wa
    }
    const do_you_have_a_proposed_name_conditional = values.do_you_have_a_proposed_name == 'register_company_name' ? {
      have_you_previously_reserved_this_company_name: values.have_you_previously_reserved_this_company_name,
      company_name: values.company_name,
      is_company_name_same_as_an_already_registered_business: values.is_company_name_same_as_an_already_registered_business
    } : {}
    const have_you_previously_reserved_this_company_name_conditional = values.have_you_previously_reserved_this_company_name == 'yes' ? {
      company_name_reservation_number: values.company_name_reservation_number,
      who_lodged_the_company_name_reservation_form: values.who_lodged_the_company_name_reservation_form
    } : {}
    const who_lodged_the_company_name_reservation_form_conditional = values.who_lodged_the_company_name_reservation_form == 'individual' ? {
      individual_given_names: values.individual_given_names,
      individual_family_name: values.individual_family_name,
    } : {
      organisation_or_company_name: values.organisation_or_company_name
    }
    const is_company_name_same_as_an_already_registered_business_conditional = values.is_company_name_same_as_an_already_registered_business == 'yes' ? {
      confirm_owners_are_officeholders_or_shareholders: values.confirm_owners_are_officeholders_or_shareholders,
      does_the_registered_business_name_have_an_abn: values.does_the_registered_business_name_have_an_abn
    } : {}
    const does_the_registered_business_name_have_an_abn_conditional = values.does_the_registered_business_name_have_an_abn == 'yes' ? {
      business_abn: values.business_abn
    } : {}
    const different_share_prices_conditional = values.different_share_prices == 'no' ? {
      share_price: values.share_price
    } : {}
    const only_for_super_conditional = values.only_for_super == 'yes' ? {
      agree_only_for_super: values.agree_only_for_super
    } : {}
    handler({ 
      ...second_screen, 
      ...do_you_have_a_proposed_name_conditional, 
      ...have_you_previously_reserved_this_company_name_conditional,
      ...who_lodged_the_company_name_reservation_form_conditional,
      ...is_company_name_same_as_an_already_registered_business_conditional,
      ...does_the_registered_business_name_have_an_abn_conditional,
      ...different_share_prices_conditional,
      ...only_for_super_conditional
    })
  }
})(App)


export default CompanyDetails
