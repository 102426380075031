import { Grid, Card, Box, Typography, CardActions, Button, makeStyles, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core'
import React from 'react'
import PageTitle from '../Shared/PageTitle'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

const useStyles = makeStyles(theme => ({
  included: {
    color: theme.palette.success.dark,
    fontWeight: 'bold'
  },
  notIncluded: {
    color: theme.palette.grey[400],
    fontWeight: 'bold'
  },
  success: { 
    backgroundColor: theme.status.success[400], 
    margin: "auto 0",
    '&:hover': { backgroundColor: theme.status.success[700] } 
  }
}))

const Included = ({ children }) => {
  const classes = useStyles()
  return(
    <TableCell className={classes.included}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">{ children }</Typography>
      </Box>
    </TableCell>
  )
}

const NotIncluded = ({ children }) => {
  const classes = useStyles()
  return(
    <TableCell className={classes.notIncluded}>
      <Box display="flex" alignItems="center">
        <Typography variant="h6">{ children }</Typography>
      </Box>
    </TableCell>
  )
}

const Pricing = () => {
  const classes = useStyles()
  return (
    <Box maxWidth="650px" margin="0 auto">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle>Brandhub pricing and plans</PageTitle>
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={12}>
          <Card elevation={1}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Features</TableCell>
                  <TableCell>Free plan</TableCell>
                  <TableCell>Premium plan</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <Included>Available</Included>
                  <Included>Available</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <Included>3 per month</Included>
                  <Included>100 per month</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <Included>First one</Included>
                  <Included>Unlocked</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <Included>$20 each</Included>
                  <Included>Included</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <NotIncluded>Limited</NotIncluded>
                  <Included>24/7</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <NotIncluded>Unavailable</NotIncluded>
                  <Included>Available</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <NotIncluded>Unavailable</NotIncluded>
                  <Included>Available</Included>
                </TableRow>
                <TableRow>
                  <TableCell>Feature Name</TableCell>
                  <NotIncluded>Unavailable</NotIncluded>
                  <Included>Available</Included>
                </TableRow>
                <TableRow>
                  <TableCell><strong>Price</strong></TableCell>
                  <TableCell><Typography variant="h4">Free</Typography></TableCell>
                  <TableCell><Typography variant="h4">AU$35 / month</Typography></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Button fullWidth variant="contained" className={classes.success}>Subscribe to Brandhub Premium</Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Pricing
