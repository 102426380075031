import React from 'react'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../Shared/Form'
import { makeStyles } from '@material-ui/core/styles';
import { requestRead } from '../../../api/documentsApi';
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, Typography } from '@material-ui/core'
import CardTitle from '../../../Theme/customComponents/CardTitle';
import Container from '../../Shared/Container';
import ResultCard from '../../Shared/ResultCard';
import LoadingPage from '../../Shared/LoadingPage';

const useStyles = makeStyles((theme) => ({
  input: { display: 'none' },
  label: { width: '100%', display: 'flex', marginTop: theme.spacing(2) },
  image: { width: 'auto', height: 'auto', margin: '0 auto',  maxHeight: '500px', maxWidth: '500px' },
  success: { backgroundColor: theme.status.success[400], '&:hover': { backgroundColor: theme.status.success[700] } },
}));

const App = ({
  scrollFunction,
  values,
}) => {

  const [logo, setLogo] = React.useState(null)

  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
    values.logo && getLogo(values.logo)
    console.log('effect')
  }, [])

  // React.useEffect(() => {
    
  // }, [values.logo])

  const getLogo = filename => {
    console.log(filename, '<req>uesting this</req>')
    requestRead(filename)
      .then(res => {
        console.log(res, 'res from read')
        setLogo(res.data.documentUrl)
      })
      .catch(err => {
        console.dir(err)
        setLogo(null)
      })
  }

  const classes = useStyles()
  return (
    <Form>
      <Box width="100%" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Grid container spacing={2}>
          <Grid item sm={12}>
            <Typography variant="body1">Before applying for a Trademark we recommend running all available checks, this is an effective way to ensure the brand you are registering is much more resistant to scrutiny.</Typography>
          </Grid>
          <Grid item sm={6}>
            <Container>
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">Business Name</Typography> 
                <Divider />
                <CardContent>
                  <Typography variant="h3">{values.business.Properties.business_name}</Typography>
                </CardContent>
              </Box>
            </Container>
            <Container>
              <Box display="flex" flexDirection="column">
                <Typography variant="h4">Logo</Typography> 
                <Divider />
                <CardContent>
                  {logo && <img className={classes.image} src={logo} />}
                </CardContent>
              </Box>
            </Container>
          </Grid>
          <Grid item sm={6}>
            <Container>
              <Typography variant="h3">
                Recommended actions
              </Typography>
              <Divider />
              <Box display="flex" flexDirection="column" height="fit-content">
                {
                  values.business.Properties.checklist ?
                    <ResultCard dense results={values.business.Properties.checklist.filter(c => c.name == 'name_check')} business={values.business}/> :
                    <LoadingPage />
                }
              </Box>
            </Container>
          </Grid>
          {/* <Grid item sm={5}>
            <Box display="flex" flexDirection="column">
              <Typography variant="overline">You have requested</Typography> 
              <Typography variant="h5">Brand Copyright</Typography><br/>
              <Typography variant="overline">Average quote price</Typography> 
              <Typography variant="h5">Starting from $200</Typography><br/>
            </Box>
            <Box display="flex" flexDirection="column">
              <Typography variant="overline">Average <strong>3 days</strong> turnaround</Typography> 
              <Typography variant="overline">Responds in <strong>2 hours</strong> within business hours</Typography> 
              <Typography variant="overline"><strong>230</strong> finished jobs</Typography>
            </Box>
          </Grid> */}
          <Grid item sm={12}>
            <Box display="flex" justifyContent="center" marginTop={2}>
              <Button fullWidth size="large" variant="contained" color="primary" onClick={values.handler}> Request a quote</Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Form>
  )
}

const Quote = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName,
    business,
    logo,
    back,
    skip
  }) => ({
      updateBankDetails,
      refreshUserData,
      business: business, 
      business_name: businessName,
      result: null,
      logo,
      handler,
      back,
      skip
    }),
  validationSchema: Yup.object().shape({}),
  handleSubmit(
    { logo, handler },
    { setSubmitting, setFieldValue }
  ) {
    setSubmitting(true)
    handler()
      .then(res => {
        setFieldValue('result', res)
        setSubmitting(false)
      })
  }
})(App)

export default Quote
