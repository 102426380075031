import { fetchMenuItemsApi } from '../api/layoutApi'
import { fetchBusinesses } from '../actions/businessesActions'

export const FETCHING = '@@redux-layout/FETCHING'
export const FETCH_MENU_ITEMS = '@@redux-layout/FETCH_MENU_ITEMS'

// const emulateFetchingBusinessesName = new Promise((resolve, reject) => {
//   setTimeout(() => {
//     resolve({businesses: [
//       { name: 'LensBender'},
//       { name: 'Candy Co.' },
//       { name: 'Leisure Sweet Studios' },
//       { name: 'The Anchor BMX' },
//     ]})
//   }, 2000)
// })

// export const fetchInitialData = () => {
//   return dispatch => {
//     dispatch(fetching())
//     setTimeout(() => {
//       // dispatch(fetchBusinesses())
//       dispatch(fetching(false))
//     }, 2000)
//   }
// }

export const fetching = (val = true) => ({
  type: FETCHING,
  payload: val
})

export const updateMenuItems = payload => ({
  type: FETCH_MENU_ITEMS,
  payload: payload
})
