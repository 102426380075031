import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

import React from 'react'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'row !important',
  },
  selectAdornment: {
    margin: theme.spacing(1),
    width: '100px',
  },
  expandIcon: {
    transform: 'none !important'
  },
  noBorder: {
    border: 'none',
    marginTop: '0.5rem'
  },
  expansionPanel: {
    width: '100%',
    background: 'transparent',
    '&:before': {
      display: 'none'
    }
  },
  noPaddingLeft: {
    paddingLeft: 0
  }
}));

export const ExpandableHeading = ({errorFormula = null, heading, text, CustomIcon = null, titleSize = null, noPaddingLeft = false, rotateIcon = false, expanded = false, fixed = false}) => {
  const classes = useStyles()
  const propsForPanel = expanded ? { expanded: true } : {}
  return (
    <ExpansionPanel classes={{root: classes.expansionPanel}} className={classes.noBorder} elevation="0" {...propsForPanel}>
      <ExpansionPanelSummary
        // classes={!rotateIcon && {
        //   expandIcon: classes.expandIcon
        // }}
        classes={{
          expandIcon: !rotateIcon && classes.expandIcon,
          root: classes.noPaddingLeft
        }}
        expandIcon={!fixed ? (errorFormula ? <ErrorOutlineIcon /> : CustomIcon ? <CustomIcon /> : <HelpOutlineIcon />) : null}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <Typography variant="h6">{ heading }</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Typography color="textSecondary" variant="subtitle2">
          {text}
        </Typography>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  )
}
