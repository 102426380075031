import React from 'react'
import { Typography, Box, Button, makeStyles, Divider, Tooltip } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom'
import { useStatusClasses } from '../../Theme/statusClasses';
import clsx from 'clsx'
import BusinessPreview from './BusinessPreview'

const useStylesTooltip = makeStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.primary.light
  }
}))

const useStyles = makeStyles(theme => ({
  business:{
    background: '#fff',
    borderRadius: '10px',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    cursor: 'pointer',
    transition: 'box-shadow .1s linear',
    '&:hover': {
      boxShadow: theme.shadows[2]
    }
  },
  data: {
    flex: 1,
    paddingRight: theme.spacing(5)
  },
  action: {
    border: '2px solid',
    borderColor: theme.palette.warning.main,
    cursor: 'default',
    '&:hover': {
      boxShadow: 'none'
    }
  },
  divider: {
    backgroundColor: theme.palette.warning.main
  },
}))

const BusinessesComponent = ({ businesses }) => {
  const location = useLocation()
  const history = useHistory()
  const path = location.pathname.split('/').reverse()[0]
  const statusClasses = useStatusClasses()
  const classes = useStyles()
  const tooltipClasses = useStylesTooltip()
  const [dialog, setDialog] = React.useState({ open: false, content: null })

  const handleClose = () => setDialog(prev => ({ ...prev, open: false}))

  return (
    <Box width="100%">
      {/* <Box className={clsx(classes.business, classes.action)}> */}
      <Box paddingLeft="0.5em">
        <Tooltip classes={tooltipClasses} title="Add a new business now to begin the business creation process, from there you can do things like register your brand, trademark a logo and protect your brand" placement="right">
          <Button color="primary" variant="contained" onClick={() => history.push(`/add_business`)}> Add a new Business </Button>
        </Tooltip>
      </Box>
        {/* <Box className={classes.data}>
          <Typography variant="h3" color="primary"> Add new Business </Typography>
          <Divider className={classes.divider} />
          <Box display="flex" justifyContent="space-between">
            <Typography variant="body1">Add a new business now to begin the business creation process, from there you can do things like register your brand, trademark a logo and protect your brand</Typography>
            <Button color="primary" variant="contained" onClick={() => history.push(`/add_business`)}> Start </Button>
          </Box>
        </Box> */}
      {/* </Box> */}
      {
        businesses?.map(el => <BusinessPreview clickable business={el} click={() => history.push(`/businesses/${el.BusinessId}`)} />)
      }
    </Box>
  )
}

export default BusinessesComponent