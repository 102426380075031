import React from 'react';
import clsx from 'clsx';
import Toolbar from '@material-ui/core/Toolbar';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    height: 60, 
    background: '#f5f5f5', 
    boxShadow: theme.shadows[0]
  }
}));

function PaperTitle(props) {
  const classes = useStyles()
  const { children, className, ...other } = props;

  return (
    <Toolbar className={clsx(classes.root, className)} {...other}>
      {children}
    </Toolbar>
  );
}

export default PaperTitle