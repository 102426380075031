import React from 'react'
import { Link } from 'react-router-dom'
import { default as MUIButton } from '@material-ui/core/Button'
import { withStyles, ThemeProvider } from "@material-ui/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Box, CircularProgress } from '@material-ui/core'
import { useTheme } from '@material-ui/core/styles';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles(theme => ({
  clickable: {
    textTransform: 'none',
    justifyContent: 'flex-start',
    margin: 0,
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5),
    borderRadius: '5px',
    borderColor: theme.palette.warning.main,
    textAlign: 'left'
  }
}))

const Clickable = ({ children, color, leftIcon = null, rightIcon = null, onClick, loading, rotation = null, size, direction, ...rest }) => {
  const clickHandler = () => (
    onClick && 
    setTimeout(() => {
      onClick()
    }, 100)
  )
  const theme = useTheme()
  const styles = useStyles({ size, direction })

  return(
    <MUIButton
      color={color || "warning"}
      variant="outlined"
      onClick={clickHandler}
      disabled={loading || rest.disabled}
      disableElevation
      {...rest}
      className={styles.clickable}
    >
      <Box pr={1} alignItems="center" justifyContent="center" display="flex">
        {leftIcon && loading ? <CircularProgress size={theme.typography.button.fontSize} /> : leftIcon}
      </Box>
      {children}
      <Box ml={'auto'} alignItems="center" justifyContent="center" display="flex">
        {rightIcon && loading ? <CircularProgress size={theme.typography.button.fontSize} /> : rightIcon}
      </Box>
    </MUIButton>
)}

export const ClickableSkeleton = ({ amount = 1 }) => {
  const skeletons = []
  for( let i = 0; i < amount; i++) {
    skeletons.push(i)
  }
  return skeletons.map((s, i) => (
    <Box marginY={.5} key={i}>
      <Skeleton variant="rect" width="100%" height={36} animation="wave"/>
    </Box>
  ))
}

export default Clickable