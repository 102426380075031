import React from 'react'
import { makeStyles } from '@material-ui/core/styles';

import { Box, Typography } from '@material-ui/core'
import QuickActionsTextSnippet from '../QuickActions/QuickActionsTextSnippet';

const useStyles = makeStyles((theme) => ({
  input: { display: 'none' },
  label: { width: '100%', display: 'flex', marginTop: theme.spacing(2) },
  image: { width: 350, height: 'auto', margin: '0 auto', marginTop: theme.spacing(2) },
  success: { backgroundColor: theme.status.success[400], '&:hover': { backgroundColor: theme.status.success[700] } }
}));

const EmailSent = ({
  scrollFunction,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  title
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
    console.log('effect')
  }, [])

  const classes = useStyles()
  const hangleImageUpload = e => {
    console.log(e.target.files)  
    setFieldValue('logo', URL.createObjectURL(e.target.files[0]))
  }
  return (
    <Box width="100%" height="100%" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant="h5">Email sent</Typography><br/>
      <QuickActionsTextSnippet />
    </Box>
  )
}

export default EmailSent
