import React from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import PaperTitle from '../../Theme/customComponents/PaperTitle';
import { Toolbar, Typography, Box, Divider, DialogTitle, DialogContent, DialogActions, Button } from '@material-ui/core';
import QuickAction from '../Shared/QuickAction';
// import DescriptionIcon from '@material-ui/icons/Description';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import SearchBar from '../Shared/SearchBar';
import Clickable, { ClickableSkeleton } from '../Shared/Clickable';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import QuickActionsSnippet from '../QuickActions/QuickActionsSnippet';
import Container from '../Shared/Container';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Businesses from '../Businesses/Businesses';

const DashboardComponent = () => {
  const businesses = useSelector(state => state.businesses?.data?.items)
  const [selected, setSelected] = React.useState(businesses)
  const history = useHistory()

  const [dialog, setDialog] = React.useState({
    open: false,
    title: '',
    content: ''
  })

  const dialogContent = {
    welcome: {
      title: 'Welcome to brandhub',
      content: <Typography variant="body1">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore obcaecati, voluptate eos ducimus perferendis illo rem atque unde molestias iure veniam animi, quis recusandae tempore voluptatum, aperiam quo architecto? Voluptatibus!
        <br/>
        <br/>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad quaerat in ipsa? Perspiciatis nihil alias error voluptatibus fugiat, optio delectus libero odio, enim labore, maxime animi dolorum cumque nesciunt. Accusantium.
      </Typography>
    },
    other: {
      title: 'Another one',
      content: <Typography variant="body1">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Inventore obcaecati, voluptate eos ducimus perferendis illo rem atque unde molestias iure veniam animi, quis recusandae tempore voluptatum, aperiam quo architecto? Voluptatibus!
        <br/>
        <br/>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad quaerat in ipsa? Perspiciatis nihil alias error voluptatibus fugiat, optio delectus libero odio, enim labore, maxime animi dolorum cumque nesciunt. Accusantium.
        <br/>
        <br/>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad quaerat in ipsa? Perspiciatis nihil alias error voluptatibus fugiat, optio delectus libero odio, enim labore, maxime animi dolorum cumque nesciunt. Accusantium.
        <br/>
        <br/>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad quaerat in ipsa? Perspiciatis nihil alias error voluptatibus fugiat, optio delectus libero odio, enim labore, maxime animi dolorum cumque nesciunt. Accusantium.
      </Typography>
    }
  }

  const handleDialog = name => {
    setDialog({
      open: true,
      title: dialogContent[name].title,
      content: dialogContent[name].content
    })
  }

  const handleClose = () => {
    setDialog({
      ...dialog,
      open: false
    })
  }

  React.useEffect(() => {
    setSelected(businesses)
  }, [businesses])

  return (
    <div>
      <Grid container spacing={4}>
        <Grid item sm={12}>
          <Container fill>
            <Typography variant="h3">
              Getting Started
            </Typography>
            <Divider />
            <Box display="flex" flexDirection="column">
              <Clickable key={1} leftIcon={<MailOutlineIcon/>} onClick={() => handleDialog('welcome')}> Welcome to BrandHub </Clickable>
              <Clickable key={2} leftIcon={<MailOutlineIcon/>} onClick={() => handleDialog('other')}> Register your first business </Clickable>
            </Box>
          </Container>
        </Grid>
        {
          businesses ? 
            <Grid item sm={12}>
              <Businesses />
            </Grid> : null
        }
        <Grid item sm={12}>
          <QuickActionsSnippet />
        </Grid>
      </Grid>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={dialog.open}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
          {dialog.title}
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom>
            {dialog.content}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DashboardComponent
