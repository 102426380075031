import React from 'react'
import { Form as FormikForm } from 'formik'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  form: {
    borderRadius: '10px',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.light,
    position: 'relative'
  }
}))

const Form = props => {
  const classes = useStyles()
  return <FormikForm className={classes.form} {...props} />
}

export default Form
