import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import TextField from '@material-ui/core/TextField'
import { Box, Button, Typography, CircularProgress } from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';

const App = ({
  businesses,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting,
  submitCount
}) => {
  const filter = createFilterOptions();
  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography>{values.title}</Typography>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.business_name && errors.business_name}>
          <FormLabel component="legend">{values.label}</FormLabel>
          <Select
            id="business"
            name="business"
            value={values.business}
            onChange={handleChange}
          >
            {
              businesses.map(business => <MenuItem value={business.Properties.business_name}>{business.Properties.business_name}</MenuItem>)
            }
          </Select>
          { (submitCount > 0 || touched.business) && errors.business && <FormHelperText>{errors.business}</FormHelperText> }
        </FormControl>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitting}
          endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
        >
          {isSubmitting ? 'Checking availability...' : values.buttonText}
        </Button>
      </Box>
    </Form>
  )
}

const AddNameForm = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    preSelected,
    title,
    label,
    buttonText,
    businesses
  }) => ({
      updateBankDetails,
      refreshUserData,
      business: preSelected,
      title,
      label,
      buttonText,
      handler
    }),
  validationSchema: Yup.object().shape({
    business: Yup.string()
      .nullable()
      .required('Required')
  }),
  handleSubmit(
    { business, handler },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    console.log('submitted', business)
    handler({ business_name: business })

  }
})(App)

export default AddNameForm
