import {
  LOGIN,
  LOGOUT,
  FETCHING,
  FETCH_DOCUMENTS
} from '../actions/userActions'

const initialStore = {
  isFetching: false,
  error: null,
  data: null,
  jwt: null
}

export default (state = initialStore, action) => {
  switch (action.type) {
    case LOGIN: {
      return {
        data: {
          ...state.data,
          ...action.payload.data
        },
        jwt: action.payload.jwt,
        isFetching: false
      }
    }

    case LOGOUT: {
      return initialStore
    }

    case FETCHING: {
      return {
        ...state,
        isFetching: action.payload
      }
    }

    default: {
      return state
    }
  }
}
