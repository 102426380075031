import React from 'react'
import _ from 'lodash'
import { Toolbar, Typography, Box, Button, CardActions, CardContent, makeStyles, ListItem, ListItemText, Table, TableBody, TableRow, TableCell, TableContainer, Paper } from '@material-ui/core';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import { useHistory } from 'react-router-dom'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import BlockIcon from '@material-ui/icons/Block';
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd';

import { capitalizeString, resolveActionName } from '../../helpers/helpers';
import { useStatusClasses } from '../../Theme/statusClasses'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx'

import SpellcheckIcon from '@material-ui/icons/Spellcheck';
import CreateIcon from '@material-ui/icons/Create';
import CopyrightIcon from '@material-ui/icons/Copyright';

const useStyles = makeStyles(theme => ({
  card: {
    border: 'none'
  },
  root: {
    padding: props => props.dense && '8px'
  },
  avatar: {
    width: theme.spacing(3.5),
    height: theme.spacing(3.5)
  },
  title: {
    color: theme.palette.primary.main
  },
  nonClickableButton: props => {

    return({
      color: `${theme.palette.primary.main} !important`,
      borderColor: `${theme.palette.primary.main} !important`
    })
  }
}))

const resolveResult = (business, result) => {
  switch(result.name) {
    case 'name_check':
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Start'},
          not_checked: {message: 'Start'},
          not_completed: {message: 'Start'}
        },
        action: {
          pathname: '/check_availability',
          state: { name: business.Properties.business_name }
        },
        icon: SpellcheckIcon
      }

    case 'name_register':
      // return 'Name registration'
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Start'},
          not_checked: {message: 'Start'},
          not_completed: {message: 'Start'},
          pending: {message: 'Pending'}
        },
        action: {
          pathname: '/register_business',
          state: { name: business.Properties.business_name }
        },
        icon: CreateIcon
      }
    case 'name_copyright':
      // return 'Name copyright'
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Start'},
          not_checked: {message: 'Start'},
          not_completed: {message: 'Start'}
        },
        action: {
          pathname: '/check_availability',
          state: { name: business.Properties.business_name }
        },
        icon: CopyrightIcon
      }
    case 'brand_copyright':
      // return 'Brand Copyright'
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Start'},
          not_checked: {message: 'Start'},
          not_completed: {message: 'Start'}
        },
        action: {
          pathname: `/businesses/copyright/${business.BusinessId}`
        },
        icon: CopyrightIcon
      }
    case 'logo_check':
      // return 'Logo availability'
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Not checked', disabled: true},
          not_checked: {message: 'Not checked', disabled: true},
          not_completed: {message: 'Not completed', disabled: true}
        },
        action: '/check_name'
      }
    case 'logo_copyright':
      // return 'Logo copyright'
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Not checked', disabled: true},
          not_checked: {message: 'Not checked', disabled: true},
          not_completed: {message: 'Not completed', disabled: true}
        },
        action: '/check_name'
      }
    case 'brand_protection':
      // return 'Brand protection'
      return {
        name: 'Name availability', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Not checked', disabled: true},
          not_checked: {message: 'Not checked', disabled: true},
          not_completed: {message: 'Not completed', disabled: true}
        },
        action: '/check_name'
      }
    case 'asic_name':
      return {
        name: 'Business name', 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again'},
          error: {message: 'Check again'},
          default: {message:'Not checked', disabled: true},
          not_checked: {message: 'Not checked', disabled: true},
          not_completed: {message: 'Not completed', disabled: true}
        },
        action: {
          pathname: '/check_availability'
        }
      }
    default:
      return {
        name: result.name, 
        status: {
          completed: {message: 'Passed', disabled: true},
          warning: {message: 'Check again', disabled: true},
          error: {message: 'Check again', disabled: true},
          default: {message:'Not checked', disabled: true},
          not_checked: {message: 'Not checked', disabled: true},
          not_completed: {message: 'Not completed', disabled: true}
        },
        action: null
      }
  }
}

const ResultCard = ({ dense, results, business}) => {
  const classes = useStyles({dense})
  const statusClasses = useStatusClasses()
  const [dialog, setDialog] = React.useState({ open: false, content: null })

  const handleClose = () => setDialog(prev => ({ ...prev, open: false}))

  const history = useHistory()

  const SuccessAvatar = () => (
    <Avatar className={clsx(statusClasses.success, classes.avatar)}>
      <CheckIcon style={{fontSize: 20}} />
    </Avatar>
  )
  const WarningAvatar = () => (
    <Avatar className={clsx(statusClasses.warning, classes.avatar)}>
      <PriorityHighIcon style={{fontSize: 20}} />
    </Avatar>
  )
  const PendingAvatar = () => (
    <Avatar className={clsx(statusClasses.inProgress, classes.avatar)}>
      <HourglassEmptyIcon style={{fontSize: 20}} />
    </Avatar>
  )
  const ErrorAvatar = () => (
    <Avatar className={clsx(statusClasses.error, classes.avatar)}>
      <BlockIcon style={{fontSize: 20}} />
    </Avatar>
  )
  const IncompleteAvatar = ({ component: Component }) => {
    return(
      <Avatar className={classes.avatar}>
        <Component style={{fontSize: 20}} />
      </Avatar>
  )}
  return (
    results ?
      _.sortBy(results, ['id']).map(result => {
        const resolved = resolveResult(business, result)
        return (
          <Card className={classes.card} elevation={0}>
            <Dialog minWidth="md" onClose={handleClose} aria-labelledby="simple-dialog-title" open={dialog.open}>
              <DialogTitle id="simple-dialog-title">Similarities found</DialogTitle>
              {
                dialog.content?.map(item => {
                  const keys = Object.keys(item)
                  return (
                    <Box margin="1em">
                      <Paper elevation={5}>
                        <Table size="small">
                          <TableBody>
                            {
                              keys?.map(row => (
                                <TableRow>
                                  <TableCell>{row}</TableCell>
                                  <TableCell>{JSON.stringify(item[row], null, 2)}</TableCell>
                                </TableRow>
                              ))
                            }
                          </TableBody>
                        </Table>
                      </Paper>
                    </Box>
                  )
                })
              }
            </Dialog>
            <CardHeader classes={{root: classes.root, avatar: classes.avatar, title: classes.title}}
              avatar={
                result.status == 'error' ? <ErrorAvatar /> : 
                result.status == 'warning' ? <WarningAvatar /> : 
                result.status == 'pending' ? <PendingAvatar /> : 
                result.status == 'completed' ? <SuccessAvatar /> :
                <IncompleteAvatar component={resolved.icon || <> </>} />
              }
              action={ 
                <>
                  <Button 
                    variant="outlined" 
                    size="small" 
                    classes={{ disabled: classes.nonClickableButton }} 
                    disabled={resolved.status[result.status]?.disabled}
                    onClick={
                      resolved.status[result.status]?.disabled ? 
                        null:
                        () => history.push(resolved.action)
                    }
                  >
                    { resolved.status[result.status].message }
                  </Button>
                  {
                    result.extra ? 
                      result.extra.length ? 
                        <Button variant="outlined" size="small" onClick={() => setDialog({open: true, content: result.extra})}>
                          {`See ${result.extra?.length} similarities`}
                        </Button> : 
                        <Button variant="outlined" size="small" disabled>
                        {`${result.extra?.length} similarities`}
                      </Button> : 
                    null
                  }
                </>
              }
              title={
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="body1">{capitalizeString(resolveActionName(result.name))}</Typography>
                </Box>
              }
            />
          </Card>
        )
    }) : <p>Loading</p>
  )
}

export default ResultCard
