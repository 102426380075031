import React from 'react';
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AddNameForm from './Forms/AddNameForm';
import ResultsPage from './ResultsPage';
import { actionCheckLogo, actionCheckName, fetchBusinesses } from '../../../actions/businessesActions';
import PageTitle from '../../Shared/PageTitle';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContent: {
    alignItems: 'center'
  }
}));


const CheckNameAvailability = () => {
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch()
  const businesses = useSelector(state => state.businesses?.data?.items)
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([
    {label: 'Choose a name'}, 
    {label: 'Check the availability', ref: React.useRef()}
  ])
  const [formState, setFormState] = React.useState({
    business_name: location.state?.name,
    results: null
  })

  React.useEffect(() => {
    console.log(location.state)
    location.state?.name && setFormState({ ...formState, business_name: location.state.name })
  }, [location.state])

  React.useEffect(() => {
    console.log(formState)
  }, [formState])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSkip = stepIndex => {
    setSteps(prev => prev.map((step, i) => ( i == stepIndex ? {...step, skipped: true} : step )))
    handleNext()
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const checkNameHandler = values => {
    const business = businesses?.filter(b => b.Properties.business_name == location.state?.name)[0]
    dispatch(actionCheckName(business, values.business_name, (res, domains) => {
      setFormState(prev => ({ 
        ...prev, 
        ...values,
        results: res,
        domains: domains,
        businessId: business?.BusinessId
      }))
      handleNext()
    }))
  }

  function getStepContent(step) {
    let scrollFunction = () => {
      let y = steps[step].ref.current.getBoundingClientRect().top + window.pageYOffset - 80 - 16
      window.scrollTo({top: y, behavior: 'smooth'})
    } 
    switch (step) {
      case 0:
        return <AddNameForm businessName={formState.business_name} handler={checkNameHandler}/>;
      case 1: 
        return <ResultsPage 
                  name={formState.business_name} 
                  id={formState.businessId}
                  results={formState.results}
                  domains={formState.domains}
                  back={handleBack}
                  scrollFunction={scrollFunction}
                />
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <PageTitle>Check business name availability</PageTitle>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({label, optional, skipped, ref}, index) => {
          let labelProps = {}
          if(optional){
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }
          let stepProps = {}
          if(skipped){
            stepProps.completed = false
          }
          return(
          <Step {...stepProps} key={label} ref={ref}>
            <StepLabel {...labelProps}>{label}</StepLabel>
            <StepContent className={classes.stepContent}>
              {getStepContent(index)}
            </StepContent>
          </Step>
        )})}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}

export default CheckNameAvailability