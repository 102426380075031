import { makeStyles } from '@material-ui/core';

export const useStatusClasses = makeStyles((theme) => ({
  warning: {
    color: `${theme.palette.getContrastText(theme.status.warning[500])} !important`,
    backgroundColor: `${theme.status.warning[500]} !important`,
  },
  inProgress: {
    color: `${theme.palette.getContrastText(theme.status.inProgress[500])} !important`,
    backgroundColor:`${ theme.status.inProgress[500]} !important`,
  },
  success: {
    color: `${theme.palette.getContrastText(theme.status.success[500])} !important`,
    backgroundColor: `${theme.status.success[500]} !important`,
  },
  error: {
    color: `${theme.palette.getContrastText(theme.status.error[500])} !important`,
    backgroundColor: `${theme.status.error[500]} !important`,
  },
}));
