import React from 'react'
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { Toolbar, Typography, Box, Button, makeStyles, CardActions, CardContent } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import AddIcon from '@material-ui/icons/Add';
import CheckIcon from '@material-ui/icons/Check';
import Avatar from '@material-ui/core/Avatar';
import Skeleton from '@material-ui/lab/Skeleton';
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import PaperTitle from '../../../Theme/customComponents/PaperTitle';
import BlockIcon from '@material-ui/icons/Block';
import CardTitle from '../../../Theme/customComponents/CardTitle';
import QuickAction from '../../Shared/QuickAction';
import { capitalizeString } from '../../../helpers/helpers';
import QuickActionsSnippet from '../QuickActionsSnippet';

const useStyles = makeStyles((theme) => ({
  warning: {
    color: theme.palette.getContrastText(theme.status.warning[500]),
    backgroundColor: theme.status.warning[500],
  },
  success: {
    color: theme.palette.getContrastText(theme.status.success[500]),
    backgroundColor: theme.status.success[500],
  },
  error: {
    color: theme.palette.getContrastText(theme.status.error[500]),
    backgroundColor: theme.status.error[500],
  },
}));

const AsicResultPage = ({ name, back, results, scrollFunction }) => {
  const classes = useStyles()
  // const checkedPlatforms = Object.keys(results)
  React.useEffect(() => {
    // setTimeout(() => {
    //   scrollFunction()
    // }, 500)
  }, [])

  const SuccessAvatar = () => (
    <Avatar className={classes.success}>
      <CheckIcon />
    </Avatar>
  )
  const WarningAvatar = () => (
    <Avatar className={classes.warning}>
      <PriorityHighIcon />
    </Avatar>
  )
  const ErrorAvatar = () => (
    <Avatar className={classes.error}>
      <BlockIcon />
    </Avatar>
  )
  return (
    <>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Card>
          <CardTitle title={`ASIC registration result`} />
          <CardContent>
            <Box display="flex" flexDirection="column" height="fit-content">
              <Typography>
                Your bussines has been registered with ASIC, we have sent any relevant documants to your email, you can also view your business and it’s progress from your dashboard
              </Typography>
              <Card>
                <CardHeader
                  avatar={
                    // !result.available ? <ErrorAvatar/> : 
                    // result.similarities ? <WarningAvatar /> : 
                    <SuccessAvatar />
                  }
                  action={ 
                    // result.similarities ? 
                    //   <Button variant="outlined" size="small">
                    //     {`${result.similarities?.length} similarities`}
                    //   </Button> : 
                    //   result.matches ? 
                    //   <Button variant="outlined" size="small">
                    //     {`View match`}
                    //   </Button> : null
                    null
                  }
                  title={"Business name"}
                  subheader={"Registered"}
                />
              </Card>
            </Box>
          </CardContent>
        </Card>
      </Box>
      <QuickActionsSnippet />
    </>
  )
}

export default AsicResultPage
