import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    // marginTop: 'calc(50% - 45px)'
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center'
  }
}))

// todo: Integrate this in the Layout page, trigger with context or redux
const LoadingPage = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/* <LinearProgress /> */}
      <CircularProgress />
    </div>
  )
}

export default LoadingPage
