import {
  ADD_ONE_BUSINESS,
  UPDATE_BUSINESS,
  UPDATE_BUSINESS_CHECKLIST,
  FETCH_BUSINESSES_BASIC,
  FETCH_BUSINES,
  FETCHING,
  LOGOUT
} from '../actions/businessesActions'

const initialStore = {
  isFetching: false,
  error: null,
  data: null
}

export default (state = initialStore, action) => {
  switch (action.type) {
    case FETCH_BUSINESSES_BASIC: {
      return {
        data: {
          ...state.data,
          items: action.payload
        },
        isFetching: false
      }
    }

    case FETCH_BUSINES: {
      const new_items = state.data ? 
        state.data?.items?.map(b => b.name == action.payload.name ? {...b, ...action.payload} : b)
        : [ action.payload ]
      return {
        data: {
          ...state.data,
          items: new_items
        }
      }
    }

    case UPDATE_BUSINESS: { // payload should be a full business structure. On api call we send relevant data, backend updates business, returns updated business, we update it here
      const new_items = state.data ? 
        state.data?.items?.map(b => 
          b.name == action.payload.name ? 
            {
              ...b, 
              ...action.payload
            } : 
            b
        ) : [ action.payload ] 
      return {
        data: {
          ...state.data,
          items: new_items
        }
      }
    }

    case UPDATE_BUSINESS_CHECKLIST: {
      const new_items = state.data ? 
        state.data?.items?.map(b => 
          b.id == action.payload.id ? 
            {
              ...b, 
              checklist: {
                ...b.checklist,
                ...action.payload.newChecklistItem
              }
            } : 
            b
        ) : [ action.payload ] //todo: careful with this?
      return {
        data: {
          ...state.data,
          items: new_items
        }
      }
    }

    case ADD_ONE_BUSINESS: {  
      return {
        data: {
          ...state.data,
          items: [...state.data.items, action.payload]
        }
      }
    }

    case FETCHING: {
      return {
        ...state,
        isFetching: true
      }
    }

    case LOGOUT: {
      return initialStore
    }

    default: {
      return state
    }
  }
}
