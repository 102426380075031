import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import { Box, Button, Typography, CircularProgress, FormHelperText, Grid, OutlinedInput } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'
import StartOrder from './StartOrder'
import CompanyDetails from './CompanyDetails'
import Officeholders from './Officeholders'
import Shareholders from './Shareholders'
import LodgementDetails from './LodgementDetails'
import CompanyAdresses from './ComapyAdresses'

const ReviewFormTemplate = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  submitCount,
  scrollFunction = () => {}
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  return (
    <>
      <StartOrder 
        data={values.formState.new_order_details} 
        scrollFunction={scrollFunction} 
        review
      />
      <CompanyDetails 
        data={values.formState.company_details}
        scrollFunction={scrollFunction} 
        review
      />
      <CompanyAdresses 
        data={values.formState.company_addresses}
        addresses={values.formState.addresses} //maybe?
        scrollFunction={scrollFunction} 
        review
      />
      <Officeholders 
        addresses={values.formState.addresses} 
        officeholders={values.formState.officeholders}
        scrollFunction={scrollFunction} 
        review
      />
      <Shareholders 
        addresses={values.formState.addresses} 
        directors={values.formState.officeholders.filter(o => o.is_director)}
        shareholders={values.formState.shareholders}
        scrollFunction={scrollFunction} 
        review
      />
      <LodgementDetails 
        data={values.formState.lodgementDetails}
        addresses={values.formState.addresses} 
        directors={values.formState.officeholders.filter(o => o.is_director)}
        shareholders={values.formState.shareholders}
        scrollFunction={scrollFunction} 
        review
      />
      <Divider />
      <Form>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              color="primary"
              onClick={status.back}
              fullWidth
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={9}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={isSubmitting}
              endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
              fullWidth
            >
              {isSubmitting ? 'Loading' : 'Next'}
            </Button>
          </Grid>
        </Grid>
      </Form>
    </>
  )
}

const Review = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    addresses,
    directors,
    shareholders,
    review,
    businessName,
    data
  }) => ({
      updateBankDetails,
      refreshUserData,
      addresses,
      directors,
      shareholders,
      review,
      formState: data,
      applicant_given_name: '',
      applicant_family_name: '',
      applicant_address: '',
      applicants_level: data.applicants_level || '',
      applicants_street_number_and_name: data.applicants_street_number_and_name || '',
      applicants_suburb: data.applicants_suburb || '',
      applicants_state: data.applicants_state || '',
      applicants_postcode: data.applicants_postcode || '',
      request_manual_review: data.request_manual_review || '',
      information_to_transmit_via_manual_review: data.information_to_transmit_via_manual_review || '',
      chairman_of_the_company: data.chairman_of_the_company || '',
      chairman_at_first_meeting: data.chairman_at_first_meeting || '',
      handler
    }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    
  }),
  handleSubmit(
    { 
      handler, 
      applicant_given_name,
      applicant_family_name,
      applicant_address,
      applicants_level,
      applicants_street_number_and_name,
      applicants_suburb,
      applicants_state,
      applicants_postcode,
      request_manual_review,
      information_to_transmit_via_manual_review,
      chairman_of_the_company,
      chairman_at_first_meeting
    },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const lodgementDetails = {
      applicant_given_name,
      applicant_family_name,
      applicant_address,
      applicants_level,
      applicants_street_number_and_name,
      applicants_suburb,
      applicants_state,
      applicants_postcode,
      request_manual_review,
      information_to_transmit_via_manual_review,
      chairman_of_the_company,
      chairman_at_first_meeting,
    }
    handler()
  }
})(ReviewFormTemplate)


export default Review
