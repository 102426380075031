import * as API from './common'
import axios from 'axios'

export const addOrder = orderData => {
  return axios.post(`${API.baseUrl}/v1/orders`, orderData, { headers: { ...API.HEADERS }})
}

export const requestUpload = params => {
  return axios.post(`${API.baseUrl}/v1/documents`, params, { headers: { ...API.HEADERS }})
}

export const requestRead = (filename) => {
  return axios.get(`${API.baseUrl}/v1/documents/${filename}`, { headers: { ...API.HEADERS }})
}

export const getDocuments = () => {
  return axios.get(`${API.baseUrl}/v1/documents`, { headers: { ...API.HEADERS }})
}