import React from 'react'
import LoadingPage from '../Shared/LoadingPage'
import QuotesComponent from './QuotesComponent'
import { Context } from '../../context/context'
import { useDispatch, useSelector } from 'react-redux'
import { actionGetOrders } from '../../actions/ordersActions'

const Quotes = () => {

  // const [loading, setLoading] = React.useState(true)
  const context = React.useContext(Context)
  const dispatch = useDispatch()
  const orders = useSelector(state => state.orders.data)
  React.useEffect(() => {
    dispatch(actionGetOrders())
  }, [])

  return (
    orders?.isFetching ? 
      <LoadingPage /> : 
      <QuotesComponent />
  )
}

export default Quotes
