import { createStore, applyMiddleware, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import businessesReducer from '../reducers/businessesReducer'
import layoutReducer from '../reducers/layoutReducer'
import ordersReducer from '../reducers/ordersReducer'
import userReducer from '../reducers/userReducer'
import snackbarReducer from '../reducers/snackbarReducer'

export default () => {
  return createStore(
    combineReducers({
      user: userReducer,
      businesses: businessesReducer,
      layout: layoutReducer,
      orders: ordersReducer,
      snackbar: snackbarReducer
    }),
    {},
    compose(
      applyMiddleware(thunk),
      window.devToolsExtension ? window.devToolsExtension() : f => f
    )
  )
}
