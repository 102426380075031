import * as API from './common'
import axios from 'axios'

export const addOrder = orderData => {
  return axios.post(`${API.baseUrl}/v1/orders`, orderData, { headers: { ...API.HEADERS }})
}

export const getOrder = orderId => {
  return axios.get(`${API.baseUrl}/v1/orders/${orderId}`, { headers: { ...API.HEADERS }})
}

export const getOrders = () => {
  return axios.get(`${API.baseUrl}/v1/orders`, { headers: { ...API.HEADERS }})
}