
import React from 'react'
import { Typography, Box, makeStyles, Divider } from '@material-ui/core';
import AlternateEmailIcon from '@material-ui/icons/AlternateEmail';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import LabelIcon from '@material-ui/icons/Label';
import { useHistory } from 'react-router-dom'
import { requestRead } from '../../api/documentsApi';
import Container from '../Shared/Container';
import placeholder from '../../placeholder.png'

const useStyles = makeStyles(theme => ({
  wrapper:{
    display: 'flex',
    flexDirection: 'row',
  },
  logoContainer: {
    marginRight: '1em',
    display: 'flex',
    borderRadius: '10px',
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center'
  },
  placeholderContainer: {
    marginRight: '1em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${placeholder})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'norepeat',
    minHeight: '100%',
    minWidth: '150px',
    borderRadius: '10px',
    overflow: 'hidden'
  },
  logo: {
    maxWidth: '150px',
    maxHeight: '150px',
    width: 'auto',
    height: 'auto',
  },
  data: {
    flex: 1,
    paddingRight: theme.spacing(5)
  },
  icon: {
    marginRight: theme.spacing(2)
  },
  businessDataItem: {
    display: 'flex',
    margin: theme.spacing(1)
  },
  purpose: {
    marginLeft: theme.spacing(1)
  }
}))

const BusinessPreview = ({ business, click, clickable = false}) => {
  const [logo, setLogo] = React.useState(null)
  const history = useHistory()
  const classes = useStyles()

  React.useEffect(() => {
    business.Properties.logo && getLogo(business.Properties.logo)
    return () => setLogo(null)
  }, [business])

  const getLogo = filename => {
    requestRead(filename)
      .then(res => {
        console.log(res)
        setLogo(res.data.documentUrl)
      })
      .catch(err => {
        console.dir(err)
        setLogo(null)
      })
  }

  return (
    <Container onClick={click} clickable={clickable}>
      <Box className={classes.wrapper}>
        <Box className={classes.data}>
          <Typography variant="h3" color="primary"> {business.Properties.business_name} </Typography>
          <Divider />
          <Box display="flex" flexDirection="row">
            {
              logo ? 
                <Box className={classes.logoContainer}>
                  <img src={logo || placeholder} alt="" className={classes.logo}/>
                </Box> : 
                <Box className={classes.placeholderContainer} />
            }
            <Box display="flex" flexDirection="column">
              <Box className={classes.businessDataItem}>
                <LabelIcon className={classes.icon}/>
                <Typography variant="body1"><strong>{business.Properties.main_purpose}</strong></Typography>
              </Box>
              <Box className={classes.businessDataItem}>
                <AlternateEmailIcon className={classes.icon}/>
                <Typography variant="body1">{business.Properties.website}</Typography>
              </Box>
              <Box className={classes.businessDataItem}>
                <InstagramIcon className={classes.icon}/>
                <Typography variant="body1">{business.Properties.socials.instagram}</Typography>
              </Box>
              <Box className={classes.businessDataItem}>
                <FacebookIcon className={classes.icon}/>
                <Typography variant="body1">{business.Properties.socials.facebook}</Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        
      </Box>
    </Container>
  )
}

export default BusinessPreview