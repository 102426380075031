import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../Shared/Form'

import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Box, Button, Typography } from '@material-ui/core'
import { KeyboardDatePicker } from '@material-ui/pickers'

const App = ({
  scrollFunction,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  setFieldValue,
  isSubmitting
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
    console.log('effect')
  }, [])

  const handleDateChange = (e, v) => setFieldValue('date_commenced', e)
  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography>To help create a better picture of your business, fill in the below form, you can always come back and add info later if you don’t have the info yet</Typography>
        <TextField
          id="main_purpose"
          error={touched.main_purpose && errors.main_purpose}
          value={values.main_purpose}
          name="main_purpose"
          label="Main purpose"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.main_purpose && errors.main_purpose}
        /> 
        <KeyboardDatePicker
          name="date_commenced"
          label="Date commenced"
          placeholder="DD/MM/YYYY"
          value={values.date_commenced}
          onChange={date => handleDateChange(date)}
          format="DD/MM/YYYY"
          inputVariant="outlined"
          margin="normal"
          error={touched.date_commenced && errors.date_commenced}
          helperText={touched.date_commenced && errors.date_commenced}
        />
        {/* <TextField
          id="date_commenced"
          error={touched.date_commenced && errors.date_commenced}
          value={values.date_commenced}
          name="date_commenced"
          label="Date commenced"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.date_commenced && errors.date_commenced}
        />  */}
        <TextField
          id="instagram"
          error={touched.instagram && errors.instagram}
          value={values.instagram}
          name="instagram"
          label="Instagram"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.instagram && errors.instagram}
          InputProps={{
            startAdornment: <InputAdornment position="start">@</InputAdornment>,
          }}
        /> 
        <TextField
          id="facebook"
          error={touched.facebook && errors.facebook}
          value={values.facebook}
          name="facebook"
          label="Facebook"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.facebook && errors.facebook}
        /> 
        <TextField
          id="website"
          error={touched.website && errors.website}
          value={values.website}
          name="website"
          label="Website"
          onChange={handleChange}
          onBlur={handleBlur}
          margin="normal"
          variant="outlined"
          helperText={touched.website && errors.website}
        /> 
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={values.back}
            disabled={isSubmitting}
            fullWidth
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={isSubmitting}
            fullWidth
          >
            Next
          </Button>
        </Box>
      </Box>
    </Form>
  )
}

const BusinessNameForm = withFormik({
  mapPropsToValues: ({
    handler,
    back,
    formState
  }) => ({
      main_purpose: formState.main_purpose,
      date_commenced: formState.date_commenced,
      instagram: formState.instagram,
      facebook: formState.facebook,
      facebook: formState.facebook,
      website: formState.website,
      back,
      handler
    }),
  validationSchema: Yup.object().shape({
    main_purpose: Yup.string()
      .nullable(),
      // .required('Required'),
    date_commenced: Yup.string()
      .nullable(),
      // .required('Required'),
    instagram: Yup.string()
      .nullable(),
    facebook: Yup.string()
      .nullable(),
      // .required('Required'),
    website: Yup.string()
      .nullable(),
      // .required('Required')
  }),
  handleSubmit(
    { main_purpose, date_commenced, instagram, facebook, website, handler },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    console.log('submitted', { main_purpose, date_commenced, instagram, facebook, website })
    setTimeout(() => {
      handler({ main_purpose, date_commenced, website, socials: { instagram, facebook } })
    }, 2000)
  }
})(App)


export default BusinessNameForm
