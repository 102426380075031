import { Box, makeStyles, Typography } from '@material-ui/core'
import React from 'react'


const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary
  }
}));

const PageTitle = ({ children, ...rest }) => {
  const classes = useStyles()
  return (
    <Box width="100%" display="flex" flexDirection="row" justifyContent="center" alignItems="center">
      <Typography className={classes.title} variant="h1" >{children}</Typography>
    </Box>
  )
}

export default PageTitle
