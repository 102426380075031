import React from 'react'
import history from '../helpers/history'

export const capitalizeString = string => string?.replace(/^\w/, (c) => c.toUpperCase())

export const resolveActionName = action => {
  switch(action) {
    case 'name_check':
      return 'Name availability'
    case 'name_register':
      return 'Name registration'
    case 'name_copyright':
      return 'Name copyright'
    case 'brand_copyright':
      return 'Brand Copyright'
    case 'logo_check':
      return 'Logo availability'
    case 'logo_copyright':
      return 'Logo copyright'
    case 'brand_protection':
      return 'Brand protection'
    case 'asic_name':
      return 'Business name'
    default:
      return action
  }
}

export const resolveAction = (action, business) => {
  switch(action) {
    case 'add_business':
      return { name: 'Add New Business', action: () => history.push(`/add_business`), text: 'Add a new business now to begin the business creation process, from there you can do things like register your brand, trademark a logo and protect your brand' }
    case 'name_check':
      return { name: 'Name Availability', action: () => history.push(`/check_availability`, business ? { name: business.Properties.business_name } : null), text: 'Run a free check against Instagram, Facebook, Australian Business Registry and ASIC databases to see if anything similar comes up' }
    case 'name_register':
      return { name: 'Create a Company', action: () => history.push(`/register_business`, business ? { name: business.Properties.business_name } : null), text: 'Easily create a company' }
    case 'brand_copyright':
      return { name: 'Trademark a Brand', action: () => history.push(`/businesses/copyright/${business ? business.Properties.BusinessId : ''}`), text: 'Copyright your brandname and logo' }
    // case 'logo_check':
    //   return { name: 'Logo availability', action: () => history.push(`/businesses/copyright/${path}`), text: 'Check your logo for copyright availability' }
    // case 'logo_copyright':
    //   return { name: 'Logo copyright', action: () => history.push(`/businesses/copyright/${path}`), text: 'Copyright your logo' }
    // case 'brand_protection':
    //   return { name: 'Brand protection', action: () => console.log('check brand availability'), text: 'Brand protection' }
  }
}