import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import BusinessNameForm from './Forms/BusinessNameForm';
import LogoForm from './Forms/LogoForm';
import AdditionalBusinessInfoForm from './Forms/AdditionalBusinessInfoForm';
import { addBusiness } from '../../actions/businessesActions';
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import * as businessesApi from '../../api/businessesApi'
import { actionFetchDocuments } from '../../actions/documentsActions';
import PageTitle from '../Shared/PageTitle';
import AddBusinessLogoForm from './Forms/AddBusinessLogo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContent: {
    alignItems: 'center'
  }
}));


const AddBusiness = () => {
  const classes = useStyles();
  const dispatch = useDispatch()
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([
    {label: 'Choose a name', ref: React.useRef()}, 
    {label: 'Submit your logo', optional: true, ref: React.useRef()}, 
    {label: 'Additional information', ref: React.useRef()}
  ])
  const [formState, setFormState] = React.useState({
    business_name: null,
    logo: null,
    main_purpose: null,
    date_commenced: null,
    website: null,
    socials: {},
    checklist: [
      {id: 1, name: 'name_check', status: 'not_checked'},
      // {id: 2, name: 'logo_check', status: 'not_checked'},
      {id: 3, name: 'name_register', status: 'not_completed'},
      {id: 4, name: 'brand_copyright', status: 'not_checked'},
      // {id: 5, name: 'logo_copyright', status: 'not_checked'},
      // {id: 6, name: 'brand_protection', status: 'not_checked'},
    ]
  })

  const history = useHistory()
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSkip = stepIndex => {
    setSteps(prev => prev.map((step, i) => ( i == stepIndex ? {...step, skipped: true} : step )))
    handleNext()
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const businessNameFormHandler = values => {
    setFormState(prev => ({ ...prev, ...values }))
    console.log(values)
    handleNext()
  }

  const uploadLogoHandler = values => {
    setFormState(prev => ({ ...prev, ...values }))
    dispatch(actionFetchDocuments())
    handleNext()
  }

  const additionalInfoHandler = values => {
    setFormState(prev => ({ ...prev, ...values}))
    handleNext()
    dispatch(addBusiness({...formState, ...values, socials: { ...formState.socials, ...values.socials }}, () => {
      history.push(`/businesses`)
    }))
  }

  function getStepContent(step) {
    // const scrollFunction = () => steps[step].ref.current.scrollIntoView({behavior: "smooth", block: "start"})
    let scrollFunction = () => {
      let y = steps[step].ref.current.getBoundingClientRect().top + window.pageYOffset - 80 - 16
      window.scrollTo({top: y, behavior: 'smooth'})
    } 
    switch (step) {
      case 0:
        return <BusinessNameForm businessName={formState.business_name} handler={businessNameFormHandler} scrollFunction={scrollFunction}/>;
      case 1:
        return <AddBusinessLogoForm businessName={formState.business_name} logo={formState.logo} handler={uploadLogoHandler} skip={() => handleSkip(step)} back={handleBack} scrollFunction={scrollFunction}/>;
      case 2:
        return <AdditionalBusinessInfoForm formState={formState} handler={additionalInfoHandler} back={handleBack} scrollFunction={scrollFunction}/>;
      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <PageTitle>Add a new business</PageTitle>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({label, optional, skipped, ref}, index) => {
          let labelProps = {}
          // if(optional){
          //   labelProps.optional = <Typography variant="caption">Optional</Typography>
          // }
          let stepProps = {}
          if(skipped){
            console.log('skipped!')
            stepProps.completed = false
          }
          return(
          <Step {...stepProps} key={label} ref={ref}>
            <StepLabel {...labelProps}>{label}</StepLabel>
            <StepContent className={classes.stepContent}>
              {getStepContent(index)}
            </StepContent>
          </Step>
        )})}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished! We will now take you to your Business view</Typography>
          <Button onClick={handleReset} className={classes.button}>
            Reset
          </Button>
        </Paper>
      )}
    </div>
  );
}

export default AddBusiness