import React from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Paper from '@material-ui/core/Paper';
import PaperTitle from '../../Theme/customComponents/PaperTitle';
import { Typography, Box, Divider, Button, Grid } from '@material-ui/core';
import QuickAction from '../Shared/QuickAction';
import Container from '../Shared/Container';
import LoadingPage from '../Shared/LoadingPage';
import { resolveAction } from '../../helpers/helpers'

const QuickActionsSnippet = ({ fill }) => {
  const history = useHistory()
  const businesses = useSelector(state => state.businesses?.data?.items)
  return (
    <Container> 
      <Typography variant="h3">
        Quick services
      </Typography>
      <Divider />
      <Box display="flex">
        {
          [{name: 'add_business', restriction: false}, {name: 'name_check', restriction: false}, {name: 'name_register', restriction: true}, {name: 'brand_copyright', restriction: true}]
            // .filter(el => {
            //   return businesses.length ? true : !el.restriction
            // })
            .map((check, i) => {
              let resolved = resolveAction(check.name, null)
              return <Button color="primary" onClick={resolved?.action}>{resolved.name}</Button> 
            })
        }
      </Box>
    </Container>
  )
}

export default QuickActionsSnippet
