// issue refund for 5k and 3k

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import AddIcon from '@material-ui/icons/Add';
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button } from '@material-ui/core';
import WarningButton from '../Shared/WarningButton';



const useStyles = makeStyles((theme) => ({
  nested: {
    borderRadius: '10px',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.5)
  },
}));

export default function BusinessesCollapse({ open }) {

  const businesses = useSelector(state => state.businesses?.data?.items)
  const classes = useStyles()
  const history = useHistory()

  const clickHandler = target => history.push(`${target}`)

  return (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <List component="div" disablePadding>
        {
          businesses ? 
            businesses.map(business => (
              <ListItem key={business?.BusinessId} button className={classes.nested} onClick={() => clickHandler('/businesses/' + business.BusinessId)}>
                <ListItemText primary={business?.Properties?.business_name} />
              </ListItem>
            )) :
            <ListItem button className={classes.nested}>
              <ListItemText primary="Loading Businesses..." />
            </ListItem>
        }
        <WarningButton 
          startIcon={<AddIcon />} 
          className={classes.nested} 
          onClick={() => clickHandler('/add_business')} 
          size="large" 
          fullWidth
          variant="outlined"
        >
          Add Business
        </WarningButton>
        {/* <ListItem button className={classes.nested} onClick={() => clickHandler('/add_business')} variant="outlined">
          <ListItemIcon><AddIcon /></ListItemIcon>
          <ListItemText primary="Add business" />
        </ListItem> */}
      </List>
    </Collapse>
  );
}