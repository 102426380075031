export const TRIGGER = '@@redux-snackbar/TRIGGER'
export const CLOSE = '@@redux-snackbar/CLOSE'

export const actionOpenSnackbar = message => ({
  type: TRIGGER,
  payload: message
})

export const actionCloseSnackbar = () => ({
  type: CLOSE
})