import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import { makeStyles } from '@material-ui/core/styles';
import Form from '../../Shared/Form'

import ResultCard from '../../Shared/ResultCard';
import LoadingPage from '../../Shared/LoadingPage';
import { Box, Button, Typography } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  input: { display: 'none' },
  button: { marginLeft: 0, marginRight: 0 },
  label: { width: '100%', display: 'flex', marginTop: theme.spacing(2) },
  image: { width: 350, height: 'auto', margin: '0 auto', marginTop: theme.spacing(2) },
  success: { backgroundColor: theme.status.success[400], '&:hover': { backgroundColor: theme.status.success[700] } }
}));

const App = ({
  scrollFunction,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  title = null
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
    console.log('effect')
  }, [])

  const classes = useStyles()
  const hangleImageUpload = e => {
    console.log(e.target.files)  
    setFieldValue('logo', URL.createObjectURL(e.target.files[0]))
  }
  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        { title ? () => title(values.business_name) : "Do you have a logo yet? Upload it to your profile" }
        <Box width="350px" height="350px">
          {values.logo ? <img className={classes.image} src={values.logo} alt=""/> : <Box width="350px" height="350px" bgcolor="#bbb" margin="0 auto" marginTop={2}/>}
        </Box>
        <input
          accept="image/*"
          className={classes.input}
          id="contained-button-file"
          name="logo"
          type="file"
          onChange={hangleImageUpload}
        />
        {
          isSubmitting ? 
            <LoadingPage /> : 
            values.result ? 
            <>
              <ResultCard results={values.result} /> 
              <label htmlFor="contained-button-file" className={classes.label}>
                <Button className={classes.button} variant="contained" color="primary" component="span" fullWidth>
                  {values.logo ? 'Change logo' : 'Select logo'}
                </Button>
              </label>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  values.handler(values.result.logo_check.status)
                }}
                fullWidth
              >
                Continue to trademark application
              </Button>
            </> : 
            <>
              <label htmlFor="contained-button-file" className={classes.label}>
                <Button className={classes.button} variant="contained" color="primary" component="span" fullWidth>
                  {values.logo ? 'Change logo' : 'Select logo'}
                </Button>
              </label>
              <Button
                variant="contained"
                color="primary"
                onClick={values.back}
                fullWidth
              >
                Back
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={values.skip}
                fullWidth
              >
                Skip
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={!values.logo}
                className={classes.success}
                onClick={handleSubmit}
                fullWidth
              >
                Check the logo
              </Button>
            </>
        }
        {/* <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={values.back}
            fullWidth
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={values.skip}
            fullWidth
          >
            Skip
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!values.logo}
            className={values.logo ? classes.success : null}
            onClick={handleSubmit}
            fullWidth
          >
            Check the logo
          </Button>
        </Box> */}
      </Box>
    </Form>
  )
}

const LogoForm = withFormik({
  mapPropsToValues: ({
    handler,
    checkLogo,
    refreshUserData,
    updateBankDetails,
    businessName,
    logo,
    back,
    skip
  }) => ({
      updateBankDetails,
      refreshUserData,
      business_name: businessName,
      logo,
      handler,
      checkLogo,
      back,
      skip,
      result: null
    }),
  validationSchema: Yup.object().shape({
    business_name: Yup.string()
      .nullable()
      .required('Required')
  }),
  handleSubmit(
    { business_name, logo, checkLogo },
    { setSubmitting, setFieldValue }
  ) {
    // setSubmitting(true)
    // handler({ business_name, logo })
    setSubmitting(true)
    checkLogo(res => {
      setFieldValue('result', res.checklist)
      setSubmitting(false)
    })
  }
})(App)

export default LogoForm
