import {
  FETCHING,
  FETCH_ORDERS,
  LOGOUT
} from '../actions/ordersActions'

const initialStore = {
  isFetching: false,
  error: null,
  data: null
}

export default (state = initialStore, action) => {
  switch (action.type) {
    case FETCH_ORDERS: {
      return {
        data: {
          ...state.data,
          items: action.payload
        },
        isFetching: false
      }
    }

    case FETCHING: {
      return {
        ...state,
        isFetching: true
      }
    }

    case LOGOUT: 
      return initialStore

    default: {
      return state
    }
  }
}
