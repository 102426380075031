import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik, FieldArray, Field, getIn, FastField } from 'formik'
import Form from '../../../Shared/Form'

import { Box, Button, Typography, CircularProgress, FormHelperText, Grid, OutlinedInput } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'

const Input = ({ label, field, form: { errors, touched, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  return(
    <FormControl component="fieldset" error={isTouched && errorMessage}>
      <FormLabel component="legend">{label}</FormLabel>
      <OutlinedInput {...field}
        margin="normal"
        variant="outlined"
        margin="dense"
        fixedLabel="true"
      /> 
      { (submitCount > 0 || isTouched) && errorMessage && <FormHelperText>{errorMessage}</FormHelperText> }
    </FormControl>
  )
}

const Role = ({ label, field, form: { errors, touched, values, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  const fieldValue = getIn(values, field.name)
  return(
    <FormControl component="fieldset" error={errorMessage} margin="dense">
      <FormControlLabel
        control={
          <Checkbox
            {...field}
            color="primary"
            checked={fieldValue}
          />
        }
        label={label}
      />
      { errorMessage && <FormHelperText>{errorMessage}</FormHelperText> }
    </FormControl>
  )
}

const Options = ({ children, label, field, form: { errors, touched, values, submitCount } }) => {
  const errorMessage = getIn(errors, field.name)
  const isTouched = getIn(touched, field.name)
  return(
    <FormControl component="fieldset" variant="outlined" size="small" error={isTouched && errorMessage}>
      <FormLabel component="legend">{label}</FormLabel>
      <Select {...field}>
        {children}
      </Select>
      { isTouched && errorMessage && <FormHelperText>{errorMessage}</FormHelperText> }
    </FormControl>
  )
}

const App = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  submitCount,
  scrollFunction
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])

  return (
    <Form>
      { values.review && <Box position="absolute" width="100%" height="100%" zIndex="999" bgcolor="rgba(0,0,0,0.1)"></Box> }
      <Box width="80%" maxWidth="800px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h6">Officeholders of the company</Typography>
        <Typography variant="body1">The Officeholders are the Directors, Secretary and Public Officer of the Company</Typography>
        <Divider />
        <FieldArray name="officeholders">
          {
            ({ push, remove }) => (
              <>
                {
                  values.officeholders.map((elem, i) => {
                    return(
                      <>
                        <Grid container>
                          <Grid item xs="9">
                            <Typography variant="h6">{`Officeholder ${i + 1}`}</Typography>
                          </Grid>
                          <Grid item xs="3">
                            <Box display="flex" justifyContent="flex-end">
                              <Button color="primary" onClick={() => remove(i)}>Remove</Button>
                            </Box>
                          </Grid>
                        </Grid>
                        <FastField label="Given Names" name={`officeholders[${i}].given_names`} component={Input} />
                        <FastField label="Family Name" name={`officeholders[${i}].family_name`} component={Input} />
                        <br/>
                        <Typography variant="body1">What role will this person have in the Company?</Typography>
                        <Typography variant="caption">Please note that you may appoint as many directors and secretaries as you wish, however, you may only appoint one Public Officer</Typography>
                        <FastField label="Director" name={`officeholders[${i}].is_director`} component={Role} />
                        <FastField label="Secretary" name={`officeholders[${i}].is_secretary`} component={Role} />
                        <FastField label="Public Officer" name={`officeholders[${i}].is_public_officer`} component={Role} />

                        <Divider />

                        <Typography variant="body1">Officeholder Address Details</Typography>
                        <Typography variant="caption">This is the residential address of the officeholder, and cannot be a PO Box. Please ensure your address is spelt correctly, otherwise your application will be rejected by ASIC.</Typography>
                        <br />
                        <FastField label="What is the officeholder's address?" name={`officeholders[${i}].address`} component={Options}>
                          {
                            values.addresses.map(address => <MenuItem value={address?.full_address}>{address?.full_address}</MenuItem>)
                          }
                          <MenuItem value="new">A different address</MenuItem>
                        </FastField>
                        {
                          values.officeholders[i].address == 'new' &&
                            <>
                              <FastField label="Level, Floor, Unit, Office, Suite" name={`officeholders[${i}].level`} component={Input} />
                              <FastField label="Street Number and Name" name={`officeholders[${i}].street_number_and_name`} component={Input} />
                              <FastField label="Suburb" name={`officeholders[${i}].suburb`} component={Input} />
                              <FastField label="State" name={`officeholders[${i}].state`} component={Options}>
                                <MenuItem value="">
                                  <em>Please select a state</em>
                                </MenuItem>
                                <MenuItem value="ACT">ACT</MenuItem>
                                <MenuItem value="NSW">NSW</MenuItem>
                                <MenuItem value="NT">NT</MenuItem>
                                <MenuItem value="QLD">QLD</MenuItem>
                                <MenuItem value="SA">SA</MenuItem>
                                <MenuItem value="TAS">TAS</MenuItem>
                                <MenuItem value="VIC">VIC</MenuItem>
                                <MenuItem value="WA">WA</MenuItem>
                              </FastField>
                              <FastField label="Postcode" name={`officeholders[${i}].postcode`} component={Input} />
                            </>
                        }
                        <Divider />
                        <Typography variant="h6">Birth Details of the Officeholder</Typography>
                        <br/>
                        <FastField label="State of Birth - (Optional)" name={`officeholders[${i}].state_of_birth`} component={Options}>
                          <MenuItem value="">
                            <em>Please select a state</em>
                          </MenuItem>
                          <MenuItem value="ACT">ACT</MenuItem>
                          <MenuItem value="NSW">NSW</MenuItem>
                          <MenuItem value="NT">NT</MenuItem>
                          <MenuItem value="QLD">QLD</MenuItem>
                          <MenuItem value="SA">SA</MenuItem>
                          <MenuItem value="TAS">TAS</MenuItem>
                          <MenuItem value="VIC">VIC</MenuItem>
                          <MenuItem value="WA">WA</MenuItem>
                        </FastField>
                        <FastField label="City of Birth" name={`officeholders[${i}].city_of_birth`} component={Input} />
                        <FastField type="date" label="Officeholder Date of Birth (dd/mm/yyyy)" name={`officeholders[${i}].date_of_birth`} component={Input} />
                        
                      </>
                    )})
                }
                {
                  !values.review && (
                    <>
                      <Divider />
                      <Button
                        variant="contained"
                        color="primary"
                        type="button"
                        onClick={() => push({ 
                          id: Date.now() + Math.random(),
                          given_names: '',
                          family_name: '',
                          is_director: false,
                          is_secretary: false,
                          is_public_officer: false
                        })}
                      >
                        Add Officeholder
                      </Button>
                    </>
                  )
                }
              </>
            )
          }
        </FieldArray>
        {
          !values.review && (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={status.back}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
                  fullWidth
                >
                  {isSubmitting ? 'Loading' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Form>
  )
}

const Officeholders = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName,
    officeholders,
    review,
    addresses
  }) => ({
      updateBankDetails,
      refreshUserData,
      officeholders,
      addresses,
      review,
      handler
      //todo: add missing fields
    }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    officeholders: Yup.array().of(
      Yup.object().shape({
        given_names: Yup.string().required('Required'),
        family_name: Yup.string().required('Required'),
        is_director: Yup.boolean().when('is_public_officer', { is: true, then: Yup.boolean().oneOf([true], 'Public officers MUST be Directors')}),
        is_secretary: Yup.boolean(),
        is_public_officer: Yup.boolean().test('multiple_officers', "There can't be multiple public officers", (el, context) => {
          const public_officers = context.from[1].value.officeholders.filter(el => el.is_public_officer)
          return public_officers.length < 2
        }),
        address: Yup.mixed().required('Address required'),
        level: Yup.string(),
        street_number_and_name: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')}),
        suburb: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')}),
        state: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')}),
        postcode: Yup.string().when('address', {is: 'new', then: Yup.string().required('Required')}),
        city_of_birth: Yup.string().required('Required'),
        date_of_birth: Yup.string().required('Required'),
        //todo: add missing fields validation
      })
    ),
  }),
  handleSubmit(
    { handler, ...values },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const newAddresses = values.officeholders.map((o, i) => {
      return o.address != 'new' ? null : {
        name: `${o.given_names} ${o.family_name}'s address`,
        id: `officeholder_${i}_address`,
        level: o.level,
        street_number_and_name: o.street_number_and_name,
        suburb: o.suburb,
        state: o.state,
        postcode: o.postcode,
        full_address: `${o.level} ${o.street_number_and_name}, ${o.suburb}, ${o.state} ${o.postcode}`
      }
    }).filter(address => address)
    handler(values.officeholders, newAddresses)
  }
})(App)


export default Officeholders
