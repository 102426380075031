import React from 'react'
import BusinessesComponent from './BusinessesComponent'
import { Context } from '../../context/context'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import LoadingPage from '../Shared/LoadingPage'

const Businesses = () => {
  const dispatch = useDispatch()
  const isFetching = useSelector(state => state.businesses.isFetching)
  const businesses = useSelector(state => state.businesses?.data?.items)
  const context = React.useContext(Context)
  const location = useLocation()
  const [currentBusiness, setCurrentBusiness] = React.useState(null)
  const path = location.pathname.split('/').reverse()[0]

  React.useEffect(() => { 
    // Fetch updated record for specific business instead
    businesses && setCurrentBusiness(businesses.filter(b => b.Properties.business_name == path)[0])
  }, [businesses, location])
  // React.useEffect(() => { context.setLoading(isFetching) }, [isFetching])

  return (
    businesses ? 
      <BusinessesComponent businesses={businesses}/> : 
      <LoadingPage />
  )
}

export default Businesses
