import React from 'react'
import LoadingPage from '../Shared/LoadingPage'
import DocumentsComponent from './DocumentsComponent'
import { Context } from '../../context/context'

const Documents = () => {

  // const [loading, setLoading] = React.useState(true)
  const context = React.useContext(Context)

  React.useEffect(() => {
    context.setLoading(true)
    setTimeout(() => {
      context.setLoading(false)
    }, 1500)
  }, [])

  return (
    context.isLoading ? <LoadingPage /> : <DocumentsComponent />
  )
}

export default Documents
