import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../Shared/Form'
import { makeStyles } from '@material-ui/core/styles';
import ReactS3Uploader from 'react-s3-uploader'
import { requestRead } from '../../../api/documentsApi';
import TextField from '@material-ui/core/TextField'
import { Box, Button, Typography } from '@material-ui/core'
import { requestUpload } from '../../../api/documentsApi'

const useStyles = makeStyles((theme) => ({
  input: { display: 'none' },
  label: { width: '100%', display: 'flex', marginTop: theme.spacing(2) },
  image: { width: 350, height: 'auto', margin: '0 auto', marginTop: theme.spacing(2) },
  success: { backgroundColor: theme.status.success[400], '&:hover': { backgroundColor: theme.status.success[700] } }
}));

const AddBusinessLogoFormTemplate = ({
  scrollFunction,
  values,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  handleSubmit,
  setFieldValue,
  setSubmitting,
  title = null
}) => {
  const [logo, setLogo] = React.useState(null)

  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])

  const [uploader, setUploader] = React.useState(null)
  const [uploadCounter, setUploadCounter] = React.useState(0)

  const classes = useStyles()
  const hangleImageUpload = e => {
    console.log(e.target.files)  
    const l = URL.createObjectURL(e.target.files[0])
    setFieldValue('logo', l)
    setLogo(l)
  }

  function getSignedUrl(file, callback) {
    const params = {
      filename: file.name,
      type: file.type
    };
  
    requestUpload(params)
      .then(res => {
        console.log(res, 'after request url')
        callback({signedUrl: res.data.uploadUrl});
        setSubmitting(false)
      })
      .catch(error => {
        console.dir(error);
      });
  }

  const handleNext = () => {
    setSubmitting(true)
    uploader.uploadFile()
    // handleSubmit()
  }

  const handleProgress = e => {
    if (uploadCounter == 0) {
      if (e == 100) {
        setUploadCounter(uploadCounter + 1)
        console.log(e, 'e')
        // if(uploadCounter == 1) {
        //   handleSubmit()
        // }
      } 
    }
  }

  const handlePreProcess = (file, next) => {
    setSubmitting(true)
    const newFile = new File([file], `${Date.now()}_${file.name.replace(/[^\w\d_\-.]+/ig, '')}`)
    setFieldValue('filename', newFile.name)
    next(newFile)
  }

  return (
    <Form>
      <Box width="80%" maxWidth="600px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <ReactS3Uploader 
          getSignedUrl={getSignedUrl} 
          uploadRequestHeaders={{}}
          preprocess={(file, next) => handlePreProcess(file, next)}
          ref={ uploader => { 
            console.log('handling ref...', uploader)
            setUploader(uploader) 
          }}
          onFinish={e => {
            handleSubmit()
          }}
          onChange={hangleImageUpload}
          onProgress={handleProgress}
          autoUpload={false}
          className={classes.input}
          id="contained-button-file"
          name="logo"
        />
        { title ? () => title(values.business_name) : "Do you have a logo yet? Upload it to your profile" }
        <Box width="350px" height="350px">
          {
            logo ? 
              <img className={classes.image} src={logo} alt=""/> : 
              <Box width="350px" height="350px" bgcolor="#bbb" margin="0 auto" marginTop={2}/>
          }
        </Box>
        <label htmlFor="contained-button-file" className={classes.label}>
          <Button variant="contained" color="primary" component="span" fullWidth>
            {values.logo ? 'Change logo' : 'Select logo'}
          </Button>
        </label>
        <Box display="flex" justifyContent="center" width="100%">
          <Button
            variant="contained"
            color="primary"
            onClick={values.back}
            fullWidth
          >
            Back
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={values.skip}
            fullWidth
          >
            Skip
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!values.logo}
            className={values.logo ? classes.success : null}
            onClick={handleNext}
            fullWidth
          >
            Next
          </Button>
        </Box>
      </Box>
    </Form>
  )
}

const AddBusinessLogoForm = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName,
    logo,
    back,
    skip
  }) => ({
      updateBankDetails,
      refreshUserData,
      business_name: businessName,
      logo,
      handler,
      back,
      filename: null,
      skip
    }),
  validationSchema: Yup.object().shape({
    business_name: Yup.string()
      .nullable()
      .required('Required')
  }),
  handleSubmit(
    { business_name, handler, filename },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    handler({ business_name, logo: filename })
  }
})(AddBusinessLogoFormTemplate)

export default AddBusinessLogoForm
