import { Grid, Card, CardHeader, CardContent, CardActions, Button, makeStyles } from '@material-ui/core'
import React from 'react'
import PageTitle from '../Shared/PageTitle'
import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
  right: {
    justifyContent: 'flex-end'
  }
}))

const Partners = () => {
  const classes = useStyles()
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <PageTitle>Our partners</PageTitle>
        </Grid>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={1}>
            <CardHeader title="Partner" />
            <CardContent>
              Partner description and offers
            </CardContent>
            <CardActions className={classes.right}>
              <Button endIcon={<LaunchIcon fontSize={'small'}/>} color="primary">Visit partner</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={1}>
            <CardHeader title="Partner" />
            <CardContent>
              Partner description and offers
            </CardContent>
            <CardActions className={classes.right}>
              <Button endIcon={<LaunchIcon fontSize={'small'}/>} color="primary">Visit partner</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={1}>
            <CardHeader title="Partner" />
            <CardContent>
              Partner description and offers
            </CardContent>
            <CardActions className={classes.right}>
              <Button endIcon={<LaunchIcon fontSize={'small'}/>} color="primary">Visit partner</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={1}>
            <CardHeader title="Partner" />
            <CardContent>
              Partner description and offers
            </CardContent>
            <CardActions className={classes.right}>
              <Button endIcon={<LaunchIcon fontSize={'small'}/>} color="primary">Visit partner</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={1}>
            <CardHeader title="Partner" />
            <CardContent>
              Partner description and offers
            </CardContent>
            <CardActions className={classes.right}>
              <Button endIcon={<LaunchIcon fontSize={'small'}/>} color="primary">Visit partner</Button>
            </CardActions>
          </Card>
        </Grid>
        <Grid item xs={4}>
          <Card elevation={1}>
            <CardHeader title="Partner" />
            <CardContent>
              Partner description and offers
            </CardContent>
            <CardActions className={classes.right}>
              <Button endIcon={<LaunchIcon fontSize={'small'}/>} color="primary">Visit partner</Button>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

export default Partners
