import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import { Box, Button, Typography, CircularProgress, FormHelperText, Grid, OutlinedInput, FormGroup } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'

const App = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  submitCount,
  scrollFunction
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  return (
    <Form>
      { values.review && <Box position="absolute" width="100%" height="100%" zIndex="999" bgcolor="rgba(0,0,0,0.1)"></Box> }
      <Box width="80%" maxWidth="800px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <ExpandableHeading heading={`New order details`} text={
          <>
            <Typography variant="caption">
              Select ‘Full Service’ if you would like your documents professionally printed and bound in high class folders and express posted to you.  Full Service preparation also includes legal oversight of your documents where required (for example, for Super Fund variations, borrowings and changes of trustee).
            </Typography>
            <br/>
            <br/>
            <Typography variant="caption">
              Select ‘Instant Email Delivery’ if you would like to receive your documents via email, and do not want printed and bound copies. Your documents will be emailed to you immediately after you have made payment for your order.
            </Typography>
          </>
        } />
        <br/>
        <FormControl component="fieldset" error={touched.order_reference && errors.order_reference}>
          <FormLabel component="legend">Enter your reference for this order</FormLabel>
          <OutlinedInput
            id="order_reference"
            value={values.order_reference}
            name="order_reference"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.order_reference) && errors.order_reference && <FormHelperText>{errors.order_reference}</FormHelperText> }
        </FormControl>
        <br/>
        <br/>
        <FormControl component="fieldset" error={(submitCount > 0 || touched.type_of_service) && errors.type_of_service} helperText="Hello">
          <FormLabel component="legend">Type of service</FormLabel>
          <RadioGroup 
            aria-label="type_of_service" 
            name="type_of_service" 
            value={values.type_of_service} 
            onChange={handleChange} 
            color="primary"
          >
            <FormControlLabel value="full_service" control={<Radio color="primary" />} label="Full service - $697.00" />
            <FormControlLabel value="instant_email_delivery" control={<Radio />} label="Instant Email Delivery - $625.00" />
          </RadioGroup>
          { (submitCount > 0 || touched.type_of_service) && errors.type_of_service && <FormHelperText>{errors.type_of_service}</FormHelperText> }
        </FormControl>
        {
          values.type_of_service == 'full_service' && 
            <>
              <FormControl component="fieldset" error={touched.first_name && errors.first_name}>
                <FormLabel component="legend">First Name</FormLabel>
                <OutlinedInput
                  id="first_name"
                  value={values.first_name}
                  name="first_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  margin="dense"
                  fixedLabel="true"
                /> 
                { (submitCount > 0 || touched.first_name) && errors.first_name && <FormHelperText>{errors.first_name}</FormHelperText> }
              </FormControl>
              <FormControl component="fieldset" error={touched.last_name && errors.last_name}>
                <FormLabel component="legend">Last Name</FormLabel>
                <OutlinedInput
                  id="last_name"
                  value={values.last_name}
                  name="last_name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  margin="dense"
                  fixedLabel="true"
                /> 
                { (submitCount > 0 || touched.last_name) && errors.last_name && <FormHelperText>{errors.last_name}</FormHelperText> }
              </FormControl>
              <FormControl component="fieldset" error={touched.level_floor && errors.level_floor}>
                <FormLabel component="legend">Level, Floor</FormLabel>
                <OutlinedInput
                  id="level_floor"
                  value={values.level_floor}
                  name="level_floor"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  margin="dense"
                  fixedLabel="true"
                /> 
                { (submitCount > 0 || touched.level_floor) && errors.level_floor && <FormHelperText>{errors.level_floor}</FormHelperText> }
              </FormControl>
              <FormControl component="fieldset" error={touched.street && errors.street}>
                <FormLabel component="legend">Street</FormLabel>
                <OutlinedInput
                  id="street"
                  value={values.street}
                  name="street"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  margin="dense"
                  fixedLabel="true"
                /> 
                { (submitCount > 0 || touched.street) && errors.street && <FormHelperText>{errors.street}</FormHelperText> }
              </FormControl>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <FormControl component="fieldset" fullWidth error={touched.suburb && errors.suburb}>
                    <FormLabel component="legend">Suburb</FormLabel>
                    <OutlinedInput
                      id="suburb"
                      value={values.suburb}
                      name="suburb"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      variant="outlined"
                      margin="dense"
                      fixedLabel="true"
                    /> 
                    { (submitCount > 0 || touched.suburb) && errors.suburb && <FormHelperText>{errors.suburb}</FormHelperText> }
                  </FormControl>
                </Grid>
                <Grid item xs={2}>
                  <FormControl component="fieldset" variant="outlined" margin="dense" fullWidth error={touched.state && errors.state}>
                    <FormLabel component="legend">State</FormLabel>
                    <Select
                      id="state"
                      name="state"
                      value={values.state}
                      onChange={handleChange}
                    >
                      <MenuItem value="">
                        <em>Please select a state</em>
                      </MenuItem>
                      <MenuItem value="ACT">ACT</MenuItem>
                      <MenuItem value="NSW">NSW</MenuItem>
                      <MenuItem value="NT">NT</MenuItem>
                      <MenuItem value="QLD">QLD</MenuItem>
                      <MenuItem value="SA">SA</MenuItem>
                      <MenuItem value="TAS">TAS</MenuItem>
                      <MenuItem value="VIC">VIC</MenuItem>
                      <MenuItem value="WA">WA</MenuItem>
                    </Select>
                    { (submitCount > 0 || touched.state) && errors.state && <FormHelperText>{errors.state}</FormHelperText> }
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <FormControl component="fieldset" fullWidth error={touched.postcode && errors.postcode}>
                    <FormLabel component="legend">Postcode</FormLabel>
                    <OutlinedInput
                      id="postcode"
                      value={values.postcode}
                      name="postcode"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      margin="normal"
                      variant="outlined"
                      margin="dense"
                      fixedLabel="true"
                    /> 
                    { (submitCount > 0 || touched.postcode) && errors.postcode && <FormHelperText>{errors.postcode}</FormHelperText> }
                  </FormControl>
                </Grid>
              </Grid>
              <br/>
              <br/>
              <FormControl component="fieldset" error={(submitCount > 0 || touched.deliver_to_end_client) && errors.deliver_to_end_client} helperText="Hello">
                <FormLabel component="legend">Is this order being delivered to the end client?</FormLabel>
                <RadioGroup 
                  aria-label="deliver_to_end_client" 
                  name="deliver_to_end_client" 
                  value={values.deliver_to_end_client} 
                  onChange={handleChange} 
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                { (submitCount > 0 || touched.deliver_to_end_client) && errors.deliver_to_end_client && <FormHelperText>{errors.deliver_to_end_client}</FormHelperText> }
              </FormControl>
              <br/>
              <br/>
              <FormControl component="fieldset" error={(submitCount > 0 || touched.send_email_copy) && errors.send_email_copy} helperText="Hello">
                <FormLabel component="legend">Would you like an email copy of your documents sent to you?</FormLabel>
                <RadioGroup 
                  aria-label="send_email_copy" 
                  name="send_email_copy" 
                  value={values.send_email_copy} 
                  onChange={handleChange} 
                >
                  <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio />} label="No" />
                </RadioGroup>
                { (submitCount > 0 || touched.send_email_copy) && errors.send_email_copy && <FormHelperText>{errors.send_email_copy}</FormHelperText> }
              </FormControl>
            </>
        }
        <br/>

        <Divider />

        <br/>
        <ExpandableHeading heading={`Create my ABN & TFN Application`} text={
          <>
          <Typography variant="caption">
            Brandhub's link with the Australian Business Register (ABR) means we can automatically populate your online ABN & TFN application for you, free of charge.
          </Typography>
          <Typography variant="caption">
            Select this option and you will be able to review the online application then submit it to the ABR.
          </Typography>
          </>
        } />
        <FormControlLabel
          control={<Checkbox checked={values.populate_abn_and_tfn} onChange={handleChange} name="populate_abn_and_tfn" />}
          label="Populate my ABN & TFN application (free)"
        />
        <br/>
        <Divider />
        <ExpandableHeading expanded="true" noPaddingLeft="true" fixed={true} heading={`Important notes regarding your order`} text={
          <>
            <FormControl error={ errors.terms}>
              <FormLabel>Terms and conditions</FormLabel>
              <br/>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox checked={values.terms} onChange={handleChange} name="terms" error={(submitCount > 0) && errors.terms} />}
                  label="I agree to the Terms of Service detailed on this website"
                />
              </FormGroup>
            </FormControl>
            <br/>
            <Typography variant="caption">
              In providing the completed Company Registration Package to you we are specifically not providing you with any financial or legal advice as to the appropriateness of a Company for your particular financial requirements and circumstances.
            </Typography>
            {/* <Typography variant="caption">
              When purchasing this document you are agreeing to our Terms of Service as detailed on our website.
            </Typography> */}
          </>
        } />
        {
          !values.review && (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={status.back}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
                  fullWidth
                >
                  {isSubmitting ? 'Loading' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Form>
  )
}

const StartOrder = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    businessName,
    review,
    data
  }) => ({
      updateBankDetails,
      refreshUserData,
      businessName,
      review,
      order_reference: data.order_reference || businessName,
      type_of_service: data.type_of_service || '',
      populate_abn_and_tfn: data.populate_abn_and_tfn || null,
      terms: data.terms || false,
      first_name: data.first_name || '',
      last_name: data.last_name || '',
      level_floor: data.level_floor || '',
      street: data.street || '',
      suburb: data.suburb || '',
      state: data.state || '',
      postcode: data.postcode || '',
      deliver_to_end_client: data.deliver_to_end_client || '',
      send_email_copy: data.send_email_copy || '',
      handler
    }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    order_reference: Yup.string().required('Order Reference required'),
    type_of_service: Yup.string().required('Type of service required'),
    first_name: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    last_name: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    level_floor: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    street: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    suburb: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    state: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    postcode: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    deliver_to_end_client: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    send_email_copy: Yup.string().when('type_of_service', {is: 'full_service', then: Yup.string().required('Required')}),
    terms: Yup.mixed().oneOf([true])
  }),
  handleSubmit(
    { handler, ...values },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const first_screen = {
      order_reference: values.order_reference,
      type_of_service: values.type_of_service,
      populate_abn_and_tfn: values.populate_abn_and_tfn,
    }
    const full_service_data = values.type_of_service == 'full_service' ? {
      first_name: values.first_name,
      last_name: values.last_name,
      level_floor: values.level_floor,
      street: values.street,
      suburb: values.suburb,
      state: values.state,
      postcode: values.postcode,
      deliver_to_end_client: values.deliver_to_end_client,
      send_email_copy: values.send_email_copy,
    } : {}
    handler({ ...first_screen, ...full_service_data })
  }
})(App)


export default StartOrder
