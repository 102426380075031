import { fetchMenuItemsApi } from '../api/layoutApi'
import { fetchBusinesses, clearBusinesses } from '../actions/businessesActions'
import { Auth } from 'aws-amplify';
import { getOrders } from '../api/ordersApi';
import { getBusinesses } from '../api/businessesApi';
import { cleatOrders } from './ordersActions';
import { actionFetchDocuments } from './documentsActions';

export const FETCHING = '@@redux-user/FETCHING'
export const LOGIN = '@@redux- user/LOGIN'
export const LOGOUT = '@@redux-user/LOGOUT'

export const maybeRefresh = async () => {
  try {
    console.log(localStorage.getItem('CognitoIdentityServiceProvider.gna6r2fluaf4qsnkv7jiu02ag.gonzalo.idToken'), 'curr token')
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const { refreshToken } = cognitoUser.getSignInUserSession();
    cognitoUser.refreshSession(refreshToken, (err, session) => {
      // do something with new session here, maybe dispatch and save jwt in redux
      console.log(err, session, 'err, session')
    })
  } catch (e){
    console.log(e)
  }
}

const fetchAll = async () => {
  try {
    const businesses = (await getBusinesses()).data.data
    // add more things to load here
    return new Promise(resolve => {
      resolve({
        businesses: businesses
        // and return them here
      })
    }
  )} catch(e) {
    return new Promise((resolve, reject) => reject(e))
  }
}

export const fetchEverything = jwt => {
  return dispatch => {
    dispatch(fetching(true))
    fetchAll()
      .then(res => {
        dispatch(fetching(false))
      })
  }
}

export const actionLogin = ({ jwt, user }) => {
  return dispatch => {
    dispatch(login({jwt, data: user}))
    dispatch(fetchBusinesses())
    dispatch(actionFetchDocuments())
  }
}

export const actionLogout = () => {
  return dispatch => {
    dispatch(logout())
    dispatch(clearBusinesses())
    dispatch(cleatOrders())
  }
}

export const logout = () => ({
  type: LOGOUT
})

export const login = payload => ({
  type: LOGIN,
  payload: payload
})

export const fetching = (val = true) => ({
  type: FETCHING,
  payload: val
})