import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import AsicAddNameForm from './Forms/AsicAddNameForm';
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import TopDocsForm from './Forms/TopDocsForm';
import AsicResultPage from './AsicResultPage';
import { updateBusiness } from '../../../actions/businessesActions';

import StartOrder from './Forms/StartOrder';
import CompanyDetails from './Forms/CompanyDetails';
import CompanyAdresses from './Forms/ComapyAdresses';
import Officeholders from './Forms/Officeholders';
import { actionAddOrder } from '../../../actions/ordersActions';
import LoadingPage from '../../Shared/LoadingPage';
import Shareholders from './Forms/Shareholders';
import LodgementDetails from './Forms/LodgementDetails';
import { Box, Grid } from '@material-ui/core';
import Review from './Forms/Review';
import PageTitle from '../../Shared/PageTitle';

export const newShareholder = (date) => ({
  id: date,
  type: '',
  shares_held_on_trust: '',
  shares_held_on_trust_for: '',
  name: '',
  given_names: '',
  family_name: '',
  company_name: '',
  company_has_acn_or_arbn: '',
  company_acn_or_arbn: '',
  company_representative_1: '',
  company_representative_2: '',
  joint_holder_1_type: '',
  joint_holder_2_type: '',
  joint_holder_1_name: '',
  joint_holder_1_given_names: '',
  joint_holder_1_family_name: '',
  joint_holder_1_company_name: '',
  joint_holder_1_company_has_acn_or_arbn: '',
  joint_holder_1_company_acn_or_arbn: '',
  joint_holder_1_company_representative_1: '',
  joint_holder_1_company_representative_2: '',
  joint_holder_1_address: '',
  joint_holder_1_level: '',
  joint_holder_1_street_number_and_name: '',
  joint_holder_1_suburb: '',
  joint_holder_1_state: '',
  joint_holder_1_postcode: '',
  joint_holder_2_name: '',
  joint_holder_2_given_names: '',
  joint_holder_2_family_name: '',
  joint_holder_2_company_name: '',
  joint_holder_2_company_has_acn_or_arbn: '',
  joint_holder_2_company_acn_or_arbn: '',
  joint_holder_2_company_representative_1: '',
  joint_holder_2_company_representative_2: '',
  joint_holder_2_address: '',
  joint_holder_2_level: '',
  joint_holder_2_street_number_and_name: '',
  joint_holder_2_suburb: '',
  joint_holder_2_state: '',
  joint_holder_2_postcode: '',
  address: '',
  level: '',
  street_number_and_name: '',
  suburb: '',
  state: '',
  postcode: '',
  class_of_shares_held: '',
  number_of_shares: '',
  price_of_each_share: ''
})

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  stepContent: {
    alignItems: 'center'
  }
}));


const AsicRegisterBusiness = () => {
  const classes = useStyles();
  const location = useLocation()
  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState([
    {label: 'Choose a business name'}, 
    {label: 'Setup your order', ref: React.useRef()},
    {label: 'Company Details', ref: React.useRef()},
    {label: 'Company Adresses', ref: React.useRef()},
    {label: 'Officeholders', ref: React.useRef()},
    {label: 'Shareholders', ref: React.useRef()},
    {label: 'Lodgement details', ref: React.useRef()},
    {label: 'Review and send', ref: React.useRef()},
    {label: 'Result', ref: React.useRef()},
  ])
  const [formState, setFormState] = React.useState({
    order_type: 'asic_registration',
    business_name: location.state?.name,
    business_id: null,
    new_order_details: {},
    company_details: {},
    company_addresses: {},
    addresses: [],
    people: [],
    officeholders: [ //need this for the initial value of the array in the form
      { 
        id: Date.now() * Math.random(),
        given_names: '',
        family_name: '',
        is_director: false,
        is_secretary: false,
        is_public_officer: false,
        address: '',
        state_of_birth: '',
        city_of_birth: '',
        date_of_birth: ''
      }
    ],
    shareholders: [
      newShareholder(Date.now() * Math.random())
    ],
    lodgementDetails: {}
  })

  const dispatch = useDispatch()
  const businesses = useSelector(state => state.businesses?.data?.items)

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleSkip = stepIndex => {
    setSteps(prev => prev.map((step, i) => ( i == stepIndex ? {...step, skipped: true} : step )))
    handleNext()
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleNextStep = values => {
    console.log(values, 'values')
    setFormState(prev => ({ ...prev, ...values }))
    // api call here
    handleNext()
  }
  
  const handleStart = values => {
    let business = businesses.filter(b => b.Properties.business_name == formState.business_name)[0]
    console.log(business)
    setFormState(prev => ({ ...prev, new_order_details: values, business_id: business?.BusinessId }))
    // dispatch(actionAddOrder({ ...formState, new_order_details: values, business_id: business?.BusinessId }))
    handleNext()
  }

  const handleCompanyDetails = values => {
    setFormState(prev => ({ ...prev, company_details: values }))
    // api call here
    handleNext()
  }
  
  const handleAddresses = (addresses, values) => {
    // Save addresses as fully built addresses to use in other components. company_addresses is the raw data 
    setFormState(prev => {
      const toInsert = addresses.filter(newAddress => !prev.addresses.find(existingAddress => existingAddress.full_address == newAddress.full_address))
      console.log(toInsert, 'to insert')
      return ({ ...prev, addresses: [...toInsert], company_addresses: values })
    })
    handleNext()
  }
  
  const handleOfficeholders = (officeholders, addresses) => {
    setFormState(prev => {
      const toInsert = addresses.length ? addresses.filter(newAddress => !prev.addresses.find(existingAddress => existingAddress?.full_address == newAddress?.full_address)) : []
      return ({ ...prev, officeholders: [...officeholders], addresses: [...prev.addresses, ...toInsert] })
    })
    handleNext()
  }
  
  const handleShareholders = (shareholders, addresses) => {
    setFormState(prev => {
      const toInsert = addresses.length ? addresses.filter(newAddress => !prev.addresses.find(existingAddress => existingAddress?.full_address == newAddress?.full_address)) : []

      return ({ ...prev, shareholders: [...shareholders], addresses: [...prev.addresses, ...toInsert] })
    })
    handleNext()
  }

  const handleLodgement = lodgementDetails => {
    setFormState(prev => {
      return ({ ...prev, lodgementDetails: lodgementDetails })
    })
    handleNext()
  }

  const handleReview = () => {
    dispatch(actionAddOrder(formState, () => {
      const currentBusiness = businesses.filter(b => b.BusinessId == formState.business_id)[0]
      dispatch(updateBusiness(formState.business_id, { 
        ...currentBusiness.Properties,
        checklist: currentBusiness.Properties.checklist.map(check => {
          return check.name == 'name_register' ? 
            { ...check, status: 'pending' } : 
            check
        })
      }))
    }))
    handleNext()
  }

  function getStepContent(step) {
    let scrollFunction = () => {
      let y = steps[step].ref.current.getBoundingClientRect().top + window.pageYOffset - 80 - 16
      window.scrollTo({top: y, behavior: 'smooth'})
      console.log(y, 'scroll to')
    } 
    switch (step) {
      case 0: //todo: only pass businesses that are not registed yet.
        return <AsicAddNameForm 
                  preSelected={formState.business_name} 
                  businesses={businesses} 
                  handler={handleNextStep}
                  title="What name would you like to register with ASIC?"
                  label="Select a business"
                  buttonText="Begin Registration"
                  />;
      case 1: 
        return <StartOrder 
                data={formState.new_order_details} 
                businessName={formState.business_name} 
                handler={handleStart} 
                scrollFunction={scrollFunction}
                back={handleBack} 
                />
      case 2: 
        return <CompanyDetails 
                data={formState.company_details}
                handler={handleCompanyDetails} 
                scrollFunction={scrollFunction} 
                back={handleBack} 
                />
      case 3: 
        return <CompanyAdresses 
                data={formState.company_addresses}
                addresses={formState.addresses} //maybe?
                handler={handleAddresses} 
                scrollFunction={scrollFunction} 
                back={handleBack} 
                />
      case 4: 
        return <Officeholders 
                addresses={formState.addresses} 
                officeholders={formState.officeholders}
                handler={handleOfficeholders} 
                scrollFunction={scrollFunction} 
                back={handleBack} 
                />
      case 5: 
        return <Shareholders 
                addresses={formState.addresses} 
                directors={formState.officeholders.filter(o => o.is_director)}
                shareholders={formState.shareholders}
                handler={handleShareholders} 
                scrollFunction={scrollFunction} 
                back={handleBack} 
                />
      case 6: 
        return <LodgementDetails 
                data={formState.lodgementDetails}
                addresses={formState.addresses} 
                directors={formState.officeholders.filter(o => o.is_director)}
                shareholders={formState.shareholders}
                handler={handleLodgement} 
                scrollFunction={scrollFunction} 
                back={handleBack} 
                />
      case 7: 
        return <Review 
                data={formState} 
                handler={handleReview}
                scrollFunction={scrollFunction} 
                />
      case 8: 
        return <><p>Email sent</p></>
          
      default:
        return 'Unknown step';
    }
  }

  return (
    businesses ? 
    <div className={classes.root}>
      <PageTitle>Register your business</PageTitle>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map(({label, optional, skipped, ref}, index) => {
          let labelProps = {}
          if(optional){
            labelProps.optional = <Typography variant="caption">Optional</Typography>
          }
          let stepProps = {}
          if(skipped){
            stepProps.completed = false
          }
          return(
          <Step {...stepProps} key={label} ref={ref}>
            <StepLabel {...labelProps}>{label}</StepLabel>
            <StepContent className={classes.stepContent}>
              {getStepContent(index)}
            </StepContent>
          </Step>
        )})}
      </Stepper>
      {activeStep === steps.length && (
        <Paper square elevation={0} className={classes.resetContainer}>
          <Typography>All steps completed - you&apos;re finished</Typography>
          <Button onClick={handleBack} className={classes.button}>
            Back
          </Button>
        </Paper>
      )}
    </div> :
    <LoadingPage />
  );
}

export default AsicRegisterBusiness