import  * as businessesApi from "../api/businessesApi"
import  * as documentsApi from "../api/documentsApi"
import { actionOpenSnackbar } from "./snackbarActions"

export const FETCHING = '@@redux-businesses/FETCHING'
export const FETCH_BUSINESSES_BASIC = '@@redux-businesses/FETCH_BUSINESSES_BASIC'
export const FETCH_BUSINES = '@@redux-businesses/FETCH_BUSINES'
export const ADD_ONE_BUSINESS = '@@redux-businesses/ADD_ONE_BUSINESS'
export const UPDATE_BUSINESS = '@@redux-businesses/UPDATE_BUSINESS'
export const UPDATE_BUSINESS_CHECKLIST = '@@redux-businesses/UPDATE_BUSINESS_CHECKLIST'
export const LOGOUT = '@@redux-businesses/LOGOUT'

// status:
//   not_checked
//   pending
//   warning
//   error
//   completed 


const businessesDB = [ // For emulation purposes only
  {
    "Properties": {
      "business_name": "Test Company",
      "website": "www.test.com",
      "main_purpose": "Does tests",
      "logo": null,
      "date_commenced": "1991-09-09T14:00:00.000Z",
      "checklist": {
          "name_register": {
              "status": "not_completed"
          },
          "logo_copyright": {
              "status": "not_checked"
          },
          "brand_protection": {
              "status": "not_checked"
          },
          "name_copyright": {
              "status": "not_checked"
          },
          "name_check": {
              "status": "not_checked"
          }
      },
      "socials": {
          "instagram": "test_company"
      }
    },
    "UserId": "5cf64043-cd5b-4072-84fe-ee1914805b54",
    "BusinessId": "a68ffa37-1bd4-487a-a94a-cc9a547d0ec0"
  },
  {
    "Properties": {
        "business_name": "Elbaite",
        "logo": null,
        "date_commenced": "1991-09-09T14:00:00.000Z",
        "website": "www.elbaite.io",
        "instagram": "elbaite_io",
        "main_purpose": "Crypto exchange",
        "checklist": {
          "name_register": {
              "status": "not_completed"
          },
          "logo_copyright": {
              "status": "not_checked"
          },
          "brand_protection": {
              "status": "not_checked"
          },
          "name_copyright": {
              "status": "not_checked"
          },
          "name_check": {
              "status": "not_checked"
          }
      },
      "socials": {
          "instagram": "test_company"
      }
    },
    "UserId": "5cf64043-cd5b-4072-84fe-ee1914805b54",
    "BusinessId": "a01a74de-d68c-41e4-8d06-57fefcfd0f3c"
  }
]

export const fetchBusinesses = () => {
  return dispatch => {
    dispatch(fetching())
    businessesApi.getBusinesses()
      .then(res => {
        console.log(res)
        dispatch(updateBusinesses(res.data.data))
      })
      .catch(err => {
        console.dir(err, 'error')
        // dispatch(actionOpenSnackbar(`Something went wrong. ${err.message}` ))
      })
  }
}

export const addBusiness = (data, sideEffects) => {
  return dispatch => {
    businessesApi.addBusiness(data)
        .then(res => {
          console.log(res)
          dispatch(fetchBusinesses())
          sideEffects()
        })
        .catch(err => console.dir(err))

  }
}

const emulateCheckLogoApiCall = id => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        business_id: id, 
        checklist: {
          logo_check: {
            status: 'warning', 
            similarities: ['one', 'two']
          }
        }
      })
    }, 2500)
  })
}
export const actionCheckLogo = (id, runSideEffects) => {
  return dispatch => {
    emulateCheckLogoApiCall(id).then(res => {
      runSideEffects(res)
      dispatch(updateBusinessChecklist({ id: res.business_id, newChecklistItem: res.checklist })) //todo: maybe only res.checklist
      return res
    })
  }
}

const emulateCheckNameApiCall = business_name => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        business_id: businessesDB.filter(b => b.name == business_name)[0]?.id,
        checklist: {
          name_check: { status: 'completed' }
        },
        check_result: {
          facebook: {
            status: 'completed'
          },
          instagram: {
            status: 'error',
            matches: [`${business_name}`]
          },
          asic: {
            status: 'warning',
            similarities: [`${business_name}Copy`]
          }
        }
      })
    }, 1000)
  })
}
export const actionCheckName = (business, name, runSideEffects) => {
  return dispatch => {
    businessesApi.checkSocials(name)
    .then(res => {
      console.log(res, 'res')
      const asic_match = res.asicName.Names.filter(result => result.Score == 100)
      const asic_similarity = res.asicName.Names.filter(result => result.Score < 100 && result.Score > 80)
      console.log(asic_match, asic_similarity)
      const check_result = [
        {id: 1, name: 'facebook', status: res.socials.facebook ? 'completed' : 'error'},
        {id: 2, name: 'instagram', status: res.socials.instagram ? 'completed' : 'error'},
        {id: 3, name: 'asic_name', status: asic_match.length ? 'error' : asic_similarity.length ? 'warning' : 'completed', extra: res.asicName.Names},
        {id: 4, name: 'trademark', status: res.trademark?.length ? 'warning' : 'completed', extra: res.trademark},
      ]
      business && dispatch(updateBusiness(business.BusinessId, { 
        ...business.Properties,
        checklist: business.Properties.checklist.map(check => {
          return check.name == 'name_check' ? 
            { ...check, status: (res.socials.facebook && res.socials.instagram && !asic_match.length && !res.trademark?.length) ? 'completed' : 'warning' } : 
            check
        })
      }))
      runSideEffects(check_result, res.domains)
      return res
    })
    .catch(err => console.dir(err))
  }
}


const emulateRegisterNameApiCall = id => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve({
        business_id: id, 
        checklist: {
          name_register: {
            status: 'completed' 
          }
        }
      })
    }, 2500)
  })
}
export const actionRegisterName = (id, runSideEffects = () => console.log('no side effects')) => {
  return dispatch => {
    emulateRegisterNameApiCall(id).then(res => {
      runSideEffects(res)
      dispatch(updateBusinessChecklist({ id: res.business_id, newChecklistItem: res.checklist })) //todo: maybe only res.checklist
      return res
    })
  }
}

export const requestQuote = ({ id, checklist }) => {
  return dispatch => {
    //Some api call to create the quote, return updated business status and/or quote info
    dispatch(updateBusinessChecklist({ id, checklist }))
    return {result: 'ok'}
  }
}

export const updateBusiness = (id, updatedBusiness) => {
  return dispatch => {
    businessesApi.updateBusiness(id, updatedBusiness).then(res => {
      console.log(res, 'res updateBusiness')
      dispatch(fetchBusinesses())
    })
    //Some api call to create the quote, return updated business status and/or quote info
    // dispatch(updateBusinessChecklist({ id: id, newChecklistItem: checklist }))
    return {result: 'ok'}
  }
}

export const fetching = () => ({
  type: FETCHING
})

export const updateBusinesses = payload => ({
  type: FETCH_BUSINESSES_BASIC,
  payload: payload
})

export const updateBusinessChecklist = payload => ({
  type: UPDATE_BUSINESS_CHECKLIST,
  payload: payload
})

export const updateSingleBusines = payload => ({
  type: FETCH_BUSINES,
  payload: payload
})

 export const addOneBusiness = business => ({
   type: ADD_ONE_BUSINESS,
   payload: business
 })

export const clearBusinesses = () => ({
  type: LOGOUT
})