import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Router } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Provider, useSelector } from 'react-redux'
import createStore from './store/createStore'
import Amplify, { Auth } from 'aws-amplify';
import axios from 'axios'
import LoadingPage from './Components/Shared/LoadingPage';
import WaitForData from './WaitForData'
import { actionLogout, fetchEverything } from './actions/userActions'
import { actionOpenSnackbar } from './actions/snackbarActions';
import history from './helpers/history'

const store = createStore()
// const token = localStorage.getItem('CognitoIdentityServiceProvider.gna6r2fluaf4qsnkv7jiu02ag.gonzalo.idToken')

// const maybeRefresh = async () => {
//   try {
//     console.log(token, '[maybeRefresh] curr token')
//     const cognitoUser = await Auth.currentAuthenticatedUser();
//     const { refreshToken } = cognitoUser.getSignInUserSession();
//     cognitoUser.refreshSession(refreshToken, (err, session) => {
//       console.log(err, session, '[maybeRefresh] err, session')
//       // store.dispatch(fetchEverything(localStorage.getItem('CognitoIdentityServiceProvider.gna6r2fluaf4qsnkv7jiu02ag.gonzalo.idToken')))
//     })
//   } catch (e){
//     console.log(e, '[MaybeRefresh] - error')
//     store.dispatch(actionLogout())
//     Auth.signOut().then(res => console.log('out').catch(e => console.dir(e)))
//   }
// }

// let jsx = null
// if(token) {
//   maybeRefresh()
//   //dispatch fetching all
//   jsx = <WaitForData><App /></WaitForData>
// } else {
//   jsx = <App />
// }

Amplify.configure({
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION, // (required) - Region where Amazon Cognito project was created   
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOL_ID, // (optional) -  Amazon Cognito User Pool ID   
  aws_user_pools_web_client_id: process.env.REACT_APP_USER_POOL_WEB_CLIENT, // (optional) - Amazon Cognito App Client ID (App client secret needs to be disabled)
})

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  console.log(process.env)
  const token = `Bearer ${localStorage.getItem(`${process.env.REACT_APP_ID_TOKEN_PREFIX}.${localStorage.getItem(process.env.REACT_APP_LAST_AUTH_USER)}.idToken`)}`
  config.headers.Authorization = token
  console.log(config, 'config')
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (config) {
  return config
}, function (error) {
  store.dispatch(actionOpenSnackbar('Something went wrong')) //todo: Give proper error message here. Maybe try to get them to stack
})

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <CssBaseline />
      <Router history={history}>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();