import React from 'react'
import { connect } from 'react-redux'
import * as Yup from 'yup'
import { withFormik } from 'formik'
import Form from '../../../Shared/Form'

import { Box, Button, Typography, CircularProgress, FormHelperText, Grid, OutlinedInput } from '@material-ui/core'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { ExpandableHeading } from '../../../Shared/ExpandableHeading'

const LodgementDetailsFormTemplate = ({
  values,
  status,
  touched,
  errors,
  handleBlur,
  handleChange,
  isSubmitting,
  submitCount,
  scrollFunction
}) => {
  React.useEffect(() => {
    setTimeout(() => {
      scrollFunction()
    }, 500)
  }, [])
  return (
    <Form>
      { values.review && <Box position="absolute" width="100%" height="100%" zIndex="999" bgcolor="rgba(0,0,0,0.1)"></Box> }
      <Box width="80%" maxWidth="800px" margin="0 auto" padding={2} display="flex" flexDirection="column" justifyContent="center">
        <Typography variant="h6">Lodgement Details</Typography>
        <Typography variant="body1">Person applying for the Registration of the Company</Typography>
        <Typography variant="caption">Please nominate the person who will be noted as the 'Applicant' for this Company. This is the person who will be shown in your Company lodgement form with ASIC as the person applying for the Company. If you have lodged a Form 410 Company Name reservation form in relation to this Company, you must enter the name of the person who lodged the Form 410 as the 'Applicant'.</Typography>
        <br/>
        <FormControl component="fieldset" error={touched.applicant_given_name && errors.applicant_given_name}>
          <FormLabel component="legend">Applicant Given Name</FormLabel>
          <OutlinedInput
            id="applicant_given_name"
            value={values.applicant_given_name}
            name="applicant_given_name"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.applicant_given_name) && errors.applicant_given_name && <FormHelperText>{errors.applicant_given_name}</FormHelperText> }
        </FormControl>
        <FormControl component="fieldset" error={touched.applicant_family_name && errors.applicant_family_name}>
          <FormLabel component="legend">Applicant Family Name</FormLabel>
          <OutlinedInput
            id="applicant_family_name"
            value={values.applicant_family_name}
            name="applicant_family_name"
            onChange={handleChange}
            onBlur={handleBlur}
            margin="normal"
            variant="outlined"
            margin="dense"
            fixedLabel="true"
          /> 
          { (submitCount > 0 || touched.applicant_family_name) && errors.applicant_family_name && <FormHelperText>{errors.applicant_family_name}</FormHelperText> }
        </FormControl>
        <Divider />
        
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.applicants_address && errors.applicants_address}>
          <FormLabel component="legend">What is the Applicant's address?</FormLabel>
          <Select
            id="applicants_address"
            name="applicants_address"
            value={values.applicants_address}
            onChange={handleChange}
          >
            <MenuItem value="new">A different address</MenuItem>
            {
              values.addresses.map(address => <MenuItem value={address?.full_address}>{address?.full_address}</MenuItem>)
            }
          </Select>
        </FormControl>
        {
          values.applicants_address == 'new' && 
          <>
            <FormControl component="fieldset" error={touched.applicants_level && errors.applicants_level}>
              <FormLabel component="legend">Level, Floor, Unit, Office, Suite</FormLabel>
              <OutlinedInput
                id="applicants_level"
                value={values.applicants_level}
                name="applicants_level"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.applicants_level) && errors.applicants_level && <FormHelperText>{errors.applicants_level}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" error={touched.applicants_street_number_and_name && errors.applicants_street_number_and_name}>
              <FormLabel component="legend">Street Number and Name</FormLabel>
              <OutlinedInput
                id="applicants_street_number_and_name"
                value={values.applicants_street_number_and_name}
                name="applicants_street_number_and_name"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.applicants_street_number_and_name) && errors.applicants_street_number_and_name && <FormHelperText>{errors.applicants_street_number_and_name}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" error={touched.applicants_suburb && errors.applicants_suburb}>
              <FormLabel component="legend">Suburb</FormLabel>
              <OutlinedInput
                id="applicants_suburb"
                value={values.applicants_suburb}
                name="applicants_suburb"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.applicants_suburb) && errors.applicants_suburb && <FormHelperText>{errors.applicants_suburb}</FormHelperText> }
            </FormControl>
            <FormControl component="fieldset" variant="outlined" size="small" error={touched.applicants_state && errors.applicants_state}>
              <FormLabel component="legend">State</FormLabel>
              <Select
                id="applicants_state"
                name="applicants_state"
                value={values.applicants_state}
                onChange={handleChange}
              >
                <MenuItem value="">
                  <em>Please select a state</em>
                </MenuItem>
                <MenuItem value="ACT">ACT</MenuItem>
                <MenuItem value="NSW">NSW</MenuItem>
                <MenuItem value="NT">NT</MenuItem>
                <MenuItem value="QLD">QLD</MenuItem>
                <MenuItem value="SA">SA</MenuItem>
                <MenuItem value="TAS">TAS</MenuItem>
                <MenuItem value="VIC">VIC</MenuItem>
                <MenuItem value="WA">WA</MenuItem>
              </Select>
            </FormControl>
            <FormControl component="fieldset" error={touched.applicants_postcode && errors.applicants_postcode}>
              <FormLabel component="legend">Postcode</FormLabel>
              <OutlinedInput
                id="applicants_postcode"
                value={values.applicants_postcode}
                name="applicants_postcode"
                onChange={handleChange}
                onBlur={handleBlur}
                margin="normal"
                variant="outlined"
                margin="dense"
                fixedLabel="true"
              /> 
              { (submitCount > 0 || touched.applicants_postcode) && errors.applicants_postcode && <FormHelperText>{errors.applicants_postcode}</FormHelperText> }
            </FormControl>
          </>
        }
        <Divider />
        <FormControl component="fieldset" error={(submitCount > 0 || touched.request_manual_review) && errors.request_manual_review} helperText="Hello">
          <FormLabel component="legend">Have you been instructed by ASIC to request a manual review?</FormLabel>
          <RadioGroup 
            aria-label="request_manual_review" 
            name="request_manual_review" 
            value={values.request_manual_review} 
            onChange={handleChange} 
            color="primary"
          >
            <FormControlLabel value="yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="no" control={<Radio />} label="No" />
          </RadioGroup>
          { (submitCount > 0 || touched.request_manual_review) && errors.request_manual_review && <FormHelperText>{errors.request_manual_review}</FormHelperText> }
        </FormControl>
        {
          values.request_manual_review == 'yes' && (
            <>
              <Typography variant="body1">Select No for this question unless you have been instructed by ASIC to request a manual review</Typography>
              <FormControl component="fieldset" error={touched.information_to_transmit_via_manual_review && errors.information_to_transmit_via_manual_review}>
                <FormLabel component="legend">Please enter the information you would like to transmit via your Manual Review</FormLabel>
                <OutlinedInput
                  id="information_to_transmit_via_manual_review"
                  value={values.information_to_transmit_via_manual_review}
                  name="information_to_transmit_via_manual_review"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  margin="normal"
                  variant="outlined"
                  margin="dense"
                  fixedLabel="true"
                  multiline
                  rows={6}
                /> 
                { (submitCount > 0 || touched.information_to_transmit_via_manual_review) && errors.information_to_transmit_via_manual_review && <FormHelperText>{errors.information_to_transmit_via_manual_review}</FormHelperText> }
              </FormControl>
            </>
          )
        }
        <Divider />
        <Typography variant="h6">Company Chairman</Typography>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.chairman_of_the_company && errors.chairman_of_the_company}>
          <FormLabel component="legend">Who is the Chairman of the company</FormLabel>
          <Select
            id="chairman_of_the_company"
            name="chairman_of_the_company"
            value={values.chairman_of_the_company}
            onChange={handleChange}
          >
           {
             values.directors.map(director => <MenuItem value="WA">{`${director.given_names} ${director.family_name}`}</MenuItem>)
           } 
          </Select>
        </FormControl>
        <FormControl component="fieldset" variant="outlined" size="small" error={touched.chairman_at_first_meeting && errors.chairman_at_first_meeting}>
          <FormLabel component="legend">Who will be Chairman at the first meeting of the Members?</FormLabel>
          <Select
            id="chairman_at_first_meeting"
            name="chairman_at_first_meeting"
            value={values.chairman_at_first_meeting}
            onChange={handleChange}
          >
           {
             values.shareholders.map(shareholder => (
              <MenuItem 
                value={`${shareholder.given_names} ${shareholder.family_name}`}
              >
                {`${shareholder.given_names} ${shareholder.family_name}`}
              </MenuItem>
            ))
           } 
          </Select>
        </FormControl>
        {
          !values.review && (
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={status.back}
                  fullWidth
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={9}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={isSubmitting}
                  endIcon={isSubmitting ? <CircularProgress size={16} color="default" /> : null}
                  fullWidth
                >
                  {isSubmitting ? 'Loading' : 'Next'}
                </Button>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Form>
  )
}

const LodgementDetails = withFormik({
  mapPropsToValues: ({
    handler,
    refreshUserData,
    updateBankDetails,
    addresses,
    directors,
    shareholders,
    review,
    businessName,
    data
  }) => ({
      updateBankDetails,
      refreshUserData,
      addresses,
      directors,
      shareholders,
      review,
      applicant_given_name: '',
      applicant_family_name: '',
      applicant_address: '',
      applicants_level: data.applicants_level || '',
      applicants_street_number_and_name: data.applicants_street_number_and_name || '',
      applicants_suburb: data.applicants_suburb || '',
      applicants_state: data.applicants_state || '',
      applicants_postcode: data.applicants_postcode || '',
      request_manual_review: data.request_manual_review || '',
      information_to_transmit_via_manual_review: data.information_to_transmit_via_manual_review || '',
      chairman_of_the_company: data.chairman_of_the_company || '',
      chairman_at_first_meeting: data.chairman_at_first_meeting || '',
      handler
    }),
  mapPropsToStatus: ({ back }) => ({ back }),
  validationSchema: Yup.object().shape({
    applicant_given_name: Yup.string().required('Required'),
    applicant_family_name: Yup.string().required('Required'),
    applicant_address: Yup.string().when('address', { is: 'new', then: Yup.string().required('Required') }),
    applicants_level: Yup.string().when('address', { is: 'new', then: Yup.string().required('Required') }),
    applicants_street_number_and_name: Yup.string().when('address', { is: 'new', then: Yup.string().required('Required') }),
    applicants_suburb: Yup.string().when('address', { is: 'new', then: Yup.string().required('Required') }),
    applicants_state: Yup.string().when('address', { is: 'new', then: Yup.string().required('Required') }),
    applicants_postcode: Yup.string().when('address', { is: 'new', then: Yup.string().required('Required') }),
    request_manual_review: Yup.string().required('Required'),
    information_to_transmit_via_manual_review: Yup.string().when('request_manual_review', { is: 'yes', then: Yup.string().required('Required') }),
    chairman_of_the_company: Yup.string().required('Required'),
    chairman_at_first_meeting: Yup.string().required('Required'),
  }),
  handleSubmit(
    { 
      handler, 
      applicant_given_name,
      applicant_family_name,
      applicant_address,
      applicants_level,
      applicants_street_number_and_name,
      applicants_suburb,
      applicants_state,
      applicants_postcode,
      request_manual_review,
      information_to_transmit_via_manual_review,
      chairman_of_the_company,
      chairman_at_first_meeting
    },
    { setSubmitting, setErrors }
  ) {
    setSubmitting(true)
    const lodgementDetails = {
      applicant_given_name,
      applicant_family_name,
      applicant_address,
      applicants_level,
      applicants_street_number_and_name,
      applicants_suburb,
      applicants_state,
      applicants_postcode,
      request_manual_review,
      information_to_transmit_via_manual_review,
      chairman_of_the_company,
      chairman_at_first_meeting,
    }
    handler(lodgementDetails)
  }
})(LodgementDetailsFormTemplate)


export default LodgementDetails
