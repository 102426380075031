import React from 'react'
import { useLocation } from 'react-router'

export const Context = React.createContext({
  isLoading: false,
  currentPageTitle: 'Brandhub'
})

const ContextProvider = props => {

  const [loading, setLoading] = React.useState(false)
  const [currentPageTitle, setCurrentPageTitle] = React.useState('Brandhub')
  const location = useLocation()

  const routes = {}

  const cleanPathName = path => path.replace('/', '').replace('_', ' ').replace(/^\w/, (c) => c.toUpperCase())

  React.useEffect(() => {
    setCurrentPageTitle(routes[location.pathname] || cleanPathName(location.pathname))
  }, [location.pathname])

  return (
    <Context.Provider value={{isLoading: loading, setLoading: setLoading, currentPageTitle: currentPageTitle}}>
      {props.children}
    </Context.Provider>
  )
}

export default ContextProvider