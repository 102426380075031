import * as API from './common'
import axios from 'axios'


// export const checkSocials = name => {
//   return axios.get(`${API.baseUrl}/v1/social/${name}`, { headers: { ...API.HEADERS }})
// }

export const checkSocials = async name => {
  try {
    const socials = (await axios.get(`
      ${API.baseUrl}/v1/social/${name}`, 
      { headers: { ...API.HEADERS }}
    )).data

    const asicName = (await axios.get(`
      ${API.baseUrl}/v1/lookup/acn/name/${name}`,
      { headers: { ...API.HEADERS }}
    )).data

    const trademark = (await axios.get(`
      ${API.baseUrl}/v1/lookup/trademark/au/${name}`,
      { headers: { ...API.HEADERS }}
    )).data.trademarks

    const cleanName = name.replace(/[^a-zA-Z0-9-_]/g, '')
    const domains = [
      (await axios.get(`
        ${API.baseUrl}/v1/lookup/domain/${cleanName}.com.au`,
        { headers: { ...API.HEADERS }}
      )).data,
      (await axios.get(`
        ${API.baseUrl}/v1/lookup/domain/${cleanName}.com`,
        { headers: { ...API.HEADERS }}
      )).data,
      (await axios.get(`
        ${API.baseUrl}/v1/lookup/domain/${cleanName}.io`,
        { headers: { ...API.HEADERS }}
      )).data,
      (await axios.get(`
        ${API.baseUrl}/v1/lookup/domain/${cleanName}.net`,
        { headers: { ...API.HEADERS }}
      )).data
    ]

    return new Promise(resolve =>
      resolve({
        socials: socials,
        asicName: asicName,
        trademark: trademark,
        domains: domains
      })
    )
  } catch (e) {
    return new Promise((resolve, reject) => reject(e))
  }
}

export const getBusinesses = () => {
  return axios.get(`${API.baseUrl}/v1/business`, { headers: { ...API.HEADERS }})
}

export const getBusiness = id => {
  return axios.get(`${API.baseUrl}/v1/business/${id}`, { headers: { ...API.HEADERS }})
}

export const addBusiness = businessData => {
  return axios.post(`${API.baseUrl}/v1/business`, businessData, { headers: { ...API.HEADERS }})
}

export const updateBusiness = (id, updatedBusiness) => {
  return axios.put(`${API.baseUrl}/v1/business/${id}`, updatedBusiness, { headers: { ...API.HEADERS }})
}